import type { FC } from 'react';
import { createRef, useCallback, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { Icon } from '@dx-ui/osc-icon';
import { HeadingLevel } from '@dx-ui/osc-heading-level';
import { useRouter } from 'next/router';
import { isRtl } from '@dx-ui/utilities-get-language-direction';
import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';
import cx from 'classnames';

import { getPrefersReducedMotion } from '../utils/get-prefers-reduced-motion';
import type { TBrandShowcase, TBrandShowcaseItem } from './brand-showcase.types';
import { useTranslation } from 'next-i18next';
import { useDebounceCallback } from 'usehooks-ts';

export const NavItemWidth = 224; // px
export const END_MARGIN = 5; // px
export const SLIDE_DURATION = 0.5; // seconds

/**
 * List of the Hilton brands in a carousel like form. Each brand includes a logo, additional info about the currently selected brand, a link to the brand page,
 * and includes navigation controls to cycle through each brand.
 */
export const BrandShowcase: FC<TBrandShowcase> = ({ items, id, onItemClicked, logoUrl }) => {
  const { t } = useTranslation('osc-marketing-brand-showcase');
  const { locale = 'en' } = useRouter();
  const rtl = isRtl(locale);

  const [selectedItem, setSelectedItem] = useState<TBrandShowcaseItem | undefined>(items[0]);
  const [atScrollEnd, setAtScrollEnd] = useState(false);

  const scrollAnimationRequest = useRef<number | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemRefs = useMemo(() => items.map(() => createRef<HTMLButtonElement>()), [items]);

  const onShowcaseItemClicked = (item: TBrandShowcaseItem | undefined) => {
    const index = items?.findIndex((i) => i === item);
    if (item !== selectedItem && scrollRef.current) {
      const totalWidth = scrollRef.current.scrollWidth;
      const { offsetWidth } = scrollRef.current;
      //Getting width of the first brand item which is equal to all other brand items
      const brandRect = scrollRef?.current?.children[0]?.getBoundingClientRect();
      const width = brandRect?.width || 0;

      let desiredScrollLeft = index * width * (rtl ? -1 : 1);
      const maxScrollLeft = totalWidth - offsetWidth * (rtl ? -1 : 1);
      if (desiredScrollLeft > maxScrollLeft) {
        desiredScrollLeft = maxScrollLeft;
      }
      const onComplete = () => {
        const previousItem = selectedItem;
        setSelectedItem(item);
        itemRefs[index]?.current?.focus();

        previousItem !== item && onItemClicked && onItemClicked(index);
      };
      if (scrollRef.current.scrollLeft !== desiredScrollLeft) {
        scrollToOffset(desiredScrollLeft, onComplete);
      } else {
        onComplete();
      }
    }
  };

  const scrollToOffset = useCallback(
    (desiredScrollLeft: number, onComplete?: () => void) => {
      if (scrollRef.current) {
        if (scrollAnimationRequest.current) {
          cancelAnimationFrame(scrollAnimationRequest.current);
          scrollAnimationRequest.current = null;
        }

        const { scrollLeft } = scrollRef.current;

        if (getPrefersReducedMotion()) {
          scrollRef.current.scrollLeft = desiredScrollLeft;
          onComplete && onComplete();
        } else {
          const scrollDistance = desiredScrollLeft - scrollLeft;
          const duration = Math.max(350, Math.abs(scrollDistance) * 0.6);
          let startTime: number;

          const animateScroll = (timeStamp: number) => {
            if (!startTime) {
              startTime = timeStamp;
            }

            const progress = (timeStamp - startTime) / duration;
            const easing =
              progress < 0.5
                ? SLIDE_DURATION * progress * progress
                : (4 - 2 * progress) * progress - 1;

            if (scrollRef.current) {
              scrollRef.current.scrollLeft = scrollLeft + scrollDistance * easing;

              if (progress < 1) {
                scrollAnimationRequest.current = requestAnimationFrame(animateScroll);
              } else {
                scrollAnimationRequest.current = null;
                onComplete && onComplete();
              }
            }
          };

          scrollAnimationRequest.current = requestAnimationFrame(animateScroll);
        }
      }
    },
    [scrollAnimationRequest, scrollRef]
  );

  const debouncedOnScroll = useDebounceCallback(() => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth: totalWidth, offsetWidth } = scrollRef.current;
      const marginForDetectingBrandPosition = 7;

      const distanceFromEndOfScrollBar = totalWidth - offsetWidth - Math.abs(scrollLeft);
      const endOfScrollBar =
        distanceFromEndOfScrollBar < marginForDetectingBrandPosition &&
        distanceFromEndOfScrollBar > -marginForDetectingBrandPosition;

      //Getting width of the first brand item which is equal to all other brand items
      const brandRect = scrollRef?.current?.children[0]?.getBoundingClientRect();
      const width = brandRect?.width || 0;

      const index = Math.min(items.length - 1, Math.ceil(Math.abs(scrollLeft) / Math.ceil(width)));

      const currentIndex = items.findIndex((i) => i === selectedItem);

      // Don't adjust if we aren't at a scroll stop point unless we are at the end of the bar and currentIndex is before index
      if (!endOfScrollBar || (endOfScrollBar && currentIndex < index)) {
        const previousItem = selectedItem;
        setSelectedItem(items[index]);
        itemRefs[index]?.current?.focus();

        previousItem !== items[index] && onItemClicked && onItemClicked(index);
      }
      setAtScrollEnd(endOfScrollBar);
    }
  }, 300);

  const onScrollWrapper = () => {
    if (atScrollEnd && scrollRef.current) {
      const { scrollLeft, offsetWidth, scrollWidth: totalWidth } = scrollRef.current;

      const distanceFromEndOfScrollBar = totalWidth - offsetWidth - scrollLeft;
      const endOfScrollBar = Math.abs(distanceFromEndOfScrollBar) < END_MARGIN;
      if (!endOfScrollBar) {
        setAtScrollEnd(false);
      }
    }
    debouncedOnScroll();
  };

  const handleTabListWrapperKeyDown = (evt: React.KeyboardEvent) => {
    const index = items.findIndex((i) => i.code === selectedItem?.code);
    switch (evt.key) {
      case 'ArrowLeft':
        evt.preventDefault();
        evt.stopPropagation();

        if (index !== 0) {
          onShowcaseItemClicked(items[index - 1]);
        }

        break;
      case 'ArrowRight':
        evt.preventDefault();
        evt.stopPropagation();

        if (index !== items.length - 1) {
          onShowcaseItemClicked(items[index + 1]);
        }

        break;
      default:
    }
  };

  const slide = (direction: number) => {
    const index = items.findIndex((i) => i.code === selectedItem?.code);
    let targetIndex;
    if (direction < 0) {
      targetIndex = index === 0 ? 0 : index - 1;
      // if we are at the end, make sure to update the endrow state
      if (targetIndex < items.length - 1) {
        setAtScrollEnd(false);
      }
    } else {
      targetIndex = index === items.length - 1 ? items.length - 1 : index + 1;
    }
    onShowcaseItemClicked(items[targetIndex]);
  };

  const index = items.findIndex((i) => i.code === selectedItem?.code);

  const NavItems = items.map((item, idx) => (
    <div
      className="relative w-full flex-none snap-start p-1 md:w-2/6 lg:w-1/5 xl:w-1/6"
      key={item.code}
    >
      <button
        className="relative h-32 w-full px-4"
        tabIndex={selectedItem?.name === item.name ? undefined : -1}
        onClick={() => onShowcaseItemClicked(item)}
        role="tab"
        aria-expanded={selectedItem?.name === item.name}
        aria-controls={`brands-showcase-panel-${id}`}
        ref={itemRefs[idx]}
        aria-label={item.name}
        onKeyDown={handleTabListWrapperKeyDown}
        data-testid="brands-showcase-brand-button"
        type="button"
      >
        <div className="relative size-full overflow-hidden bg-bg-alt brand-hi-refresh:bg-bg-light">
          <Image
            id={id}
            style={{
              objectFit: 'contain',
              fill: item.imageDefaultColor,
            }}
            // fill-[imageDefaultColor] does not fill if the SVG images already
            // have fill colors built-in like all of the 20 brand logos on the
            // Hilton.com homepage (portfolio site)
            fill
            src={`${logoUrl}/${item.code}.svg`}
            alt={item.name}
          />
        </div>
      </button>
      {selectedItem?.name === item.name ? (
        <div className="absolute bottom-0 flex h-2 w-full justify-center">
          <div className="top-0 me-3 size-6 origin-center rotate-45 bg-bg" />
        </div>
      ) : null}
    </div>
  ));
  return (
    <div
      className="relative w-full bg-bg-alt brand-hi-refresh:bg-bg-light"
      data-testid="brandShowcaseWrapper"
      id={id}
    >
      <div className="container flex flex-col items-start py-8 ps-4 brand-wa:py-16 xl:py-12 brand-wa:xl:py-20">
        <div className="px-0 sm:ps-6">
          <HeadingLevel
            headingLevelFallback={2}
            className="heading-2xl pb-4 pt-1 leading-tight text-primary sm:heading-3xl lg:heading-4xl"
          >
            {t('waysToStay')}
          </HeadingLevel>
        </div>
        <div className="relative flex w-full">
          {index > 0 ? (
            <button
              className="btn btn-lg btn-primary-text absolute inset-y-0 start-0 z-10 m-1"
              onClick={() => slide(-1)}
              data-testid="previous"
              type="button"
            >
              <span className="sr-only">{t('returnsToThePreviousBrand')}</span>
              <Icon name={rtl ? 'arrowhead-right' : 'arrowhead-left'} size="2xl" />
            </button>
          ) : null}
          <div className="relative w-full overflow-hidden px-12">
            <div className="absolute start-0 top-0 inline-block h-full w-5 ltr:bg-gradient-to-l rtl:bg-gradient-to-r" />
            <div
              className={cx(
                'absolute end-4 top-0 inline-block h-full ltr:bg-gradient-to-r rtl:bg-gradient-to-l',
                {
                  'w-0': atScrollEnd,
                  'w-8 md:w-32': !atScrollEnd,
                }
              )}
            />
            <div
              className="snap mx-auto flex w-56 snap-x snap-mandatory overflow-x-auto overflow-y-hidden px-0 py-1 md:mx-0 md:w-full lg:overflow-x-scroll"
              ref={scrollRef}
              onScroll={onScrollWrapper}
              role="tablist"
              data-testid="onScrollWrapper"
            >
              {NavItems}
            </div>
          </div>
          {index < items.length - 1 ? (
            <button
              className="btn btn-lg btn-primary-text absolute inset-y-0 end-0 m-1"
              onClick={() => slide(1)}
              data-testid="next"
              type="button"
            >
              <span className="sr-only">{t('advancesToTheNextBrand')}</span>
              <Icon name={rtl ? 'arrowhead-left' : 'arrowhead-right'} size="2xl" />
            </button>
          ) : null}
        </div>
        <div className="flex w-full flex-col sm:ps-6" id={`brands-showcase-panel-${id}`}>
          {items.map((item /* we render all tabs for SEO purposes */) => (
            <div
              className={`${selectedItem?.name === item.name ? 'flex' : 'hidden'} w-full flex-col`}
              key={item.name}
              data-testid={
                selectedItem?.name === item.name
                  ? 'brand-showcase-panel-active'
                  : 'brand-showcase-panel'
              }
            >
              <div className="image-corner-radius mb-8 w-full bg-bg px-8 pt-8">
                <h3 className="text-2xl font-bold leading-tight sm:text-3xl lg:text-4xl">
                  {item.name}
                </h3>
                <div className="py-5 sm:text-lg md:pb-8 lg:text-xl">
                  <CustomMarkdown>{item.shortDescription}</CustomMarkdown>
                </div>
              </div>
              <div className="flex w-full flex-col items-center">
                <a className="btn btn-xl btn-primary items-center" href={item.url || `/en/brands/`}>
                  {item.label || t('visitBrand', { brand: item.name })}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
