import { ImageFunction, ImageType, ImageClickID, ANALYTICS_NO_VALUE } from '@dx-ui/config-metrics';
import type { MastheadImageMetrics } from '../types/masthead-analytics';

export const MASTHEAD_IMAGE_POSITION = 1;
export const MASTHEAD_IMAGE_COUNT = 1;

type GenerateMastheadAnalyticsParams = {
  headline: string;
  itemTitle?: string;
};

export const generateMastheadAnalytics = ({
  headline,
  itemTitle,
}: GenerateMastheadAnalyticsParams): MastheadImageMetrics => {
  const mastheadAnalytics = {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        headline: headline || ANALYTICS_NO_VALUE,
        itemTitle: itemTitle || ANALYTICS_NO_VALUE,
        position: MASTHEAD_IMAGE_POSITION,
        count: MASTHEAD_IMAGE_COUNT,
        type: ImageType.Masthead,
        function: ImageFunction.Link,
      },
    },
  };
  return mastheadAnalytics;
};
