import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';
import { useFeatureTogglesQuery } from '../queries/generated/queries';
import { ENABLE_SHORTEN_ENROLLMENT } from '../constants/featureToggles';

export function useShortenEnrollmentToggle() {
  const { data } = useFeatureTogglesQuery({
    names: [ENABLE_SHORTEN_ENROLLMENT],
  });

  return {
    enabled: getIsFeatureToggleEnabled({
      featureToggleData: data,
      name: ENABLE_SHORTEN_ENROLLMENT,
    }),
  };
}
