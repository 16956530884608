export const getDependencies = <T>(metricsProps: T) => {
  const values: unknown[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getObjValues = (obj: any) => {
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'object') {
        getObjValues(obj[key]);
      } else {
        values.push(obj[key]);
      }
    }
  };

  getObjValues(metricsProps);

  return values;
};
