import {
  type ImageClickIDType,
  ANALYTICS_NO_VALUE,
  ImageType,
  ImageFunction,
  ImageClickID,
} from '@dx-ui/config-metrics';
import type { CardCarouselMetrics } from '../types/card-carousel-analytics';

type GenerateCardCarouselMetricsParams = {
  headline?: string;
  position: number;
  count: number;
  itemTitle?: string;
  clickID: ImageClickIDType;
  imageFunction?: (typeof ImageFunction)['Link'];
};

export const generateCardCarouselMetrics = ({
  headline = ANALYTICS_NO_VALUE,
  position = 0,
  count,
  itemTitle = ANALYTICS_NO_VALUE,
  clickID = ImageClickID.Scroll,
  imageFunction,
}: GenerateCardCarouselMetricsParams): CardCarouselMetrics => {
  const cardCarouselMetrics: CardCarouselMetrics = {
    click: {
      clickID, // This should be scroll for button clicks & img on img taps
    },
    osc: {
      image: {
        headline,
        count,
        position,
        itemTitle,
        type: ImageType.Carousel,
      },
    },
  };

  if (imageFunction === ImageFunction.Link) {
    cardCarouselMetrics.osc.image.function = ImageFunction.Link;
  }

  return cardCarouselMetrics;
};
