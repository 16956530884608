import { Alerts, Alert } from '@dx-ui/osc-alert';
import { Link } from '@dx-ui/osc-link';
import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';

import { extractInstanceIndexFromId } from '../../utils';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';
import { useMetrics } from '../../Context';

export default createCpmComponentDefinition(
  'Text Highlight',

  function mapData({ data }) {
    const { link } = data;

    return {
      ...data,
      id: data.id ?? '',
      link: link?.url ? link : null,
      links: data.links,
    };
  },

  function TextHighlight({
    items = [],
    componentParams,
    listData: data,
    mappedPage: { brandCode },
  }) {
    const metrics = useMetrics();
    const createManageContentButton = useCreateManageContentButton();
    const id = data?.id ?? '';

    if (!items.length) {
      return null;
    }

    const itemsWithManageContentButton = items.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const onChange = (index: number, direction: 'previous' | 'next' | undefined) => {
      metrics?.trackTextHeadlinerClick?.([
        {
          textHeadlinerNumber: extractInstanceIndexFromId(id),
          itemNumber: index + 1,
          totalItems: items.length,
          leftArrow: direction === 'previous',
          rightArrow: direction === 'next',
        },
      ]);
    };

    return itemsWithManageContentButton?.length > 0 ? (
      <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
        <div data-testid="text-highlight-wrapper">
          <Alerts onChangeIndex={(index, direction) => onChange(index, direction)} loop>
            {itemsWithManageContentButton.map((item) => (
              <Alert
                status="normal"
                title={item.headline}
                isTruncated={false}
                key={item.id}
                className={item.cmsTranslationClasses}
              >
                {item?.link?.url ? (
                  <Link
                    url={item.link.url}
                    showNewWindowIcon={item.link.isNewWindow}
                    isNewWindow={item.link.isNewWindow}
                    data-conductrics-goal={item.link.experimentationConfiguration?.goal}
                    data-conductrics-value={item.link.experimentationConfiguration?.value}
                    className="text-text"
                  >
                    {item?.link?.label}
                  </Link>
                ) : null}
                {item.cmsDocumentControl}
              </Alert>
            ))}
          </Alerts>
        </div>
      </BrandComponentThemeInline>
    ) : null;
  }
);
