import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';
import {
  TextualBlock,
  makeHeadingOverrides,
  makeDescriptionClassName,
  makeLinkClassName,
  makeOrderedListClassName,
  makeUnorderedListClassName,
  makeOrderedListItemClassName,
} from '@dx-ui/osc-textual-block';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { useIntersectionObserver } from 'usehooks-ts';

export type MarkdownBlockProps = {
  markdown: string;
  className?: string;
  isAnimated: boolean;
  textAlign?: 'left' | 'right' | 'center';
  isBorderTrim?: boolean;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export function MarkdownBlock({
  markdown,
  brandComponentTheme,
  textAlign = 'left',
  isBorderTrim,
  isAnimated = false,
}: MarkdownBlockProps) {
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: 0.3,
    freezeOnceVisible: true,
  });
  const wrapperRef = !getIsReducedMotion() && isAnimated ? intersectionRef : null;

  const options: Exclude<React.ComponentProps<typeof CustomMarkdown>['options'], undefined> = {
    overrides: {
      ...makeHeadingOverrides({
        brandComponentTheme,
        isAnimated,
        isInView: inView,
        textAlign,
      }),

      p: {
        props: {
          className: makeDescriptionClassName({
            brandComponentTheme,
            isAnimated,
            isInView: inView,
            textAlign,
          }),
        },
      },

      a: {
        props: { className: makeLinkClassName({ brandComponentTheme }) },
      },

      ol: {
        props: {
          className: makeOrderedListClassName({
            isAnimated,
            isInView: inView,
            brandComponentTheme,
          }),
        },
      },

      ul: {
        props: {
          className: makeUnorderedListClassName({
            isAnimated,
            isInView: inView,
            brandComponentTheme,
          }),
        },
      },

      li: { props: { className: makeOrderedListItemClassName({ brandComponentTheme }) } },
    },
  };

  return (
    <TextualBlock
      brandComponentTheme={brandComponentTheme}
      textAlign={textAlign}
      hasBorderTrim={isBorderTrim ?? false}
      ref={wrapperRef}
    >
      {/*
      language and origin are used by Markdown to construct Anchor Tag props, but we are overriding them.
      so we don't need to set anything here
      */}
      <CustomMarkdown options={options}>{markdown}</CustomMarkdown>
    </TextualBlock>
  );
}
