import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';

import Icon from '@dx-ui/osc-icon';
import type { HeaderProps, TDrawerLink } from '../types';
import { trackNavClick, trackNavClickBasedOnLabel } from '../header.utilities';
import { getLocaleFormattedNumber, replaceLang } from '@dx-ui/framework-i18n';
import { useRouter } from 'next/router';

export function NavigationPopupContents({
  subMenu,
  label,
}: {
  subMenu: TDrawerLink[];
  label: string;
}) {
  const { locale = 'en' } = useRouter();

  return (
    <div className="container flex-1 py-5">
      <MenuList>
        {subMenu.map(
          ({ label: subMenuLabel, link: { experimentationConfiguration, ...link }, onClick }) => (
            <MenuListItem key={subMenuLabel}>
              <Link
                {...link}
                url={replaceLang(link?.url, locale)}
                data-conductrics-goal={experimentationConfiguration?.goal}
                data-conductrics-value={experimentationConfiguration?.value}
                underline={false}
                className="nav-drawer-link-text"
                anchorClassName="nav-drawer-link p-3 hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:font-normal brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
                onClick={(event) => {
                  trackNavClickBasedOnLabel(subMenuLabel, label);
                  onClick?.();
                  link?.onClick?.(event);
                }}
              >
                {subMenuLabel}
              </Link>
            </MenuListItem>
          )
        )}
      </MenuList>
    </div>
  );
}

export type UserPopupContentsProps = Pick<
  HeaderProps,
  | 'userLinks'
  | 'shouldOpenAccountLinksInNewWindow'
  | 'shouldSuppressHonorsAccountLinks'
  | 'onSignOut'
> & {
  user: Exclude<HeaderProps['user'], undefined>;
};

export default function UserPopupContents({
  user,
  userLinks,
  shouldOpenAccountLinksInNewWindow,
  shouldSuppressHonorsAccountLinks,
  onSignOut,
}: UserPopupContentsProps) {
  const { locale = 'en' } = useRouter();
  const [t] = useTranslation('osc-header');

  const formattedHonorsPointsTotal = getLocaleFormattedNumber(user?.honorsPoints, { locale });

  return (
    <div className="container flex py-5">
      <div className="flex items-center">
        <div className="flex size-20 shrink-0 items-center justify-center rounded-full bg-hilton-alt">
          <Icon name="user" size="4xl" />
        </div>
        <div className="ms-5">
          <p className="mb-1 text-sm font-bold brand-ey:font-normal">
            {t('greetings', { username: user.name })}
          </p>
          <p className="mb-1 capitalize">
            <span className="text-sm font-bold underline brand-ey:font-normal">
              {user.honorsTier}
            </span>{' '}
            {t('status')}
          </p>
          <p className="mb-1 text-sm brand-ey:font-normal">
            {t('honorsPointsTotal', { honorsPointsTotal: formattedHonorsPointsTotal })}
          </p>
          {user.hhonorsNumber ? (
            <p className="mb-1 text-sm brand-ey:font-normal">
              {t('hiltonHonorsNumber', { hhonorsNumber: user.hhonorsNumber })}
            </p>
          ) : null}
        </div>
      </div>
      <div className="ms-5 flex-1 xl:ms-10">
        <MenuList>
          {userLinks?.accountLink ? (
            <MegaUserLink
              url={userLinks?.accountLink.url}
              onClick={() => {
                trackNavClick('gh_membername_hiltonhonorshome');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('hiltonHonorsHome')}
            </MegaUserLink>
          ) : null}
          {userLinks?.honorsActivityLink && !shouldSuppressHonorsAccountLinks ? (
            <MegaUserLink
              url={userLinks?.honorsActivityLink.url}
              onClick={() => {
                trackNavClick('gh_membername_hiltonhonorsactivity');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('activity')}
            </MegaUserLink>
          ) : null}

          {userLinks?.honorsPointsLink?.url && !shouldSuppressHonorsAccountLinks ? (
            <MegaUserLink
              url={userLinks.honorsPointsLink.url}
              onClick={() => {
                trackNavClick('gh_membername_hiltonhonorspoints');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('pointsLabel')}
            </MegaUserLink>
          ) : null}

          {userLinks?.honorsProfileLink?.url && !shouldSuppressHonorsAccountLinks ? (
            <MegaUserLink
              url={userLinks.honorsProfileLink.url}
              onClick={() => {
                trackNavClick('gh_membername_hiltonhonorsprofile');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('profile')}
            </MegaUserLink>
          ) : null}

          {userLinks?.goHiltonLink?.url ? (
            <MegaUserLink
              url={userLinks.goHiltonLink.url}
              onClick={() => {
                trackNavClick('gh_membername_gohiltonprogramdetails');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('goHiltonProgramDetails')}
            </MegaUserLink>
          ) : null}

          {userLinks?.friendsAndFamilyLink?.url ? (
            <MegaUserLink
              url={userLinks.friendsAndFamilyLink.url}
              onClick={() => {
                trackNavClick('gh_membername_managefriendsandfamily');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('manageFriendsAndFamily')}
            </MegaUserLink>
          ) : null}

          {userLinks?.hgvMaxLink?.url ? (
            <MegaUserLink
              url={userLinks.hgvMaxLink.url}
              onClick={() => {
                trackNavClick('gh_membername_hgvmaxratedetails');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('hgvMaxRateDetails')}
            </MegaUserLink>
          ) : null}

          {userLinks?.smbMemberLink?.url ? (
            <MegaUserLink
              url={userLinks.smbMemberLink.url}
              onClick={() => {
                trackNavClick('gh_membername_managehiltonforbusiness');
              }}
              isNewWindow={shouldOpenAccountLinksInNewWindow}
            >
              {t('manageHiltonForBusiness')}
            </MegaUserLink>
          ) : null}
          <MenuListItem>
            <button
              className="nav-drawer-link nav-drawer-link-text w-full whitespace-nowrap text-left hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:border-b-primary-alt brand-ey:focus:bg-tertiary-alt brand-ou:focus:bg-bg-light"
              onClick={async () => {
                trackNavClick('gh_membername_signout');
                await onSignOut();
              }}
              type="button"
            >
              {t('signOut')}
            </button>
          </MenuListItem>
        </MenuList>
      </div>
    </div>
  );
}

function MenuList({ children, ...listProps }: React.ComponentProps<'ul'>) {
  return (
    <ul {...listProps} className={cx('columns-3 gap-6', listProps.className)}>
      {children}
    </ul>
  );
}

function MenuListItem({ children, ...listItemProps }: React.ComponentProps<'li'>) {
  return (
    <li {...listItemProps} data-testid="menu-list-item" className="py-3">
      <span className="block break-inside-avoid border-b-2 border-border py-3 hover:font-bold brand-ey:hover:border-b-primary-alt brand-ey:hover:bg-tertiary-alt brand-ey:hover:font-normal brand-ou:border-primary brand-ou:hover:bg-bg-light ">
        {children}
      </span>
    </li>
  );
}

function MegaUserLink({
  url,
  onClick,
  children,
  isNewWindow,
}: {
  url: string;
  onClick: () => void;
  children: React.ReactNode;
  isNewWindow?: boolean;
}) {
  return (
    <MenuListItem>
      <Link
        url={url}
        underline={false}
        className="nav-drawer-link-text whitespace-nowrap"
        anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
        onClick={onClick}
        isNewWindow={isNewWindow}
      >
        {children}
      </Link>
    </MenuListItem>
  );
}
