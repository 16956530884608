import type { HeadingLevel } from '@dx-ui/osc-heading-level';
import type { IllustrationVariant } from '@dx-ui/osc-brands-wrappers';
import { illustrationVariants } from '@dx-ui/osc-brands-wrappers';
import type { GuardedType } from '../typeHelpers';
import type { textOverlayVariant, IllustrationBlockVariant } from './types';

export function isBoolean(x: unknown): x is boolean {
  return x === true || x === false;
}

export function isString(x: unknown): x is string {
  return typeof x === 'string';
}

export function isAlignment(x: unknown): x is 'left' | 'right' | 'alternate' | 'round' | 'none' {
  return x === 'left' || x === 'right' || x === 'alternate' || x === 'round' || x === 'none';
}

export function isPosition(x: unknown): x is 'left' | 'right' | 'center' {
  return x === 'left' || x === 'right' || x === 'center';
}

export function isTheme(x: unknown): x is 'light' | 'dark' {
  return x === 'light' || x === 'dark';
}

export function isOrientation(x: unknown): x is 'horizontal' | 'vertical' {
  return x === 'horizontal' || x === 'vertical';
}

export function isHeaderSvg(x: unknown): x is 'byHilton' | 'none' {
  return x === 'byHilton' || x === 'none';
}
export function isGridColumn(x: unknown): x is '2' | '3' | '4' {
  return x === '2' || x === '3' || x === '4';
}

export function isFullWidthMediaDisplay(
  x: unknown
): x is
  | 'hxHexagonBlue'
  | 'hxHexagonDarkBlue'
  | 'hxHexagonDarkOrange'
  | 'hxHexagonDarkPurple'
  | 'hxHexagonDarkRed'
  | 'hxHexagonRed'
  | 'mediaOnly'
  | 'mediaWithCopy' {
  return (
    x === 'hxHexagonBlue' ||
    x === 'hxHexagonDarkBlue' ||
    x === 'hxHexagonDarkOrange' ||
    x === 'hxHexagonDarkPurple' ||
    x === 'hxHexagonDarkRed' ||
    x === 'hxHexagonRed' ||
    x === 'mediaOnly' ||
    x === 'mediaWithCopy'
  );
}

export function isImageDisplay(
  x: unknown
): x is 'static' | 'textOverlay' | 'imageCarousel' | 'fullCarousel' {
  return x === 'static' || x === 'textOverlay' || x === 'imageCarousel' || x === 'fullCarousel';
}

export function isTabDisplay(
  x: unknown
): x is 'text' | 'textAndIcon' | 'textAndlogo' | 'icon' | 'logo' {
  return x === 'text' || x === 'textAndIcon' || x === 'textAndlogo' || x === 'icon' || x === 'logo';
}

export function isEditorialDisplay(x: unknown): x is 'default' | 'snippet' | 'withCarousel' {
  return x === 'default' || x === 'snippet' || x === 'withCarousel';
}

export function isBackgroundIllustration(x: unknown): x is IllustrationVariant {
  for (const valid of illustrationVariants) {
    if (valid === x) return true;
  }
  return false;
}

export const illustrationBlockVariant = [
  'ey-accent',
  'ey-furniture',
  'hx-signature-default',
  'hx-signature-light',
  'hx-signature-dark',
  'ww-filigree-default',
  'ww-filigree-light',
  'ww-filigree-dark',
  'none',
];

export function isIllustrationBlock(x: unknown): x is IllustrationBlockVariant {
  for (const valid of illustrationBlockVariant) {
    if (valid === x) return true;
  }
  return false;
}

export const textOverlayVariants = ['forTheStay', 'none'];

export function isTextOverlay(x: unknown): x is textOverlayVariant {
  for (const valid of textOverlayVariants) {
    if (valid === x) return true;
  }
  return false;
}

export function isPaddingAmount(x: unknown): x is 'default' | 'small' | 'medium' | 'large' {
  return x === 'default' || x === 'small' || x === 'medium' || x === 'large';
}

export function isCmsHeadingLevel(x: unknown): x is 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' {
  return x === 'h1' || x === 'h2' || x === 'h3' || x === 'h4' || x === 'h5' || x === 'h6';
}

export function isInteger(x: unknown): x is number {
  return Number.isInteger(x);
}

export function isHeadingLevel(x: unknown): x is HeadingLevel {
  if (isInteger(x)) {
    return x > 0 && x < 7;
  }

  return false;
}

export function isMediaRatio(x: unknown): x is '50-50' | '60-40' | '70-30' {
  return x === '50-50' || x === '60-40' || x === '70-30';
}

export function isCmsAspectRatio(
  x: unknown
): x is '3:2' | '3:4' | '1:1' | '16:9' | '18:5' | '21:9' {
  return x === '3:2' || x === '3:4' || x === '1:1' || x === '16:9' || x === '18:5' || x === '21:9';
}

export function convertTopHeadingLevelToInteger(cmsHeadingLevel: unknown) {
  if (isCmsHeadingLevel(cmsHeadingLevel)) {
    return parseInt(cmsHeadingLevel.replace('h', ''));
  }
}

/**
 * Converts the CMS aspect ratio format of <number>:<number> to the CPM format which is <number>x<number>
 */
type ReplaceAspectRatioColon<AspectRatio extends string> =
  AspectRatio extends `${infer Width}:${infer Height}` ? `${Width}x${Height}` : never;

/**
 * CPM formatted aspect ratios from the subset of aspect ratios that the CMS supports
 */
type ConvertedCpmAspectRatio = ReplaceAspectRatioColon<GuardedType<typeof isCmsAspectRatio>>;

export function convertCmsAspectRatioToCpmAspectRatio(aspectRatio: unknown) {
  if (isCmsAspectRatio(aspectRatio)) {
    return aspectRatio.replace(':', 'x') as ConvertedCpmAspectRatio;
  }
}

export function isCpmAspectRatio(x: unknown): x is ConvertedCpmAspectRatio {
  return x === '3x2' || x === '3x4' || x === '1x1' || x === '16x9' || x === '18x5' || x === '21x9';
}
