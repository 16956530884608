import type { Reference } from '@bloomreach/spa-sdk';
import type { BrPage } from '../adapters/types';

export type MaybeMarkdownContent = {
  headline?: string;
  subheading?: string;
  description?: string;
  shortDescription?: string;
  longDescription?: string;
  markdownEditor?: string;
  contentBlock?: Array<{ [key: string]: string }>;
  itemsRef?: Array<Reference>;
};

export function hasPageGotHeadingOne(brPage: BrPage) {
  const children = brPage.getComponent('main')?.getChildren();

  if (!children) {
    return false;
  }

  for (const child of children) {
    const params = child.getParameters();
    const models = child.getModels();
    const hasContentGotH1 = hasModelsGotH1(brPage, Object.values(models));

    if (params.topHeadingLevel === 'h1' || hasContentGotH1) {
      return true;
    }
  }

  return false;
}

function hasModelsGotH1(brPage: BrPage, refs: Array<Reference>) {
  for (const ref of refs) {
    const content = brPage.getContent(ref);
    const data = content?.getData<MaybeMarkdownContent>();
    let hasListDataGotH1 = false;

    if (!data) {
      return false;
    }

    if (Array.isArray(data.itemsRef)) {
      hasListDataGotH1 = hasModelsGotH1(brPage, data.itemsRef);
    }

    if (hasListDataGotH1 || hasDocumentGotH1(data)) {
      return true;
    }
  }

  return false;
}

function hasDocumentGotH1(data: MaybeMarkdownContent) {
  return (
    hasHeadingOne(data.headline) ||
    hasHeadingOne(data.subheading) ||
    hasHeadingOne(data.description) ||
    hasHeadingOne(data.shortDescription) ||
    hasHeadingOne(data.longDescription) ||
    hasHeadingOne(data.markdownEditor) ||
    hasContentBlockGotH1(data.contentBlock)
  );
}

function hasContentBlockGotH1(contentBlock?: Array<{ [key: string]: string }>) {
  if (!Array.isArray(contentBlock)) {
    return false;
  }

  for (const block of contentBlock) {
    for (const content of Object.values(block)) {
      if (hasHeadingOne(content)) {
        return true;
      }
    }
  }

  return false;
}

// Content team create H1's using both `#Heading` and `# Heading`
const headingOnePattern = /^#(?!#)\s*.+$/m;

export function hasHeadingOne(text?: string) {
  return headingOnePattern.test(text ?? '');
}
