import {
  CpmMappingError,
  createCpmComponentDefinition,
  removeNullyFromArray,
  useCreateManageContentButton,
  useSegmentedItems,
} from '@dx-ui/cpm-sdk';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import { EditorialSnippet } from '@dx-ui/osc-editorial-snippet';
import type { IconNames } from '@dx-ui/osc-icon';
import { BrandEditorialCarousel } from './BrandEditorialCarousel';
import { EditorialHamptonHexVisual } from './EditorialHamptonHexVisual';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';
import { useShouldRenderExperiment } from '@dx-ui/framework-conductrics';

export default createCpmComponentDefinition(
  'Editorial',

  function mapComponentData({ data, componentParams }) {
    const cpmRelated = data?.cpmRelatedDocuments ?? [];

    const relatedDocuments = removeNullyFromArray(cpmRelated).map((item, index) => {
      const image = item.cpmAssets[0];

      if (!image || !image.aspectRatios['3x2']) {
        throw new CpmMappingError(
          `Related document "${index + 1}" is missing an image with an aspect ratio of 3x2`
        );
      }

      const captionData = image
        ? {
            captionLink: image.captionLink,
            caption: image.caption,
          }
        : undefined;

      const cardImage = image.aspectRatios['3x2'];

      return {
        $ref: item._meta.$ref,
        id: item._meta.$ref.replace('/page/', ''),
        headline: item.headline,
        shortDescription: item.shortDescription,
        longDescription: item.longDescription,
        link: item.link ? item.link : undefined,
        segmentIds: item.segmentIds,
        experimentationConfiguration: item.experimentationConfiguration,
        links: item.links,
        imageAspectRatio: '3:2' as AspectRatio,
        imageUrl: cardImage.url,
        captionData,
        imageAltTxt: image.altText,
      };
    });

    const image = data.cpmAssets[0];

    if (!image || !image.aspectRatios['1x1']) {
      throw new CpmMappingError(
        `Editorial requires an image with an aspect ratio of 1x1 (${data.contentType} - ${data.displayName})`
      );
    }

    const captionData = image
      ? {
          captionLink: image.captionLink,
          caption: image.caption,
        }
      : undefined;

    const mainImage = {
      alt: image.altText,
      url: image.aspectRatios['1x1'].url,
      captionData,
    };

    return {
      // Content fields
      headline: data.headline,
      shortDescription: data.shortDescription,
      chips: data.chip, // `chip` is an array, let's call it `chips`
      social: data.socialMediaLinks,
      image: mainImage,
      longDescription: data.longDescription,
      teaser: data.teaser,
      gridDescription: data.gridDescription,
      cards: relatedDocuments,
      links: data.links,

      // Non-content fields
      editorialVariant: componentParams.display,
      brandComponentTheme: componentParams.theme,
      imageDisplay: componentParams.imageDisplay,
    };
  },

  function CpmEditorial({
    componentParams,
    data,
    mappedPage: { brandCode },
    experimentationAgents,
  }) {
    const createManageContentButton = useCreateManageContentButton();
    const segmentedCards = useSegmentedItems(data ? data.cards : [], {
      maintainUnsegmentedCount: true,
    });
    const shouldRenderExperiment = useShouldRenderExperiment();

    if (!data) {
      return null;
    }

    const carouselItems = segmentedCards
      .filter((item) =>
        shouldRenderExperiment(experimentationAgents, item.experimentationConfiguration)
      )
      .map((item) => {
        const links = item.links.filter((link) =>
          shouldRenderExperiment(experimentationAgents, link.experimentationConfiguration)
        );

        return {
          ...item,
          cmsDocumentControl: createManageContentButton(item.$ref),
          links,
          link: links[0],
        };
      });

    const { teaser, chips, social, links, gridDescription } = data;
    const chipLabel = chips?.[0]?.chipLabel;

    const socialLink = social?.[0];
    const filteredLinks =
      links?.filter((link) =>
        shouldRenderExperiment(experimentationAgents, link.experimentationConfiguration)
      ) ?? [];

    return (
      <BrandComponentThemeInline
        brandCode={brandCode}
        componentClassName="editorial"
        componentParams={componentParams}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <EditorialHamptonHexVisual
          theme={componentParams.theme}
          enabled={componentParams.backgroundIllustration === 'hx-hexagon' && brandCode === 'HP'}
        >
          {data?.editorialVariant === 'default' || data?.editorialVariant === 'withCarousel' ? (
            <BrandEditorialCarousel
              image={data.image}
              contentBelowImg={null}
              carousel={data?.editorialVariant === 'withCarousel' ? carouselItems : []}
              headline={data.headline}
              subHeadline={data.shortDescription}
              brandComponentTheme={data.brandComponentTheme}
              carouselHeadline={gridDescription || undefined}
              editorialVariant={data?.editorialVariant}
              teaser={teaser || undefined}
              chipLabel={chipLabel || ''}
              socialMediaLink={{
                url: socialLink?.url ?? '',
                linkLabel: socialLink?.linkLabel ?? '',
                channel: socialLink?.channel ?? '',
              }}
              longDescription={data?.longDescription || ''}
              links={filteredLinks}
            />
          ) : null}

          {data?.editorialVariant === 'snippet' ? (
            <EditorialSnippet
              image={{
                url: data?.image?.url,
                altText: data?.image?.alt,
              }}
              heading={data?.headline}
              shortDescription={data?.shortDescription}
              longDescription={data?.longDescription ?? ''}
              brandComponentTheme={data?.brandComponentTheme}
              isSquareImage={data?.imageDisplay === 'round' ? false : true}
              socialMediaLink={{
                url: socialLink?.url ?? '',
                label: socialLink?.linkLabel ?? '',
                isNewWindow: true,
              }}
              socialMediaIcon={socialLink?.channel?.toLowerCase() as (typeof IconNames)[number]}
              ctaLink={filteredLinks[0]}
            />
          ) : null}
        </EditorialHamptonHexVisual>
      </BrandComponentThemeInline>
    );
  }
);
