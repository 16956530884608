import cx from 'classnames';
import { useId, useRef } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';

import { useHasUserModifiedFontDefaults } from '@dx-ui/utilities-accessibility';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export type TCurvedHeading = {
  /** Text to curve along the SVG path. */
  curvedText: string;
  /** Pass additional classes to the parent `<h2>` wrapper. */
  className?: string;
  /** Pass additional classes to the text elements. */
  textClassNames?: string;
  /** Used to calculate the intensity of the circular arc on which the text is placed. Defaults to `0.5`. Suggested value is between `0` and `1`. */
  curvature?: number;
  /** Optional heading text to display below the curved header. */
  textBelow?: string;
  /** Optional second node to display below the 'textBelow'. */
  nodeBelow?: React.ReactNode;
  /** Optional prop to designate heading level, default is h2. */
  headingLevel?: HeadingLevel;
  /** Used to disable the curve */
  shouldDisable?: boolean;
};

export const CurvedHeading = ({
  className,
  textClassNames,
  curvedText,
  curvature = 0.5,
  textBelow,
  nodeBelow,
  headingLevel,
  shouldDisable,
}: TCurvedHeading) => {
  const id = useId();
  const curvedHeadingId = `curved-heading-${id}`;
  const textRef = useRef(null);
  const svgTextDimensions = useRect({ ref: textRef });
  const calculatedCurvature = Math.sin((curvature * Math.PI) / 2);
  const viewBoxWidth = 200;
  const viewBoxHeight = viewBoxWidth * calculatedCurvature;
  const shouldDisableCurve = useHasUserModifiedFontDefaults() || shouldDisable;

  return (
    <HeadingLevel
      headingLevelFallback={headingLevel}
      className={cx(className, { 'flex flex-col': shouldDisableCurve })}
    >
      {shouldDisableCurve ? (
        <span
          className={cx(
            'heading-2xl text-balance md:heading-3xl lg:heading-4xl sm:text-wrap',
            textClassNames
          )}
        >
          {curvedText}
        </span>
      ) : (
        <span
          className={cx('block w-full min-w-48 text-center', {
            'min-w-56 md:min-w-80 lg:min-w-96': !textBelow,
          })}
          style={{ height: svgTextDimensions?.height }}
        >
          <svg
            className="top-1/2 -translate-y-1/2 overflow-visible"
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            role="img"
          >
            <title>{curvedText}</title>
            <defs>
              <path
                id={curvedHeadingId}
                fill="transparent"
                d={`M 0, ${viewBoxHeight} Q ${viewBoxWidth / 2} ${
                  viewBoxHeight * (1 - calculatedCurvature)
                } ${viewBoxWidth}, ${viewBoxHeight}`}
              />
            </defs>
            <text
              ref={textRef}
              className={cx('heading-3xl fill-current text-center', textClassNames)}
            >
              <textPath xlinkHref={`#${curvedHeadingId}`} startOffset="50%" textAnchor="middle">
                {curvedText}
              </textPath>
            </text>
          </svg>
        </span>
      )}

      {textBelow ? (
        <span
          className={cx(
            'heading-4xl text-balance md:heading-5xl lg:heading-6xl sm:text-wrap',
            textClassNames
          )}
        >
          {textBelow}
        </span>
      ) : null}
      {nodeBelow ? nodeBelow : null}
    </HeadingLevel>
  );
};
