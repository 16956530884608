import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';

import {
  mapData,
  FullWidthMediaAndCopyOverlay,
} from '../../components/brand/full-width-media-copy-overlay/definition';

export default createCpmComponentDefinition(
  'Full-Width Media and Copy',
  mapData,
  FullWidthMediaAndCopyOverlay
);
