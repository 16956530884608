import type { Component as BrComponent, Reference } from '@bloomreach/spa-sdk';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useBrComponentContext } from '../adapters/hooks';
import type { DocumentModels } from '../adapters/types';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';
import type { DispatchSegmentsUpdate } from '../context/EditorPageSegmentationContext';
import { useEditorPageSegmentsContext } from '../context/EditorPageSegmentationContext';

type BrComponentData = BrComponent & {
  model: {
    id: string;
    name: string;
    label: string;
    models?: DocumentModels;
  };
};

type ItemsRef = (Reference | null)[];

/**
 * Find all segments attached to a component so that they can be filtered in the editor
 */
export function SegmentationWrapper({ children }: { children: ReactNode }) {
  const [_, setSegments] = useEditorPageSegmentsContext();
  const page = useCpmMergedBrPageContext();
  const brComponent = useBrComponentContext() as unknown as BrComponentData;

  const models = brComponent.getModels();
  const componentName = brComponent.model.label;

  useEffect(() => {
    registerSegments(models, page, componentName, setSegments);
  }, [componentName, models, page, setSegments]);

  return children;
}

function registerSegments(
  models: DocumentModels,
  page: ReturnType<typeof useCpmMergedBrPageContext>,
  componentName: string,
  setSegments: DispatchSegmentsUpdate
) {
  Object.values(models).forEach((docRef) => {
    const content = page.getContent(docRef?.$ref || '');

    if (!content || typeof content.getData !== 'function') {
      return;
    }

    const data = content.getData();

    if (!data) {
      return;
    }

    const name = `${componentName}: ${data.displayName}`;

    if (Array.isArray(data.segmentIds)) {
      data.segmentIds.forEach((segmentId) => {
        setSegments((prevState) => {
          const nextState = { ...prevState };
          let modifiedState = false;

          if (!nextState[segmentId]) {
            nextState[segmentId] = [];
            modifiedState = true;
          }

          // we know this is defined, we just set it above
          const nextSegment = nextState[segmentId];

          if (!nextSegment.includes(name)) {
            nextSegment.push(name);
            modifiedState = true;
          }

          if (modifiedState) {
            return nextState;
          }

          return prevState;
        });
      });
    }

    if (Array.isArray(data.itemsRef)) {
      registerSegments(convertItemsRefToModels(data.itemsRef), page, componentName, setSegments);
    }
  });
}

function convertItemsRefToModels(itemsRef: ItemsRef) {
  return itemsRef.reduce((models, ref, index) => {
    models[`${index}`] = ref;
    return models;
  }, {} as DocumentModels);
}
