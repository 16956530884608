interface ByHilton {
  className?: string;
  fill?: string;
}

export const ByHilton: React.FC<ByHilton> = ({ fill = 'fill-text-heading', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="140" height="15" className={className}>
    <title>by Hilton</title>
    <g id="graduate-by-hilton" className={fill}>
      <path d="M0 .41h6.59c1.62 0 2.9.45 3.71 1.26.65.65.97 1.44.97 2.41v.04c0 1.6-.85 2.49-1.87 3.06 1.64.63 2.66 1.58 2.66 3.49v.04c0 2.6-2.11 3.89-5.31 3.89H0V.41Zm8.17 4.2c0-.93-.73-1.46-2.05-1.46H3.04v3h2.88c1.38 0 2.25-.45 2.25-1.5v-.04ZM6.65 8.75H3.04v3.12h3.71c1.38 0 2.21-.49 2.21-1.54v-.04c0-.95-.71-1.54-2.31-1.54ZM20.87 9.01 15.41.41h3.65l3.39 5.7L25.9.41h3.55l-5.46 8.54v5.66h-3.12v-5.6ZM99.21 7.13c.01-1.44.53-3.1 1.7-4.52.91-1.11 2.07-1.85 3.45-2.24 1.96-.56 3.9-.49 5.77.33 1.94.84 3.24 2.31 3.86 4.32.79 2.55.49 4.97-1.09 7.15-1.07 1.48-2.55 2.34-4.33 2.68-1.53.3-3.05.24-4.53-.27-1.76-.6-3.08-1.74-3.95-3.38-.61-1.15-.88-2.38-.88-4.07Zm7.6 4.94h.33c.11 0 .22-.02.33-.04 1.34-.19 2.33-.89 2.94-2.09.57-1.13.67-2.33.44-3.56-.32-1.71-1.54-3.11-3.32-3.36-.76-.1-1.51-.07-2.23.19-1.27.46-2.04 1.39-2.43 2.65-.3.96-.32 1.94-.11 2.92.2.95.64 1.78 1.4 2.41.76.63 1.66.89 2.64.87ZM120.63.42H123.81c.14 0 .21.04.27.16 1.57 2.93 3.15 5.86 4.73 8.79.03.05.06.1.13.21V.43h3.13v14.18H128.9c-.15 0-.22-.05-.28-.17l-4.68-8.73c-.04-.07-.08-.15-.15-.22v9.1h-3.14V.42ZM45.02 8.98v5.62h-3.28V.43h3.27v5.72h4.92V.43h3.29V14.6h-3.27V8.98h-4.92ZM90.7 14.61h-3.27V3.34h-3.9V.43H94.6v2.9h-3.9V14.6ZM74.68 11.69h5.27v2.91H71.4V.43h3.28v11.26ZM64.3 14.61h-3.26V.43h3.26v14.18ZM134.31.82h-.68V.41h1.84v.41h-.69v1.61h-.47V.82ZM136.05.42h.65l.56 1.28.56-1.28h.66v2.02h-.47V.99l-.63 1.44h-.23l-.63-1.44v1.44h-.47V.42Z" />
    </g>
  </svg>
);
