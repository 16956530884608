import type { MappingSchema } from '../schema';
import type { ValueOf } from '../typeHelpers';
import type { UniversalComponentParams, ComponentParamsProperty } from './types';
import {
  isBoolean,
  isString,
  isTheme,
  isAlignment,
  isBackgroundIllustration,
  isHeadingLevel,
  isPaddingAmount,
  isPosition,
  convertTopHeadingLevelToInteger,
} from './validators';

export const effectsList = [
  'animation',
  'blur',
  'backgroundImage',
  'svg',
  'borderTrim',
  'horizontalLine',
  'scrollingAnimation',
  'noMargin',
  'backgroundParallax',
];

export function makeParamValidator<T, DefaultValue>(
  validator: (x: unknown) => x is T,
  defaultValue: DefaultValue
) {
  return function doValidation(value: unknown): T | DefaultValue {
    if (validator(value)) {
      return value;
    } else {
      return defaultValue;
    }
  };
}

function sanitizeUniversalParams(
  params: Partial<Record<string, string | boolean>>
): UniversalComponentParams {
  function validOrUndefined<T>(validator: (x: unknown) => x is T, value: unknown): T | undefined {
    return makeParamValidator(validator, undefined)(value);
  }

  return {
    animation: validOrUndefined(isBoolean, params.animation),
    horizontalLine: validOrUndefined(isBoolean, params.horizontalLine),
    scrollingAnimation: validOrUndefined(isBoolean, params.scrollingAnimation),
    noMargin: validOrUndefined(isBoolean, params.noMargin),
    backgroundImage: validOrUndefined(isBoolean, params.backgroundImage),
    backgroundParallax: validOrUndefined(isBoolean, params.backgroundImage),
    backgroundIllustration: validOrUndefined(
      isBackgroundIllustration,
      params.isBackgroundIllustration
    ),
    topPadding: validOrUndefined(isPaddingAmount, params.topPadding),
    bottomPadding: validOrUndefined(isPaddingAmount, params.bottomPadding),
    borderTrim: validOrUndefined(isBoolean, params.borderTrim),
    oneLinkNoTx: validOrUndefined(isBoolean, params.oneLinkNoTx),

    anchorId: validOrUndefined(isString, params.anchorId),
    displayOption: validOrUndefined(isString, params.displayOption),
    display: validOrUndefined(isString, params.display),

    theme: validOrUndefined(isTheme, params.theme),

    textAlign: validOrUndefined(isPosition, params.textAlign),
    textboxPosition: validOrUndefined(isPosition, params.textAlign),
    imageDisplay: validOrUndefined(isAlignment, params.imageDisplay),

    topHeadingLevel: validOrUndefined(
      isHeadingLevel,
      convertTopHeadingLevelToInteger(params.topHeadingLevel)
    ),

    agentId: validOrUndefined(isString, params.agentId),
    variation: validOrUndefined(isString, params.variation),
  };
}

function stripUndefineds<T>(x: T): T {
  return JSON.parse(JSON.stringify(x));
}

export function parseComponentParams<ComponentSchema extends ValueOf<MappingSchema>>(
  componentSchema: ComponentSchema,
  componentParamsRaw: Partial<Record<string, string | boolean>>
): ComponentParamsProperty<ComponentSchema> {
  for (const [key, value] of Object.entries(componentParamsRaw)) {
    // Core+ returns 16 'documentN' keys, mostly with the value "". They can safely be deleted

    // matches keys like 'document', 'document1', 'document5', 'document12' etc
    const isDocumentKey = /^document([0-9]*)$/;
    if (isDocumentKey.test(key) && value === '') delete componentParamsRaw[key];

    // A lot of 'oneLinkNoTxN' are returned to exclude child items from translation. These keys
    // can be removed when the value is `false`.

    // matches keys like 'oneLinkNoTx', 'oneLinkNoTx1', 'oneLinkNoTx5', 'oneLinkNoTx15' etc
    const isOneLinkExcludeFromTranslationKey = /^oneLinkNoTx([0-9]*)$/;

    if (isOneLinkExcludeFromTranslationKey.test(key) && value === false) {
      delete componentParamsRaw[key];
    }
  }

  const universalParams = sanitizeUniversalParams(componentParamsRaw);

  const componentSpecificParams =
    'componentParams' in componentSchema
      ? Object.fromEntries(
          Object.entries(componentSchema.componentParams).map(([key, parser]) => [
            key,
            parser(componentParamsRaw[key]),
          ])
        )
      : {};

  const supportedEffects: Record<string, boolean> = {};
  for (const effect of effectsList) {
    supportedEffects[effect] = effect in componentSpecificParams;
  }

  const hasComponentSpecificParams =
    Object.keys(stripUndefineds(componentSpecificParams)).length > 0;
  const hasUniversalParams = Object.keys(stripUndefineds(universalParams)).length > 0;
  const shouldIncludeSupportedEffects = hasComponentSpecificParams && hasUniversalParams;

  const componentSpecificParamsWithSupported = shouldIncludeSupportedEffects
    ? { ...componentSpecificParams, supportedEffects }
    : componentSpecificParams;

  return {
    ...stripUndefineds({
      ...universalParams,
      ...componentSpecificParamsWithSupported,
    } as ComponentParamsProperty<ComponentSchema>),
  };
}
