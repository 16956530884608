import {
  ANALYTICS_NO_VALUE,
  type BaseImageMetrics,
  ImageClickID,
  type ImageClickIDType,
  ImageFunction,
  ImageType,
} from '@dx-ui/config-metrics';
import type { FilmstripMetrics } from '../types/filmstrip-analytics';

type GenerateFilmstripMetricsParams = {
  metrics?: Partial<BaseImageMetrics>;
  position?: number;
  count?: number;
  headline?: string;
  itemTitle?: string;
  clickID: ImageClickIDType;
  imageFunction?: (typeof ImageFunction)['Link'];
};

/**
 * Utility function to generate carousel metrics
 */
export const generateFilmstripMetrics = ({
  metrics,
  position = 0,
  headline = ANALYTICS_NO_VALUE,
  itemTitle = ANALYTICS_NO_VALUE,
  clickID = ImageClickID.Scroll,
  count = 0,
  imageFunction,
}: GenerateFilmstripMetricsParams): FilmstripMetrics => {
  const filmstripMetrics = {
    click: {
      ...metrics?.click,
      clickID,
    },
    osc: {
      ...metrics?.osc,
      image: {
        ...metrics?.osc?.image,
        headline,
        count,
        position,
        itemTitle,
        type: ImageType.Filmstrip,
      },
    },
  };

  if (imageFunction === ImageFunction.Link) {
    filmstripMetrics.osc.image.function = ImageFunction.Link;
  }

  return filmstripMetrics;
};
