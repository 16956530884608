import {
  type AssetVariants,
  type StructuredAsset,
  createCpmComponentDefinition,
  useCreateManageContentButton,
  selectFirstAssetWithAspectRatio,
  selectLastAssetWithAspectRatio,
  CpmMappingError,
} from '@dx-ui/cpm-sdk';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';
import { Collage } from '@dx-ui/osc-collage';
import type { CarouselImages } from '@dx-ui/osc-carousel';

export function getIsFlipped(
  index: number,
  imageDisplay: 'left' | 'right' | 'alternate' | 'round' | 'none'
) {
  switch (imageDisplay) {
    case 'left':
      return true;

    case 'right':
      return false;

    case 'alternate':
    default:
      return Boolean(index % 2);
  }
}

export default createCpmComponentDefinition(
  'Collage',

  function mapData({ data, componentParams: { imageDisplay, animation }, index }) {
    function constructImage(asset: StructuredAsset | null, aspectRatio: keyof AssetVariants) {
      if (asset === null) {
        return null;
      }

      return {
        size: 'md',
        __typename: 'BrandImageVariant',
        _id: asset?.aspectRatios[aspectRatio]?.id ?? '',
        url: asset?.aspectRatios[aspectRatio]?.url ?? '',
        imageUrl: asset?.aspectRatios[aspectRatio]?.url ?? '',
        altText: asset?.altText ?? '',
      };
    }

    const primaryImage = constructImage(
      selectLastAssetWithAspectRatio('1x1', data.cpmAssets),
      '1x1'
    );

    const secondaryImage = constructImage(
      selectFirstAssetWithAspectRatio('16x9', data.cpmAssets),
      '16x9'
    );

    const primaryImageThreeByTwo = constructImage(
      selectLastAssetWithAspectRatio('3x2', data.cpmAssets),
      '3x2'
    );

    const secondaryImageThreeByTwo = constructImage(
      selectFirstAssetWithAspectRatio('3x2', data.cpmAssets),
      '3x2'
    );

    const primaryCaptionAsset = data.cpmAssets[1];

    const secondaryCaptionAsset = data.cpmAssets[0];

    const carouselImages = [
      { ...primaryImageThreeByTwo, ...primaryCaptionAsset },
      { ...secondaryImageThreeByTwo, ...secondaryCaptionAsset },
    ].map((image) => {
      return {
        alt: image?.altText,
        url: image?.imageUrl,
        captionData: {
          ...(image.caption && {
            caption: image.caption,
          }),
          ...(image.captionLink && {
            captionLink: image?.captionLink,
          }),
        },
      };
    }) as CarouselImages[];

    if (!(primaryImage && secondaryImage)) {
      throw new CpmMappingError(
        `The "${
          data.displayName || data.name
        }" document is missing two images with the Collage image variant`
      );
    }

    if (!primaryImage) {
      throw new CpmMappingError(
        `The "${
          data.displayName || data.name
        }" document is missing a Collage image variant for the desktop screensize image`
      );
    }

    if (!primaryImageThreeByTwo) {
      throw new CpmMappingError(
        `The "${
          data.displayName || data.name
        }" document is missing a ThreeByTwo image variant for the mobile screensize image`
      );
    }

    const isFlipped = getIsFlipped(index, imageDisplay);

    return {
      id: data.id,
      shortDescription: data.shortDescription,
      headline: data.headline,
      primaryImage,
      secondaryImage,
      longDescription: data.longDescription ?? '',
      logo: undefined,
      isFlipped,
      link:
        data.link?.url && data.link?.label ? { ...data.link, text: data.link.label } : undefined,
      ...(data.logoUrl
        ? {
            logo: {
              altText: '',
              imageUrl: data.logoUrl ?? '',
            },
          }
        : null),

      primaryCaptionData: primaryCaptionAsset
        ? {
            captionLink: primaryCaptionAsset?.captionLink,
            caption: primaryCaptionAsset?.caption,
          }
        : {},
      secondaryCaptionData: secondaryCaptionAsset
        ? {
            captionLink: secondaryCaptionAsset?.captionLink,
            caption: secondaryCaptionAsset?.caption,
          }
        : {},
      isAnimated: animation,
      links: data.links,
      carouselImages,
    };
  },

  function CollageCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const createManageContentButton = useCreateManageContentButton();

    if (items.length === 0) {
      return null;
    }

    const itemsWithManageContentButton = items.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    return (
      <BrandComponentThemeInline
        componentClassName="collage"
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        {itemsWithManageContentButton.map((item) => {
          return (
            <Collage
              key={item.id}
              {...item}
              isAnimated={componentParams.animation || brandCode === 'GU'}
              brandComponentTheme={componentParams.theme}
            />
          );
        })}
      </BrandComponentThemeInline>
    );
  }
);
