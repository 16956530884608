import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { AgentsSelection } from '../conductrics-hooks';

const CONTROL_VARIANT = 'a';

/**
 * Determines if an element should be rendered based on its `CmsExperimentationConfiguration`,
 * the current locale, and Conductrics agent selection.
 * */
export function shouldRenderExperiment({
  agents,
  config,
  locale,
  supportedAgentLocales = ['en'],
}: {
  // Record of Conductrics selections
  agents: AgentsSelection;
  // Experiment configuration containing agentId and variation (this is set via the CMS)
  config?: CmsExperimentationConfiguration;
  // Active locale
  locale: SupportedLanguage;
  // Languages that are supported by the Conductrics Agent for the test e.g. the test should run on both `en` & `es`. Defaults to `en`.
  supportedAgentLocales?: Array<SupportedLanguage>;
}) {
  // Item has no or missing config so we should always render it
  if (!config || !config.agentId || !config.variation) {
    return true;
  }

  const { agentId, variation } = config;
  const isControlVariant = variation === CONTROL_VARIANT;
  const agent = agents[agentId];
  const choice = agent?.selection?.choice?.toLowerCase();

  /**
   * Render the control variant if any on the conditions below are true:
   * 1) The locale is unsupported
   * 2) The agent is still loading
   * 3) The agent has no selection
   * 4) The agent has had an error while loading
   */
  if (!supportedAgentLocales.includes(locale) || !agent?.isLoaded || !choice || !!agent?.error) {
    return isControlVariant;
  }

  return choice === variation;
}
