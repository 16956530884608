import { useState } from 'react';
import type { ImageProps } from 'next/legacy/image';
import Image from 'next/legacy/image';
import { CarouselNoImage } from './carousel-no-image';
import { Caption } from '@dx-ui/osc-caption';
import type { CaptionProps } from '@dx-ui/osc-caption';
import classnames from 'classnames';
import { ANALYTICS_GLOBAL_CLICK_EVENT, trackEvent } from '@dx-ui/config-metrics';
import type { CarouselSingleMetrics } from './types/carousel-analytics';

export type CarouselImageProps = {
  /** Image alt text. Defaults to "Image showcasing selected Hotel" */
  alt?: string | null;
  /** Pass in overrides for next/image component */
  imageProps?: Omit<ImageProps, 'src'>;
  /** source of image */
  url: string;
  captionData?: CaptionProps;
  /** Whether it is the currently active image */
  isActive?: boolean;
  /** Analytics data */
  metrics?: CarouselSingleMetrics;
};

/**
 * Component to render the carousel image. Includes error handling that will return a "no photo available" placeholder
 * in case of image error.
 * @returns JSX.Element
 */
export const CarouselImage = ({
  alt,
  imageProps,
  url,
  captionData,
  isActive,
  metrics,
}: CarouselImageProps) => {
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <>
      <div
        className={classnames('absolute inset-0 size-full shrink-0 bg-text/10', {
          'animate-pulse': !isImageLoaded,
        })}
      />
      {isImageError ? (
        <CarouselNoImage isFullSize />
      ) : (
        <figure className="relative size-full">
          <Image
            alt={alt || ''}
            className={classnames('absolute inset-0 size-full object-cover duration-500', {
              'opacity-0': !isImageLoaded,
              'opacity-100': isImageLoaded,
            })}
            layout="fill"
            onError={() => {
              setIsImageError(true);
            }}
            onLoadingComplete={() => {
              setIsImageLoaded(true);
            }}
            src={url}
            unoptimized={true}
            data-testid="carousel-image"
            aria-hidden={isActive ? 'false' : 'true'}
            {...imageProps}
          />
          {captionData && isActive ? (
            <Caption
              caption={captionData?.caption}
              captionLink={captionData?.captionLink}
              metricsOnClick={() => metrics && trackEvent(ANALYTICS_GLOBAL_CLICK_EVENT, metrics)}
            />
          ) : null}
        </figure>
      )}
    </>
  );
};
