import {
  ImageType,
  ANALYTICS_NO_VALUE,
  ImageClickID,
  type ImageClickIDType,
  type ImageFunctionType,
  type BaseImageMetrics,
} from '@dx-ui/config-metrics';
import type { GalleryMetrics } from '../types/gallery-analytics';

export const DEFAULT_GALLERY_POSITION = 1;
export const DEFAULT_GALLERY_COUNT = 1;

type GenerateGalleryMetricsParams = {
  metrics?: Partial<BaseImageMetrics>;
  clickID: ImageClickIDType;
  headline?: string;
  itemTitle?: string;
  position?: number;
  count?: number;
  imageFunction: ImageFunctionType;
};

export const generateGalleryMetrics = ({
  metrics,
  clickID,
  headline,
  itemTitle,
  position,
  count,
  imageFunction,
}: GenerateGalleryMetricsParams): GalleryMetrics => ({
  click: {
    clickID,
  },
  osc: {
    ...metrics?.osc,
    image: {
      ...metrics?.osc?.image,
      headline: metrics?.osc?.image.headline || headline || ANALYTICS_NO_VALUE,
      itemTitle: itemTitle || ANALYTICS_NO_VALUE,
      position: position || DEFAULT_GALLERY_POSITION,
      count: count || DEFAULT_GALLERY_COUNT,
      type: ImageType.Gallery,
      function: imageFunction,
    },
  },
});

export const generatePartialGalleryMetrics = (
  headline: string | null | undefined
): Partial<BaseImageMetrics> => ({
  click: {
    clickID: ImageClickID.Img,
  },
  osc: {
    image: {
      headline: headline ?? '',
      type: ImageType.Gallery,
    },
  },
});
