import { useMemo, useState } from 'react';
import { AttachedContent } from './BrandCarouselAttachedContent';
import cx from 'classnames';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { GetHeightForWidthAndAspectRatio, overrideImageUrl } from '@dx-ui/osc-responsive-image';
import { getBreakpoint } from '../../utils/media-query';
import { useMediaQuery } from 'usehooks-ts';
import { CarouselSingle, generateCpmCarouselMetrics } from '@dx-ui/osc-carousel';
import type { BaseImageMetrics } from '@dx-ui/config-metrics';

export type BrandCarouselProps = {
  items: AttachedContent[];
  images: { alt: string; url: string; captionData?: CaptionProps; children?: React.ReactNode }[];
  isFullScreen: boolean;
  brandComponentTheme?: CmsBrandComponentTheme;
  alignContent?: CmsAlignContent;
  onArrowClick?: (index: number) => void;
};

/**
 * Brand Carousel (formerly Image Carousel) utilizes the OSC-Carousel and adds optional attached text content to create a looped carousel, sadly Calliope music and horses are not included.
 */
export const BrandCarousel = ({
  items,
  isFullScreen,
  images,
  brandComponentTheme,
  alignContent = 'center',
  onArrowClick,
}: BrandCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onNavigation = (index: number) => {
    setActiveIndex(index);
    onArrowClick?.(index);
  };

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const { headline, description, link, cmsTranslationClasses } = items[activeIndex] || {};

  const isXL = useMediaQuery(`(min-width: ${getBreakpoint('xl')}px)`);
  const isSmall = useMediaQuery(`(min-width: ${getBreakpoint('sm')}px)`);

  const imageUrls = images.map((image) => {
    const width = isXL ? 1500 : isSmall ? 1280 : 640;
    const aspectRatio = isXL ? '21:9' : isSmall || !isFullScreen ? '3:2' : '1:1';

    return {
      url: overrideImageUrl(image.url, GetHeightForWidthAndAspectRatio(width, aspectRatio), width),
      alt: image.alt,
      captionData: image.captionData,
      children: image.children ? image.children : null,
    };
  });

  const metrics: Partial<BaseImageMetrics> = useMemo(
    () => generateCpmCarouselMetrics(headline),
    [headline]
  );

  return (
    <div className="relative">
      {isFullScreen ? (
        <div
          data-testid="testBackgroundColorFull"
          className={cx(
            {
              'brand-ou:bg-secondary': !isDark && !isLight,
              'bg-bg-dark': isDark,
              'bg-bg-light': isLight,
            },
            cmsTranslationClasses
          )}
        >
          <CarouselSingle
            onArrowClick={onNavigation}
            images={imageUrls}
            className="!aspect-[1/1] sm:!aspect-[3/2] xl:!aspect-[21/9]"
            showAlternateControls={true}
            metrics={metrics}
          />
          <div className="container py-8 brand-wa:py-16 xl:py-12 brand-wa:xl:py-20">
            <AttachedContent
              headline={headline}
              description={description}
              link={link}
              alignContent={alignContent}
              brandComponentTheme={brandComponentTheme}
            />
          </div>
        </div>
      ) : (
        <div
          data-testid="testBackgroundColor"
          className={cx('container py-8 brand-wa:py-16 xl:py-12 brand-wa:xl:py-20', {
            'brand-ou:bg-secondary': !isDark && !isLight,
            'bg-bg-dark': isDark,
            'bg-bg-light': isLight,
          })}
        >
          {/* some brand will require className='rounded-lg' to display rounded corners, this is brand specfic */}
          <CarouselSingle
            onArrowClick={onNavigation}
            images={imageUrls}
            className="image-corner-radius xl:aspect-[21/9]"
            showAlternateControls={false}
            metrics={metrics}
          />
          <div className="pt-8 xl:pt-12">
            <AttachedContent
              headline={headline}
              description={description}
              link={link}
              alignContent={alignContent}
              brandComponentTheme={brandComponentTheme}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandCarousel;
