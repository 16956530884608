import type { ReactNode } from 'react';
import type { Link } from '@dx-ui/osc-link';
import { useCycle } from '@dx-ui/osc-cycle';
import cx from 'classnames';
import Image from 'next/image';
import {
  ANALYTICS_GLOBAL_CLICK_EVENT,
  ImageClickID,
  trackEvent,
  ImageFunction,
} from '@dx-ui/config-metrics';
import { generateImageHeadlinerMetrics } from './utils/image-headliner-analytics';

export type TImageHeadlinerItem = {
  headline?: string;
  id?: string;
  link?: Link & { experimentationConfiguration?: CmsExperimentationConfiguration } & {
    onLinkClick?: (e?: React.SyntheticEvent<HTMLAnchorElement>) => void;
  };
  imageAltText?: string;
  imageUrl?: string;
  isPortfolio?: boolean;
  index?: number;
  /** Determines brand color theme for component */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** CMS document editor button **/
  cmsDocumentControl?: ReactNode;
  /* Translation CSS classes to add to each item */
  cmsTranslationClasses?: string;
  /* Total number of slides in the component */
  totalSlides?: number;
};

/**
 * A headline item that can contain an image, headline text, and link to more information.
 */
export const ImageHeadlinerItem = (props: TImageHeadlinerItem) => {
  const { active, hasSlides } = useCycle();
  const {
    headline,
    id,
    link,
    imageUrl,
    imageAltText,
    index = 0,
    brandComponentTheme,
    cmsDocumentControl,
    cmsTranslationClasses,
    totalSlides = 1,
    ...rest
  } = props;
  const describedByID = `${id}-description`;

  const imageCss = 'inline-block max-h-12 md:max-h-16 w-16 md:w-24';

  const target = link?.isNewWindow ? '_blank' : '_self';
  const rel = link?.isNewWindow ? 'noopener' : null;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      data-testid="imageHeadlinerItem"
      {...rest}
      className={cx(
        'mx-4 my-2 flex justify-center md:m-0 md:mx-auto md:w-4/5 md:px-4 lg:w-full lg:px-6',
        {
          hidden: hasSlides && index !== active,
        },
        cmsTranslationClasses
      )}
    >
      <span data-testid={describedByID} className="flex items-center text-left md:justify-center">
        {imageUrl && imageAltText && (
          <div data-testid="imageHeadlinerImage">
            <div className="relative mr-4 flex h-8 w-16 overflow-hidden md:mr-6 md:h-16 md:w-24">
              <Image
                id={id}
                className={cx(imageCss)}
                alt={imageAltText}
                src={imageUrl}
                style={{
                  objectFit: 'cover',
                }}
                fill
              />
            </div>
          </div>
        )}
        <span
          className={cx('image-headliner-item-text lg:image-headliner-font-size-lg', {
            'text-text-inverse': isDark,
            'image-headliner-item-text-light': isLight,
          })}
          aria-label={headline}
        >
          {headline}
          &nbsp;
          {link?.url && link.label && target && (
            <a
              className={cx(
                'image-headliner-item-link inline-block overflow-visible underline sm:max-w-full md:inline md:max-w-none',
                {
                  'text-text-inverse': isDark,
                  'image-headliner-item-link-text-light': isLight,
                  'image-headliner-item-link-text-default': !isDark && !isLight,
                }
              )}
              href={link.url}
              aria-describedby={link.adaDescription ? describedByID : undefined}
              target={target}
              data-testid="imageHeaderlinerItemLink"
              rel={rel || undefined}
              data-conductrics-goal={link.experimentationConfiguration?.goal}
              data-conductrics-value={link.experimentationConfiguration?.value}
              onClick={(e) => {
                trackEvent(
                  ANALYTICS_GLOBAL_CLICK_EVENT,
                  generateImageHeadlinerMetrics({
                    clickID: ImageClickID.Img,
                    headline,
                    itemTitle: link.label,
                    position: index + 1,
                    count: totalSlides,
                    imageFunction: ImageFunction.Link,
                  })
                );
                link?.onLinkClick?.(e);
              }}
            >
              {link.label}
            </a>
          )}
        </span>
      </span>
      {cmsDocumentControl}
    </div>
  );
};

export default ImageHeadlinerItem;
