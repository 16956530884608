import { extractInstanceIndexFromId } from '../../utils/id-transformer';
import { FullWidthImage, DEFAULT_ASPECT_RATIO_FWI } from '@dx-ui/osc-marketing';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';
import { useMetrics } from '../../Context';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';

export default createCpmComponentDefinition(
  'Full Width Image',

  function mapData({ data, componentParams }) {
    const asset = data.cpmAssets[0];

    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;

    const desktopDefinedImage = data?.cmsDefinedAspectRatioImages[0]?.desktop?.url;
    const tabletDefinedImage = data?.cmsDefinedAspectRatioImages[0]?.tablet?.url;
    const mobileDefinedImage = data?.cmsDefinedAspectRatioImages[0]?.mobile?.url;

    return {
      id: data.id,
      description: data.shortDescription,
      imageOverlayButtonText: data.label,
      title: data.headline,
      link: {
        adaDescription: data.link?.adaDescription,
        isNewWindow: data.link?.isNewWindow,
        label: data.link?.label,
        url: data.link?.url ?? '',
        experimentationConfiguration: data.link?.experimentationConfiguration,
      },
      desktopImageUrl: desktopDefinedImage ?? asset?.aspectRatios['18x5']?.url ?? '',
      tabletImageUrl: tabletDefinedImage ?? asset?.aspectRatios['18x5']?.url ?? '',
      mobileImageUrl: mobileDefinedImage ?? asset?.aspectRatios['3x2']?.url ?? '',
      aspectRatioDesktop:
        (componentParams?.aspectRatioDesktop as AspectRatio) ?? DEFAULT_ASPECT_RATIO_FWI.desktop,
      aspectRatioTablet:
        (componentParams?.aspectRatioTablet as AspectRatio) ?? DEFAULT_ASPECT_RATIO_FWI.tablet,
      aspectRatioMobile:
        (componentParams?.aspectRatioMobile as AspectRatio) ?? DEFAULT_ASPECT_RATIO_FWI.mobile,
      captionData,
      imageAltText: asset?.altText ?? '',
      links: data.links,
    };
  },

  function FullWidthImageCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const metrics = useMetrics();
    const data = items[0];

    if (!data) {
      return null;
    }

    const { id = '', imageOverlayButtonText } = data;

    const overlayButtonClicked = () => {
      if (imageOverlayButtonText && metrics) {
        metrics.trackFullWidthImageClick?.([
          {
            fullWidthImageNumber: extractInstanceIndexFromId(id),
            label: imageOverlayButtonText,
          },
        ]);
      }
    };

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <FullWidthImage {...data} overlayButtonClicked={overlayButtonClicked} />
      </BrandComponentThemeInline>
    );
  }
);
