import cx from 'classnames';
import { Parallax } from 'react-scroll-parallax';
import type { TResponsiveImage } from '@dx-ui/osc-responsive-image';
import { useWindowSize, useIntersectionObserver } from 'usehooks-ts';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { type CarouselImages, CarouselSingle } from '@dx-ui/osc-carousel';
import { useCallback, useRef } from 'react';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { useRect } from '@dx-ui/utilities-use-rect';
import { CollageContent, type CollageContentProps } from './collage-content';
import { ANALYTICS_GLOBAL_CLICK_EVENT, trackEvent } from '@dx-ui/config-metrics';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { CollageDesktopImage } from './collage-desktop-image';
import {
  generateCollageMetrics,
  PRIMARY_CAPTION_POSITION,
  SECONDARY_CAPTION_POSITION,
} from './utils/collage-analytics';
export type OmittedImageProps = Omit<TResponsiveImage, 'aspectRatio' | 'width'>;

export type PropertyThemeOptions = {
  base: string;
  accent?: {
    border?: boolean;
    heading?: boolean;
    hover?: boolean;
    icon?: boolean;
  };
  additionalThemeOptions?: Record<string, unknown>;
  childTheme?: {
    base: string;
  } & Omit<PropertyThemeOptions, 'childTheme'>;
};

/**
 * Collage Props
 *
 * CollageProps extends the CollageContentProps, which is where the majority of the
 * props for this component are defined.
 */
export type CollageProps = Omit<CollageContentProps, 'isInView' | 'isDark' | 'isLight'> & {
  /**
   * JSON that configures how the images are rendered
   */
  primaryImage: OmittedImageProps;
  primaryCaptionData?: CaptionProps;
  secondaryImage: OmittedImageProps;
  secondaryCaptionData?: CaptionProps;
  speed?: number;
  /**
   * CMS document editor button
   */
  cmsDocumentControl?: React.ReactNode;
  /**
   * Translation CSS classes to add to each collage item
   */
  cmsTranslationClasses?: string;
  /**
   * Primary and secondary images used in carousel for mobile view.
   */
  carouselImages: CarouselImages[];
  /**
   * Image carousel styling for tailored properties.
   */
  showAlternateControls?: boolean;
  /**
   * Applies styling to carousel for tailored properties
   */
  isTailored?: boolean;
  /**
   * Applies property theming
   */
  propertyTheme?: PropertyThemeOptions;
};

/**
 * The collage component is a collage of two images with additional content. There is a main image with a smaller image offset to the lower left or right corner
 * and content that can be flipped from left to right of the main image. On mobile the collage appears as a carousel with two images (primary and secondary) with additional content above it.
 */
export const Collage: React.FC<CollageProps> = ({
  brandComponentTheme,
  brandLinkVariant,
  logo,
  headline,
  shortDescription,
  longDescription,
  link,
  primaryImage,
  primaryCaptionData,
  secondaryImage,
  secondaryCaptionData,
  isFlipped = false,
  isTailored = false,
  speed = -10,
  isAnimated = false,
  cmsDocumentControl,
  cmsTranslationClasses,
  carouselImages,
  showAlternateControls = false,
  propertyTheme,
}) => {
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.15],
    freezeOnceVisible: true,
  });
  const primaryImageRef = useRef<HTMLDivElement>(null);
  const secondaryImageRef = useRef<HTMLDivElement>(null);
  const primaryImageRect = useRect({ ref: primaryImageRef });
  const primaryImageWidth = primaryImageRect?.width ?? 0;
  const secondaryImageRect = useRect({ ref: secondaryImageRef });
  const secondaryImageWidth = secondaryImageRect?.width ?? 0;

  const { width: screenWidth = 0 } = useWindowSize();

  const observedWrapper = !getIsReducedMotion() && isAnimated ? intersectionRef : null;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const optimizedCarouselImages = useCallback(() => {
    return carouselImages?.map((img) => ({
      alt: img?.alt || '',
      url:
        getAspectRatioUrl({
          src: img?.url || '',
          width: screenWidth,
        })?.twoXimgUrl || '',
    }));
  }, [carouselImages, screenWidth]);

  const handlePrimaryCaptionClick = () =>
    primaryCaptionData
      ? trackEvent(
          ANALYTICS_GLOBAL_CLICK_EVENT,
          generateCollageMetrics({
            headline,
            position: PRIMARY_CAPTION_POSITION,
            itemTitle: primaryCaptionData?.caption,
          })
        )
      : undefined;

  return (
    <section
      className={cx(
        'flex items-center py-8 lg:container brand-wa:py-16 xl:py-12 brand-wa:xl:py-20',
        cmsTranslationClasses,
        {
          'bg-bg-dark brand-ol:bg-bg': isDark,
          'bg-bg-light brand-ol:bg-bg': isLight,
          'lg:py-20 lg:overflow-hidden': isAnimated,
        }
      )}
      data-testid="collageContainer"
    >
      {isFlipped ? (
        <div className="relative hidden grow lg:-me-20 lg:block lg:w-5/12" ref={primaryImageRef}>
          <Parallax speed={speed} disabled={!isAnimated}>
            <CollageDesktopImage
              altText={primaryImage.altText}
              aspectRatio="1:1"
              captionData={primaryCaptionData}
              imageUrl={primaryImage.imageUrl}
              onClick={handlePrimaryCaptionClick}
              width={primaryImageWidth}
            />
          </Parallax>
        </div>
      ) : null}
      <div className={cx('flex flex-col items-end lg:w-6/12 ', { 'lg:-me-20': !isFlipped })}>
        <div
          ref={observedWrapper}
          className={cx('grow px-8 pb-8 lg:p-0', isFlipped ? 'lg:me-10' : 'lg:ms-10')}
          data-testid="content-container"
        >
          <CollageContent
            brandComponentTheme={brandComponentTheme}
            brandLinkVariant={brandLinkVariant}
            headline={headline}
            isInView={inView}
            isAnimated={isAnimated}
            isDark={isDark}
            isFlipped={isFlipped}
            isLight={isLight}
            link={link}
            logo={logo}
            longDescription={longDescription}
            shortDescription={shortDescription}
            propertyTheme={propertyTheme}
          />
        </div>
        <div
          className={cx(
            'relative z-20 hidden w-full border-8 shadow-xl lg:block',
            propertyTheme?.accent?.border ? 'accent-border' : 'border-bg'
          )}
          ref={secondaryImageRef}
        >
          <CollageDesktopImage
            altText={secondaryImage.altText}
            aspectRatio="16:9"
            captionData={secondaryCaptionData}
            imageUrl={secondaryImage.imageUrl}
            onClick={() =>
              secondaryCaptionData
                ? trackEvent(
                    ANALYTICS_GLOBAL_CLICK_EVENT,
                    generateCollageMetrics({
                      headline,
                      position: SECONDARY_CAPTION_POSITION,
                      itemTitle: secondaryCaptionData?.caption,
                    })
                  )
                : undefined
            }
            width={secondaryImageWidth}
          />
        </div>
        <div className="w-full lg:hidden">
          <CarouselSingle
            images={optimizedCarouselImages()}
            showAlternateControls={showAlternateControls}
            isTailored={isTailored}
          />
        </div>
      </div>
      {!isFlipped ? (
        <div className="hidden grow lg:block lg:w-5/12" ref={primaryImageRef}>
          <Parallax speed={speed} disabled={!isAnimated}>
            <CollageDesktopImage
              altText={primaryImage.altText}
              aspectRatio="1:1"
              captionData={primaryCaptionData}
              imageUrl={primaryImage.imageUrl}
              onClick={handlePrimaryCaptionClick}
              width={primaryImageWidth}
            />
          </Parallax>
        </div>
      ) : null}
      {cmsDocumentControl}
    </section>
  );
};

export default Collage;
