import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export type HexVariant =
  | 'hxHexagonBlue'
  | 'hxHexagonDarkBlue'
  | 'hxHexagonDarkOrange'
  | 'hxHexagonDarkPurple'
  | 'hxHexagonDarkRed'
  | 'hxHexagonRed';
const getHamptonHexColor = (variant: HexVariant) => {
  if (variant === 'hxHexagonBlue') return '0047B5';
  if (variant === 'hxHexagonDarkBlue') return '002666';
  if (variant === 'hxHexagonDarkOrange') return 'C55426';
  if (variant === 'hxHexagonDarkPurple') return '6F79BD';
  if (variant === 'hxHexagonDarkRed') return '801F40';
  if (variant === 'hxHexagonRed') return 'CF3242';
};

type HamptonHexBlockProps = {
  headline: string;
  variant: HexVariant;
  link?: {
    label: string;
    url: string;
    adaDescription?: string;
    isNewWindow?: boolean;
    experimentationConfiguration?: CmsExperimentationConfiguration;
  };
  metricsOnClick?: () => void;
};

export const HamptonHexBlock = ({
  headline,
  variant,
  link,
  metricsOnClick,
}: HamptonHexBlockProps) => {
  return (
    <div className="relative start-0 top-0 flex min-h-full w-full items-center justify-center">
      <div className="flex size-full flex-col flex-wrap items-center justify-center gap-12">
        <article className="relative inline-flex w-full font-headline font-bold uppercase text-text-inverse md:my-16 md:w-auto">
          <div
            className="absolute inset-y-0 start-1/2 -z-1 hidden aspect-[640/400] -translate-x-1/2 bg-auto bg-center bg-no-repeat md:block"
            style={{
              backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 640 400"><path fill="%23${getHamptonHexColor(
                variant
              )}" d="M320 400c-10 0-19-1-29-4L34 323c-20-5-34-23-34-43V120c0-20 14-38 34-43L291 4a106 106 0 0 1 58 0l257 73c20 5 34 23 34 43v160c0 20-14 38-34 43l-257 73c-10 3-19 4-29 4" /></svg>')`,
            }}
          />
          <div
            style={{ backgroundColor: `#${getHamptonHexColor(variant)}` }}
            className="flex min-h-[2.4em] w-full flex-col content-center items-center hyphens-auto text-balance break-words bg-bg-dark py-6 text-center text-4xl md:mx-4 md:w-auto md:max-w-[20ch] md:!bg-transparent md:pb-[calc((100%*(72/400)))] md:pt-[calc((100%*(72/400))+0.1em)] md:text-5xl lg:text-7xl"
          >
            <HeadingLevel headingLevelFallback={2} className="w-full px-12 py-4 text-center">
              {headline}
            </HeadingLevel>
            {link?.label && link?.url ? (
              <>
                <BrandLink
                  anchorClassName="md:hidden"
                  brandComponentTheme="dark"
                  url={link.url}
                  label={link.label}
                  isNewWindow={link.isNewWindow}
                  showNewWindowIcon={link.isNewWindow}
                  data-conductrics-goal={link.experimentationConfiguration?.goal}
                  data-conductrics-value={link.experimentationConfiguration?.value}
                  metricsOnClick={metricsOnClick}
                />
                <BrandLink
                  anchorClassName="hidden md:block absolute -bottom-16"
                  url={link.url}
                  label={link.label}
                  isNewWindow={link.isNewWindow}
                  showNewWindowIcon={link.isNewWindow}
                  data-conductrics-goal={link.experimentationConfiguration?.goal}
                  data-conductrics-value={link.experimentationConfiguration?.value}
                  metricsOnClick={metricsOnClick}
                />
              </>
            ) : null}
          </div>
        </article>
      </div>
    </div>
  );
};
