import type { SupportedLanguage } from '@dx-ui/framework-i18n';

export const LANGUAGES = {
  EN: 'en',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  JA: 'ja',
  ES: 'es',
  RU: 'ru',
  AR: 'ar',
  HO: 'ko',
  PT: 'pt',
  TR: 'tr',
  ZH_HANS: 'zh-hans',
  ZH_HANT: 'zh-hant',
  CS: 'cs',
  SV: 'sv',
  TH: 'th',
  NL: 'nl',
  RO: 'ro',
  PL: 'pl',
  BG: 'bg',
  FI: 'fi',
} as const;

export const getLanguages = () => Object.values(LANGUAGES) as SupportedLanguage[];
