import { useMemo, useState, useEffect } from 'react';
import type Cookies from 'universal-cookie';

import type { Segments, SegmentedItem } from '../utils/segments';
import { getFilteredItems, getUnsegmentedItems } from '../utils/segments';
import { TMS_LIVE_COOKIE_NAME, TMS_DEV_COOKIE_NAME, isProdApp } from '../utils/constants';
import { useCookies } from '../context/CookiesContext';
import { usePreviewSegmentsContext } from '../context/PreviewSegmentsContext';

const getSegmentCookie = (cookies: Cookies) => {
  let cookie = '';

  if (!isProdApp) {
    cookie = cookies.get(TMS_DEV_COOKIE_NAME);
  }

  if (!cookie) {
    cookie = cookies.get(TMS_LIVE_COOKIE_NAME);
  }

  return cookie;
};

export const useSegments = () => {
  /*
   * This must use a combo of useState and useEffect, rather than useMemo.
   *
   * This is because the server does not have segment information, so will
   * always render the page with unsegmented content.
   *
   * When the client hydrates, it will have segmentation information right from initial render.
   * If you do use useMemo, it will hydrate in a segmented state.

   * React would therefore not know to update the DOM, because the client render doesn't
   * know anything has changed.
   *
   * https://reactjs.org/docs/react-dom.html#hydrate
   *
   */
  const [segments, setSegments] = useState<Segments>([]);
  const cookies = useCookies();

  useEffect(() => {
    const tms: string = getSegmentCookie(cookies) || '';

    setSegments(
      tms
        .split(',')
        .filter((entry) => (entry.toLowerCase() || '').startsWith('web'))
        .map((entry) => entry.split('=')[1] || '')
    );
  }, [cookies]);

  return segments;
};

type UseSegmentedItemsOptions = {
  /**
   * Will make sure we don't exceed the number of unsegmented items by adding segmented ones in
   * This is useful for the dynamic grids (4XGrid / Grid369), where the number of items must be the same, whether we're segmented or not
   */
  maintainUnsegmentedCount?: boolean;
};

export function useSegmentedItems<T extends SegmentedItem>(
  items: T[],
  options: UseSegmentedItemsOptions = {}
): T[] {
  const cookieSegments = useSegments();
  const [previewSegments] = usePreviewSegmentsContext();
  const segments = previewSegments.length ? previewSegments : cookieSegments;

  const numItems = options.maintainUnsegmentedCount
    ? getUnsegmentedItems(items).length
    : items.length;

  const filteredItems = useMemo(
    () => getFilteredItems({ items, segments, numItems }),
    [items, numItems, segments]
  );

  return filteredItems;
}

export function useGetFilterSegmentedItems() {
  const cookieSegments = useSegments();
  const [previewSegments] = usePreviewSegmentsContext();

  return <T extends SegmentedItem>(items: T[], options: UseSegmentedItemsOptions = {}): T[] => {
    const segments = previewSegments.length ? previewSegments : cookieSegments;

    const numItems = options.maintainUnsegmentedCount
      ? getUnsegmentedItems(items).length
      : items.length;

    return getFilteredItems({ items, numItems, segments });
  };
}
