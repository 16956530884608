import { env } from '@dx-ui/framework-env';

export const getBaseUrl = (slug?: string) =>
  slug ? new URL(slug, env('OHW_BASE_URL'))?.href : new URL(env('OHW_BASE_URL')).origin;

export const defaultPhoneNumbers = {
  number: '8556721138',
  friendlyNumber: '855-672-1138',
};

export const disableMetricsCookie = 'OHW_PROP_disable_metrics';
