import { Parallax } from 'react-scroll-parallax';

export type IllustrationVariant = (typeof illustrationVariants)[number];
export const illustrationVariants = [
  'es-line-illustration',
  'hx-hexagon',
  'none',
  'wa-cocktail',
  'wa-feather',
  'wa-plate',
  'wa-stairwell',
  'ww-filigree',
] as const;

export const BackgroundIllustration = ({
  variant,
  isParallax,
}: {
  variant: IllustrationVariant;
  isParallax?: boolean;
}) => {
  switch (variant) {
    case 'wa-stairwell':
      return <StairwellIllustration isParallax={isParallax} />;
    case 'wa-feather':
      return <FeatherIllustration isParallax={isParallax} />;
    case 'wa-plate':
      return <PlateIllustration isParallax={isParallax} />;
    case 'ww-filigree':
      return <Filigree />;
    case 'wa-cocktail':
      return;
    case 'es-line-illustration':
      return <LineIllustration />;
    case 'none':
      return;
  }
};

export const Filigree = () => {
  return (
    <div className="pointer-events-none absolute bottom-0 h-1/2 w-full overflow-hidden">
      <div className="size-full scale-[2.0] bg-[url('/modules/assets/img/brand/WW/backgrounds/filigree.svg')] opacity-35 sm:scale-[none]" />
    </div>
  );
};

export const LineIllustration = () => {
  return (
    <div className="pointer-events-none absolute top-0 size-full overflow-hidden bg-tertiary">
      <div
        className="absolute start-[50%] size-[150%] -translate-x-1/2 translate-y-[-160px] bg-[url('/modules/assets/img/brand/ES/backgrounds/embassy-suites-lines-background.svg')] bg-cover bg-top 
      bg-no-repeat lg:size-[125%] lg:translate-y-0  lg:bg-[bottom_20%_right_50%] xl:bg-[bottom_20%_right_50%]"
      />
    </div>
  );
};

export const StairwellIllustration = ({ isParallax }: { isParallax?: boolean }) => {
  return (
    <div className="container pointer-events-none absolute bottom-0 start-[50%] aspect-[100/91] h-[500px] w-screen -translate-x-1/2 translate-y-[400px] overflow-visible rtl:translate-x-1/2">
      <Parallax className="size-full" speed={isParallax ? 10 : 0}>
        <div className="ms-auto size-full md:w-[70%] lg:w-1/2">
          <div
            className="relative h-full
          bg-[url('/modules/assets/img/brand/WA/backgrounds/WA_stairwell_for_light_background.png')]
          bg-cover bg-no-repeat ltr:bg-right rtl:bg-left"
          />
        </div>
      </Parallax>
    </div>
  );
};

export const FeatherIllustration = ({ isParallax }: { isParallax?: boolean }) => {
  return (
    <div className="container pointer-events-none absolute bottom-0 start-[50%] aspect-[542/349] h-[600px] w-screen -translate-x-1/2 translate-y-[400px] overflow-visible rtl:translate-x-1/2">
      <Parallax className="size-full" speed={isParallax ? 10 : 0}>
        <div className="ms-auto size-full md:w-[90%] lg:w-4/5">
          <div
            className="relative h-full
          bg-[url('/modules/assets/img/brand/WA/backgrounds/WA_feather_for_light_background.png')]
          bg-contain bg-no-repeat ltr:bg-right rtl:bg-left"
          />
        </div>
      </Parallax>
    </div>
  );
};

export const PlateIllustration = ({ isParallax }: { isParallax?: boolean }) => {
  return (
    <div className="container pointer-events-none absolute bottom-0 start-[50%] aspect-[250/109] h-[400px] w-screen -translate-x-1/2 translate-y-[300px] overflow-visible rtl:translate-x-1/2">
      <Parallax className="size-full" speed={isParallax ? 10 : 0}>
        <div className="ms-auto size-full md:w-4/5 lg:w-[70%]">
          <div
            className="relative h-full
          bg-[url('/modules/assets/img/brand/WA/backgrounds/WA_plate_setting_for_light_background.png')]
          bg-contain bg-no-repeat ltr:bg-right rtl:bg-left"
          />
        </div>
      </Parallax>
    </div>
  );
};
