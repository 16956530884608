import type * as React from 'react';
import { TabButton } from '@dx-ui/osc-tabs';
import cx from 'classnames';

type TabButtonProps = {
  tabId: string;
  className?: string;
  activeClassName?: string;
  inactiveClassName?: string;
  children: React.ReactNode;
};

const VerticalTabButton = ({
  tabId,
  className,
  activeClassName,
  inactiveClassName,
  children,
}: TabButtonProps) => (
  <TabButton
    id={tabId}
    key={tabId}
    className={cx('relative mb-0 flex items-center px-4 py-3 text-start font-sans', className)}
    activeClassName={cx(
      'after:absolute after:start-0 after:top-0 after:h-full after:bg-hilton',
      activeClassName
    )}
    inactiveClassName={cx('bg-bg-light', inactiveClassName)}
  >
    {children}
  </TabButton>
);

export { VerticalTabButton };
export default VerticalTabButton;
