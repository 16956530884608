import Image from 'next/image';
import cx from 'classnames';

import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { Caption } from '@dx-ui/osc-caption';
import { GetHeightForWidthAndAspectRatio } from '@dx-ui/osc-responsive-image';

import type { CaptionProps } from '@dx-ui/osc-caption';

type CollageDesktopImageProps = {
  altText: string;
  aspectRatio: '1:1' | '16:9';
  imageUrl: string;
  width: number;
  captionData?: CaptionProps;
  className?: string;
  headline?: string;
  onClick?: () => void;
};

export const CollageDesktopImage = ({
  altText,
  aspectRatio = '1:1',
  captionData,
  className,
  imageUrl,
  onClick,
  width,
}: CollageDesktopImageProps) => {
  if (!imageUrl) return null;

  const imageWithRatioUrl = getAspectRatioUrl({ src: imageUrl, width }).twoXimgUrl || '';
  const isPrimaryImageRatio = aspectRatio === '1:1';

  return (
    <figure className={className}>
      <Image
        alt={altText}
        src={imageWithRatioUrl}
        width={width}
        className={cx('object-cover', isPrimaryImageRatio ? 'aspect-square' : 'aspect-video')}
        height={GetHeightForWidthAndAspectRatio(width, aspectRatio)}
      />
      {captionData ? <Caption {...captionData} metricsOnClick={onClick} /> : null}
    </figure>
  );
};
