import {
  type ImageFunctionType,
  ANALYTICS_NO_VALUE,
  type BaseImageMetrics,
  ImageClickID,
  ImageFunction,
  ImageType,
} from '@dx-ui/config-metrics';
import type { DynamicGridMetrics } from '../types/dynamic-grid-analytics';

type GenerateDynamicGridMetricsParams = {
  metrics?: Partial<BaseImageMetrics>;
  position: number;
  count?: number;
  itemTitle?: string;
  headline?: string;
  imageType?: (typeof ImageType)['Dynamic'];
  imageFunction?: ImageFunctionType;
};

/**
 * Utility function to generate dynamic Grid metrics
 */
export function generateDynamicGridMetrics({
  metrics,
  position,
  count = 0,
  itemTitle = ANALYTICS_NO_VALUE,
  headline = ANALYTICS_NO_VALUE,
  imageFunction = ImageFunction.Modal, // 'Modal' for Dynamic Grid, 'Link' for Dynamic Grid WOM
}: GenerateDynamicGridMetricsParams): DynamicGridMetrics {
  const DynamicGridMetrics = {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      ...metrics?.osc,
      image: {
        ...metrics?.osc?.image,
        count,
        itemTitle,
        type: ImageType.Dynamic,
        headline: metrics?.osc?.image?.headline || headline,
        position,
        function: imageFunction,
      },
    },
  };

  return DynamicGridMetrics;
}

/**
 * Utility function to generate Dynamic Grid metrics
 * The returned object will serve as a partial image metric object to be extended within the Dynamic Grid component
 */
export function generatePartialDynamicGridMetrics(
  headline: string | null | undefined
): Partial<BaseImageMetrics> {
  return {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        // All the other DynamicGridMetrics attributes will be set in DynamicGridItem
        headline: headline || ANALYTICS_NO_VALUE,
        type: ImageType.Dynamic,
      },
    },
  };
}
