/**
 * Shamelessly copied from https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
 * with slight modifications.
 */

import { useLayoutEffect, useState } from 'react';

export const useHasOverflow = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  onlyHorizontal = false,
  onlyVertical = false
) => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    if (!current) return;

    const trigger = () => {
      const hasOverflowHorizontal =
        current?.scrollWidth && current?.clientWidth
          ? current.scrollWidth > current.clientWidth
          : false;

      const hasOverflowVertical =
        current?.scrollHeight && current?.clientHeight
          ? current.scrollHeight > current.clientHeight
          : false;

      if (onlyHorizontal) {
        setIsOverflow(hasOverflowHorizontal);
        return;
      }

      if (onlyVertical) {
        setIsOverflow(hasOverflowVertical);
        return;
      }

      setIsOverflow(hasOverflowHorizontal || hasOverflowVertical);
    };

    if ('ResizeObserver' in window) {
      new ResizeObserver(trigger).observe(current);
    }

    trigger();
  }, [onlyHorizontal, onlyVertical, ref]);

  return isOverflow;
};
