import { useEffect } from 'react';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';
import { usePreviewSegmentsContext } from '../context/PreviewSegmentsContext';
import { usePreviewConductricsAgents } from '../context/PreviewConductricsAgents';

// Bloomreach needs to render edit document buttons each time
// we add/remove items based on filtering
export function useSyncBrPage() {
  const brPage = useCpmMergedBrPageContext();
  const [agents] = usePreviewSegmentsContext();
  const [segments] = usePreviewConductricsAgents();

  useEffect(() => {
    brPage.sync();
  }, [agents, segments, brPage]);
}
