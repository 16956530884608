import type { ReactNode } from 'react';
import cn from 'classnames';

import type { HeadingAnalysis, HeadingAnalysisNode } from '../../hooks/use-heading-analyzer';
import styles from './HeadingAnalyzer.module.css';

export function HeadingAnalyzer({ analysis }: { analysis: HeadingAnalysis }) {
  if (!analysis.headings?.length) {
    return <p className="p-4">No headings detected</p>;
  }

  return (
    <div className="max-h-[30rem] min-h-40 overflow-scroll">
      <HeadingList>
        {analysis.headings.map((heading) => (
          <HeadingListItem key={heading.text} heading={heading} />
        ))}
      </HeadingList>
    </div>
  );
}

function HeadingList({ children }: { children: ReactNode }) {
  return (
    <ul className="relative pl-4 before:absolute before:left-2 before:top-0 before:h-full before:w-px before:bg-[#101010]">
      {children}
    </ul>
  );
}

function HeadingListItem({ heading }: { heading: HeadingAnalysisNode }) {
  const handleClick = () => {
    const $el = document.querySelector(`[data-heading-analyzer-index="${heading.index}"]`);
    const outlineCssClass = styles.outline ?? '';

    $el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'center' });
    $el?.classList.add(outlineCssClass);

    setTimeout(() => {
      $el?.classList.remove(outlineCssClass);
    }, 4000);
  };

  const textStyle = cn('block text-left', { 'text-danger': heading.hasError });
  const headingDescription = (
    <>
      <span className="font-bold">H{heading.headingLevel}:</span> {heading.text}
    </>
  );

  return (
    <li
      aria-label={`H${heading.headingLevel}: ${heading.text}`}
      className="relative px-4 py-2 before:absolute before:-left-2 before:top-5 before:h-px before:w-4 before:bg-[#101010]"
    >
      {heading.isMissing ? (
        <span className={textStyle}>{headingDescription}</span>
      ) : (
        <button className={textStyle} type="button" onClick={handleClick}>
          {headingDescription}
        </button>
      )}
      {heading.children?.length ? (
        <div className="mt-4">
          <HeadingList>
            {heading.children.map((child) => (
              <HeadingListItem key={child.text} heading={child} />
            ))}
          </HeadingList>
        </div>
      ) : null}
    </li>
  );
}
