import { ResponsiveImage, ResponsivePicture } from '@dx-ui/osc-responsive-image';
import type { TResponsiveImage, TResponsivePicture } from '@dx-ui/osc-responsive-image';

export type DynamicImageProps = Pick<
  TResponsiveImage,
  'id' | 'imageUrl' | 'altText' | 'aspectRatio' | 'width' | 'captionData' | 'className'
> &
  Pick<
    TResponsivePicture,
    'tabletAspectRatio' | 'mobileAspectRatio' | 'tabletImageUrl' | 'mobileImageUrl'
  >;

export const DynamicImage: React.FC<DynamicImageProps> = ({
  id,
  imageUrl,
  altText,
  aspectRatio,
  tabletAspectRatio,
  mobileAspectRatio,
  tabletImageUrl,
  mobileImageUrl,
  width,
  captionData,
  className,
}) => {
  const sharedProps = {
    id: `dynamic-grid-wom-item-img-${id}`,
    aspectRatio,
    imageUrl,
    altText,
    width,
    captionData,
    className,
  };

  return tabletImageUrl || mobileImageUrl ? (
    <ResponsivePicture
      {...sharedProps}
      tabletAspectRatio={tabletAspectRatio}
      mobileAspectRatio={mobileAspectRatio}
      tabletImageUrl={tabletImageUrl}
      mobileImageUrl={mobileImageUrl}
    />
  ) : (
    <ResponsiveImage {...sharedProps} />
  );
};
