import { ANALYTICS_NO_VALUE, ImageClickID, ImageFunction, ImageType } from '@dx-ui/config-metrics';
import type { FullWidthMediaMetrics } from '../types/full-width-media-analytics';

export const FULL_WIDTH_MEDIA_POSITION = 1;
export const FULL_WIDTH_MEDIA_COUNT = 1;

export type GenerateFullWidthMediaMetricsParams = {
  metrics?: Partial<FullWidthMediaMetrics>;
  headline?: string;
  itemTitle?: string;
};

export const generateFullWidthMediaMetrics = ({
  headline,
  itemTitle,
  metrics,
}: GenerateFullWidthMediaMetricsParams): FullWidthMediaMetrics => {
  const fullWidthMediaMetrics = {
    click: {
      ...metrics?.click,
      clickID: ImageClickID.Img,
    },
    osc: {
      ...metrics?.osc,
      image: {
        ...metrics?.osc?.image,
        headline: metrics?.osc?.image?.headline || headline || ANALYTICS_NO_VALUE,
        itemTitle: itemTitle || ANALYTICS_NO_VALUE,
        position: FULL_WIDTH_MEDIA_POSITION,
        count: FULL_WIDTH_MEDIA_COUNT,
        type: ImageType.Full,
        function: ImageFunction.Link,
      },
    },
  };
  return fullWidthMediaMetrics;
};

export const generatePartialFullWidthMediaMetrics = (
  headline: string | null | undefined
): Partial<FullWidthMediaMetrics> => {
  return {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        headline: headline || ANALYTICS_NO_VALUE,
        type: ImageType.Full,
        function: ImageFunction.Link,
      },
    },
  };
};
