import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';

import BrandOffset from './BrandOffset';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

export default createCpmComponentDefinition(
  'Offset Grid',

  function mapComponentData({ data, componentParams }) {
    const images = data.cpmAssets;

    return {
      id: data.id,
      links: data.links,
      headline: data?.headline ?? '',
      shortDescription: data?.shortDescription ?? '',
      isInversePattern: componentParams?.display === 'mirror' || false,
      images: {
        small: {
          alt: images[0]?.altText ?? '',
          src:
            images[0]?.aspectRatios['2x1']?.url ??
            images[0]?.aspectRatios['16x9']?.url ??
            images[0]?.aspectRatios['3x2']?.url ??
            '',
          captionData: {
            caption: images[0]?.caption ?? '',
            captionLink: images[0]?.captionLink ?? '',
          },
        },
        large: {
          alt: images[1]?.altText ?? '',
          src:
            images[1]?.aspectRatios['2x1']?.url ??
            images[1]?.aspectRatios['16x9']?.url ??
            images[1]?.aspectRatios['3x2']?.url ??
            '',
          captionData: {
            caption: images[1]?.caption ?? '',
            captionLink: images[1]?.captionLink ?? '',
          },
        },
      },
      link: data.link?.url
        ? {
            _id: data.link?._id ?? '',
            adaDescription: data.link?.adaDescription ?? '',
            isNewWindow: data.link?.isNewWindow ?? false,
            label: data.link?.label ?? '',
            url: data.link?.url ?? '',
            experimentationConfiguration: data.link.experimentationConfiguration,
          }
        : undefined,
    };
  },

  function OffsetCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const data = items[0];

    if (!data) {
      return null;
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={brandCode === 'GU'}>
          <BrandOffset
            image={data?.images}
            headline={data?.headline}
            isInversePattern={data?.isInversePattern}
            shortDescription={data?.shortDescription}
            link={data?.link}
            brandComponentTheme={componentParams?.theme}
          />
        </AnimateRevealItem>
      </BrandComponentThemeInline>
    );
  }
);
