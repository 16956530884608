import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import { Link } from '@dx-ui/osc-link';
import type { IconName } from '@dx-ui/osc-icon';
import Icon from '@dx-ui/osc-icon';
import { getDependencies } from './utils/error-page-utils';

export type ErrorKey = 'unknown' | 'technical' | '404';

export type THelpfulLink = {
  adaDescription: string;
  linkLabel: string;
  linkURL: string;
  isNewWindow: boolean;
} & ({ iconUrl: string } | { icon: IconName });

export type ErrorPageProps<TMetrics, TMetricsProps> = React.PropsWithChildren<{
  /**
   * error type
   */
  error: ErrorKey;

  helpfulLinks: THelpfulLink[];

  /**
   * Metrics object with "setErrorPageData" method
   */
  metrics?: TMetrics & { setErrorPageData: (args: TMetricsProps) => void };

  /**
   * Props that are being passed to "setErrorPageData"
   */
  metricsProps?: TMetricsProps;
}>;

/** Technical error page, Not Found error page and Unknown error page that could be used as a Fallback UI. */
export const ErrorPage = <T, K>({
  error,
  helpfulLinks,
  children,
  metrics,
  metricsProps,
}: ErrorPageProps<T, K>) => {
  const { t } = useTranslation('osc-error-page');

  const useEffectDeps: unknown[] = [];

  if (metricsProps) {
    useEffectDeps.push(...getDependencies(metricsProps));
  }

  useEffect(() => {
    if (metrics && metricsProps) {
      metrics.setErrorPageData(metricsProps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics, metricsProps, ...useEffectDeps]);

  return (
    <div className="h-screen">
      <div className="flex flex-col px-8 py-10 sm:h-[80vh] sm:items-center sm:justify-center sm:p-20">
        <h1 className="text-4xl font-black text-primary sm:text-center xl:mb-4 xl:text-7xl">
          {t(`${error}.heading`)}
        </h1>

        {error !== 'unknown' && t(`${error}.subheading`) ? (
          <h2
            data-testid="pageSubTitle"
            className="mb-6 text-sm font-bold tracking-wider text-text-alt sm:mb-9 sm:text-base xl:mb-4"
          >
            {t(`${error}.subheading`).toUpperCase()}
          </h2>
        ) : null}

        <p className="mb-4 text-base sm:mb-8 sm:text-center xl:mb-16 xl:text-2xl">
          {t(`${error}.description`)}
        </p>

        <p className="text-lg font-bold sm:text-lg xl:text-2xl">{t(`${error}.action`)}</p>

        {helpfulLinks ? (
          <nav className="mb-2 py-2 sm:self-stretch sm:py-10">
            <ul className="flex flex-col flex-wrap items-start sm:flex-row sm:px-8 md:px-12 lg:px-16">
              {helpfulLinks?.map &&
                helpfulLinks.map((link) => {
                  return (
                    <li key={link.linkLabel} className="flex justify-center px-1 sm:flex-1">
                      <Link
                        url={link.linkURL}
                        className="flex flex-nowrap items-center justify-center whitespace-nowrap py-2 text-base text-primary xl:text-lg"
                      >
                        <span className="block ltr:mr-2 rtl:ml-2" aria-hidden>
                          {'iconUrl' in link ? (
                            <ReactSVG src={link.iconUrl} className="w-5" />
                          ) : (
                            <Icon name={link.icon} className="w-5" />
                          )}
                        </span>
                        {link.linkLabel}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </nav>
        ) : null}

        {children ? (
          <div
            className="flex flex-col items-stretch justify-stretch self-stretch sm:items-center md:flex-row"
            id="callme-wrapper"
          >
            {children}
          </div>
        ) : null}
      </div>
      <div className="absolute bottom-16 right-0" id="chatnow-wrapper" />
    </div>
  );
};

export default ErrorPage;
