import { ANALYTICS_NO_VALUE, ImageClickID, ImageFunction, ImageType } from '@dx-ui/config-metrics';
import type { HalfAndHalfMetrics } from '../types/half-and-half-analytics';

export const HALF_AND_HALF_IMAGE_COUNT = 1;

type GenerateHalfAndHalfMetricsParams = {
  headline?: string;
  itemTitle?: string;
};

export const generateHalfAndHalfMetrics = ({
  headline,
  itemTitle,
}: GenerateHalfAndHalfMetricsParams): HalfAndHalfMetrics => {
  const halfAndHalfMetrics = {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        headline: headline || ANALYTICS_NO_VALUE,
        itemTitle: itemTitle || ANALYTICS_NO_VALUE,
        position: HALF_AND_HALF_IMAGE_COUNT,
        count: HALF_AND_HALF_IMAGE_COUNT,
        type: ImageType.Half,
        function: ImageFunction.Link,
      },
    },
  };

  return halfAndHalfMetrics;
};
