export type Segments = Array<string>;

export type SegmentedItem = {
  segmentIds?: Segments;
};

type GetFilteredItemsOptions<TProps> = {
  items: Array<TProps & SegmentedItem>;
  segments: Segments;
  numItems?: number;
};

export const getUnsegmentedItems = <T extends SegmentedItem>(items: Array<T>) =>
  items?.filter((item) => item.segmentIds?.length === 0 || !item.segmentIds) || [];

export const getFilteredItems = <T>({ segments, items, numItems }: GetFilteredItemsOptions<T>) => {
  const filteredItems = items?.filter((item) => {
    // Don't filter any items with 0 segments
    if ((item.segmentIds?.length ?? 0) <= 0) {
      return true;
    }

    return segments?.filter((segmentId) => item?.segmentIds?.includes(segmentId))?.length > 0;
  });

  return numItems ? filteredItems?.slice(0, numItems) : filteredItems;
};
