import * as React from 'react';
import { sendInteractionReward } from '../../header.utilities';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useTranslation } from 'next-i18next';
import type { LoginResponse } from '@dx-ui/osc-login';
import { LoginIFrame } from '@dx-ui/osc-login';
import { NavItemVisual } from '../nav-items';
import type { TNavItemWithModal } from '../nav-items';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import type { HeaderProps } from '../../types';
import { type IconNames } from '@dx-ui/osc-icon';

type IconName = (typeof IconNames)[number];

type NavItemSection = 'navigation' | 'user';

export const NavItemWithModalAndGlow: React.FC<React.PropsWithChildren<TNavItemWithModal>> = ({
  theme,
  section,
  buttonLabel,
  buttonIcon,
  onClick,
  title,
  onLoginAttempt,
  children,
}) => {
  const [showDialog, setShowDialog] = React.useState(false);

  const open = () => {
    sendInteractionReward();
    onClick?.();
    setShowDialog(true);
  };
  const close = () => setShowDialog(false);

  const handleLogin = (response: LoginResponse) => {
    onLoginAttempt?.(response);
    if (!response.error) {
      close();
    }
  };

  const arrayChildren = React.Children.toArray(children);

  const [t] = useTranslation('osc-login');

  return (
    <>
      <li className="flex items-center justify-center">
        <button
          className="btn btn-primary-outline flex items-center py-1 pe-3 ps-4 shadow-[0_0_15px_rgb(0,144,147)] transition-shadow duration-300 ease-in-out hover:shadow-none"
          data-testid="with-modal"
          type="button"
          onClick={open}
        >
          <NavItemVisual
            label={buttonLabel ?? t('signIn')}
            icon={buttonIcon}
            theme={theme}
            section={section}
          />
        </button>
      </li>
      <Dialog isOpen={showDialog} onDismiss={close} size="md" title={title}>
        {React.Children.map(arrayChildren, (child) => {
          if (React.isValidElement(child) && child.type === LoginIFrame) {
            return React.cloneElement(
              child as React.ReactElement<React.ComponentProps<typeof LoginIFrame>>,
              {
                onLoginAttempt: handleLogin,
                onClose: close,
              }
            );
          }
          return child;
        })}
      </Dialog>
    </>
  );
};

type NavItemWithLinkProps = Omit<Link, 'className' | 'children' | 'icon'> & {
  theme: 'dark' | undefined;
  section: NavItemSection;
  label: string;
  icon?: React.ReactElement;
  namedIcon?: IconName;
  isWide?: boolean;
  experimentationConfiguration?: CmsExperimentationConfiguration;
};

type NavItemProps = Omit<React.ComponentProps<'li'>, 'className' | 'children'> &
  Pick<HeaderProps, 'theme'> & {
    isOpen?: boolean;
    children: React.ReactElement;
  };

export function NavItem({ children, ...listItemProps }: NavItemProps) {
  const spacedChild = React.cloneElement(children, {
    className: cx(children.props.className, 'flex-1 self-stretch'),
  });

  return (
    <li
      {...listItemProps}
      className="group flex h-full items-center border-b-8 border-transparent px-1 pt-1 text-sm hover:text-primary hover:underline"
    >
      {spacedChild}
    </li>
  );
}

export const NavItemWithLinkGlow: React.FC<NavItemWithLinkProps> = ({
  theme,
  namedIcon,
  section,
  label,
  isWide,
  experimentationConfiguration,
  ...rest
}) => {
  return (
    <NavItem theme={theme} data-testid="with-link">
      <Link
        underline={false}
        {...rest}
        data-conductrics-goal={experimentationConfiguration?.goal}
        data-conductrics-value={experimentationConfiguration?.value}
        className={cx('flex items-center whitespace-nowrap', isWide ? 'p-2' : 'p-1', {
          'nav-main-link': section === 'navigation',
        })}
      >
        <NavItemVisual section={section} theme={theme} label={label} icon={namedIcon} />
      </Link>
    </NavItem>
  );
};
