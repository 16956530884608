import type { Page } from '@bloomreach/spa-sdk';
import { initialize } from '@bloomreach/spa-sdk';
import type { QueryClient } from '@tanstack/react-query';

import type {
  BrComponent,
  BrPage,
  BrPageModel,
  CpmPageModel,
  ExtractCampaignCodeTaggingValuesFn,
} from '../adapters/types';
import type { serverSideCmsPageModelQuery as serverSideCmsPageModelQueryImport } from '../generated/queries';
import type { KeyedMappingDefinition, CpmServerBundle } from './types';
import type { CpmMappingFunctionRelaxed, CpmPreMappedDataInstanceRelaxed } from './typesRelaxed';
import { mapCpmData } from './mapCpmData';
import { getBrComponentName, isMappedComponentName, type MappedComponentName } from '../schema';
import { removeUndefineds } from '../helpers';
import { type CpmMappedPage, extractMappedPage } from './cpmMappedPage';
import { getPath } from '../utils/unknown-object-helpers';
import { getTaggingValuesFromCpmAnalytics } from '../utils/add-campaign-id-to-url';
import { logError } from '@dx-ui/framework-logger';

export function makeFetchServerCpmPage(
  definitions: Partial<KeyedMappingDefinition>,
  serverSideCmsPageModelQuery: typeof serverSideCmsPageModelQueryImport
) {
  function preMapComponent(
    componentName: MappedComponentName,
    componentBr: BrComponent,
    pageBr: BrPage,
    mappedPage: CpmMappedPage,
    extractCampaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn
  ): CpmPreMappedDataInstanceRelaxed {
    type MapData = CpmMappingFunctionRelaxed | undefined;

    const mapData = definitions[componentName]?.mapData as MapData;

    if (!mapData) {
      throw new Error(`No mapping provided for component "${componentName}"`);
    }

    const mappedData = mapCpmData(
      componentName,
      mapData as unknown as CpmMappingFunctionRelaxed,
      pageBr,
      componentBr,
      mappedPage,
      {
        add: () => null,
        clear: () => null,
      },
      extractCampaignCodeTaggingValues
    );

    return { componentName, mappedData } as unknown as CpmPreMappedDataInstanceRelaxed;
  }

  function preMapSection(
    pageBr: Page,
    mappedPage: CpmMappedPage,
    sectionName: string,
    extractCampaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn
  ): Record<string, CpmPreMappedDataInstanceRelaxed> {
    const brComponents = pageBr.getComponent(sectionName)?.getChildren() ?? [];

    return Object.fromEntries(
      brComponents
        .map(function applyDataMapping(componentBr) {
          let data: CpmPreMappedDataInstanceRelaxed | null = null;

          try {
            const componentName = getBrComponentName(componentBr);
            if (!isMappedComponentName(componentName)) {
              throw new Error(`Cannot map unknown component`);
            }

            data = preMapComponent(
              componentName,
              componentBr,
              pageBr,
              mappedPage,
              extractCampaignCodeTaggingValues
            );
          } catch {
            const [id, label, ref] = [
              getPath(componentBr, ['model', 'id']),
              getPath(componentBr, ['model', 'label']),
              getPath(componentBr, ['model', 'links', 'self', 'href']),
            ];

            logError(
              'Cpm Fetch Error',
              'preMap',
              `${String(label)}|${String(id)}|${String(label)}|${String(ref)}`
            );
          }

          return {
            isUntranslated: !!getPath(componentBr, ['model', 'meta', 'paramsInfo', 'oneLinkNoTx']),
            data,
          };
        })
        .map(function assignIndexedKeys({ isUntranslated, data }, i, { length }) {
          return [
            [
              'cpm-',
              i.toString().padStart(Math.ceil(Math.log10(length)) + 1, '0'),
              isUntranslated ? '-noTx' : '',
            ].join(''),
            data,
          ];
        })
        .filter((item) => item[1] !== null)
    );
  }

  return async function fetchServerCpmPage(
    queryClient: QueryClient,
    contentPath: string,
    localeCode: string,
    pathname: string,
    sectionNames: string[],
    extractCampaignCodeTaggingValues = getTaggingValuesFromCpmAnalytics
  ): Promise<CpmServerBundle<KeyedMappingDefinition> | null> {
    const cmsPageModel: CpmPageModel = (
      await serverSideCmsPageModelQuery(queryClient, {
        path: contentPath,
        language: localeCode,
      })
    ).cmsPageModel;

    if (!cmsPageModel) {
      return null;
    }

    const cpmPageModel = cmsPageModel;

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bloomReachConfiguration: any = {
      httpClient: () => Promise.resolve({ data: cpmPageModel }),
    };

    const cpmPage = initialize(bloomReachConfiguration, cpmPageModel as BrPageModel);
    const mappedPage = extractMappedPage(pathname, localeCode, cpmPage);
    const preMappedData = Object.fromEntries(
      sectionNames.map((sectionName) => [
        sectionName,
        preMapSection(cpmPage, mappedPage, sectionName, extractCampaignCodeTaggingValues),
      ])
    );
    const experimentationAgentIds = Array.from(
      Object.values(preMappedData)
        .flatMap((section) => Object.values(section))
        .reduce((agentIds, component) => {
          const mappedAgentIds = component.mappedData.experimentationAgentIds;

          if (mappedAgentIds?.size) {
            return new Set([...agentIds, ...mappedAgentIds]);
          }

          return agentIds;
        }, new Set<string>())
    ).filter((id) => !!id);

    return removeUndefineds({
      contentPath,
      mappedPage,
      preMappedData,
      experimentationAgentIds,
    });
  };
}
