import { Pagination } from '@dx-ui/osc-pagination';
import { useCycle } from '@dx-ui/osc-cycle';
import cx from 'classnames';
import {
  ANALYTICS_GLOBAL_CLICK_EVENT,
  ANALYTICS_NO_VALUE,
  ImageClickID,
  trackEvent,
} from '@dx-ui/config-metrics';
import { generateImageHeadlinerMetrics } from './utils/image-headliner-analytics';
import type { TImageHeadlinerItemData } from './image-headliner';

export type TImageHeadlinerControls = {
  label?: string;
  hideLabel?: boolean;
  hasLoop?: boolean;
  brandComponentTheme?: CmsBrandComponentTheme;
  itemData: TImageHeadlinerItemData[];
};

const ImageHeadlinerControls = ({
  label = 'Page',
  hideLabel = true,
  hasLoop = true,
  brandComponentTheme,
  itemData,
}: TImageHeadlinerControls) => {
  const { hasSlides, cycleId, active, total, onNextClick, onPreviousClick } = useCycle();

  const currentItem = itemData[active];
  const currentHeadline = currentItem?.headline;
  const currentCtaText = currentItem?.ctaText;
  const currentPosition = currentItem?.position || 1;
  const totalItems = itemData.length;
  const trackImageHeadlinerScrollClick = () => {
    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateImageHeadlinerMetrics({
        clickID: ImageClickID.Scroll,
        headline: currentHeadline,
        itemTitle: currentCtaText || ANALYTICS_NO_VALUE,
        position: currentPosition,
        count: totalItems,
      })
    );
  };

  const onNextClickWithAnalytics = () => {
    trackImageHeadlinerScrollClick();
    onNextClick();
  };
  const onPreviousClickWithAnalytics = () => {
    trackImageHeadlinerScrollClick();
    onPreviousClick();
  };

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return hasSlides ? (
    <div
      data-e2e="arrows"
      className={cx(
        'pointer-events-none inset-0 mr-4 justify-end whitespace-nowrap text-xl font-bold lg:text-2xl',
        {
          'text-text-inverse': isDark,
          'brand-ht:text-text-inverse': isLight,
          'image-headliner-controls-default': !isDark && !isLight,
        }
      )}
    >
      <Pagination
        controls={cycleId}
        label={label}
        hideLabel={hideLabel}
        current={active}
        total={total}
        onNextClick={onNextClickWithAnalytics}
        onPreviousClick={onPreviousClickWithAnalytics}
        loop={hasLoop}
        // TODO: bring this prop back once OSC-1278 is complete
        // // isHeadliner={true}
      />
    </div>
  ) : null;
};

export { ImageHeadlinerControls };
export default ImageHeadlinerControls;
