import type * as React from 'react';
import cx from 'classnames';

import { TextBlock } from '@dx-ui/osc-text-block';
import type { ExperimentationConfiguration, OscDomLink } from '@dx-ui/cpm-sdk';
import { createCpmComponentDefinition, CpmMappingError } from '@dx-ui/cpm-sdk';

import { MarkdownBlock } from './MarkdownBlock';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

import { CurvedHeading } from '@dx-ui/osc-curved-heading';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { ByHilton } from './svg/by-hilton';

export type ParsedContentSection = Exclude<
  React.ComponentProps<typeof TextBlock>['data'],
  undefined
>[number];

export default createCpmComponentDefinition(
  'Text Component',

  function mapData({
    data,
    cmsDocumentType,
    componentParams,
    mappedPage: { brandCode },
    addIssue,
    clearIssue,
  }) {
    if (data.markdownEditor) {
      return {
        experimentationConfiguration: data.experimentationConfiguration,
        links: data.links,
        markdown: data.markdownEditor,
      } as {
        markdown: string;
        experimentationConfiguration?: ExperimentationConfiguration;
        links: OscDomLink[];
      };
    }

    const isStory = cmsDocumentType === 'Story';
    const isGraduateBrand = brandCode === 'GU';

    const mappedContentBlocks: Array<ParsedContentSection | null> = (data.contentBlock ?? []).map(
      (section): ParsedContentSection | null => {
        if (section?.description) {
          return { kind: 'description', content: section.description };
        }
        if (section?.heading) {
          return { kind: 'heading', content: section.heading };
        }
        if (section?.orderedList) {
          return { kind: 'orderedList', content: section.orderedList };
        }
        if (section?.unorderedList) {
          return { kind: 'unorderedList', content: section.unorderedList };
        }

        return null;
      }
    );

    if (isStory && data?.links?.length) {
      const link = data.links[0];

      if (link) {
        mappedContentBlocks.push({
          kind: 'cta',
          content: {
            _id: '',
            url: link.url,
            label: link.label,
            adaDescription: link.adaDescription || '',
            isNewWindow: link.isNewWindow || false,
            experimentationConfiguration: link.experimentationConfiguration,
          },
        });
      }
    }

    const blocks = mappedContentBlocks.filter((section): section is ParsedContentSection =>
      Boolean(section)
    );

    if (componentParams?.curvedHeader) {
      const headlineTooLong = data?.headline?.length > 12;
      const isTextAlignUsed =
        componentParams?.textAlign === 'left' || componentParams?.textAlign === 'right';

      if (!isGraduateBrand) {
        throw new CpmMappingError(
          `Curved Header parameter is only supported on Graduate brand pages.`
        );
      }

      if (headlineTooLong) {
        throw new CpmMappingError(`Curved Header headline has a hard limit of 12 characters.`);
      }

      if (!isStory) {
        throw new CpmMappingError(`Curved Header is only supported in Story Documents.`);
      }

      if (isTextAlignUsed) {
        addIssue({
          id: data.id,
          message:
            'Curved Header is always centered. Setting Text Display to Left or Right will have no effect.',
        });
      } else {
        clearIssue(data.id);
      }
    }

    if (componentParams?.headerSvg === 'byHilton') {
      if (!isGraduateBrand) {
        throw new CpmMappingError(
          `Curved Header SVG parameter is only supported on Graduate brand pages.`
        );
      }
    }

    if (!blocks.length && (!data.headline || !data.shortDescription)) {
      addIssue({
        id: data.id,
        message: `"${data.name || data.displayName}" has no content attached`,
      });
    } else {
      clearIssue(data.id);
    }

    return {
      links: data.links,
      headline: data.headline,
      shortDescription: data.shortDescription,
      longDescription: data.longDescription,
      blocks,
    };
  },

  function TextBlockCpm({ items = [], componentParams, mappedPage: { brandCode, languageCode } }) {
    const { textAlign, animation, theme, borderTrim } = componentParams;

    const data = items[0];

    if (!data) {
      return null;
    }

    if ('headline' in data && 'shortDescription' in data) {
      const isCurvedHeader = componentParams.curvedHeader;
      const disableCurve = languageCode !== 'en' ? true : false;

      if (isCurvedHeader) {
        return (
          <BrandComponentThemeInline
            componentParams={componentParams}
            brandCode={brandCode}
            backgroundIllustration={{
              isParallax: componentParams?.backgroundParallax,
              variant: componentParams?.backgroundIllustration,
            }}
          >
            <div className="container">
              <div className="flex justify-center overflow-y-hidden">
                <CurvedHeading
                  curvedText={data?.headline}
                  textBelow={data?.shortDescription}
                  shouldDisable={disableCurve}
                  textClassNames={
                    theme === 'dark' ? 'text-text-inverse' : 'brand-ht:text-text-inverse'
                  }
                  className="py-6 text-center"
                  nodeBelow={
                    componentParams.headerSvg === 'byHilton' && (
                      <ByHilton
                        className="mx-auto mt-3"
                        fill={theme === 'dark' ? 'fill-text-inverse' : 'fill-text-heading'}
                      />
                    )
                  }
                />
              </div>
              {data?.longDescription ? (
                <BrandTextBody
                  brandComponentTheme={theme}
                  className={cx('pb-6 text-center', theme === 'dark' ? 'text-text-inverse' : '')}
                >
                  {data?.longDescription}
                </BrandTextBody>
              ) : null}
            </div>
          </BrandComponentThemeInline>
        );
      }
    }

    if ('blocks' in data) {
      if (!data?.blocks.length) {
        return null;
      }

      const filteredBlocks = data.blocks.map((block) => {
        // Replace link with one that has been filtered by experimentation agent. Links are filtered by the CPM SDK.
        // The link will be the same one as the mapping function if no experimentation agent(s) have been defined.
        if (block.kind === 'cta' && data.links?.[0]) {
          block.content = data.links[0];
        }

        return block;
      });

      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <TextBlock
            textAlign={textAlign}
            isAnimated={animation || brandCode === 'GU'}
            isBorderTrim={borderTrim}
            data={filteredBlocks}
            brandComponentTheme={theme}
          />
        </BrandComponentThemeInline>
      );
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <MarkdownBlock
          textAlign={textAlign}
          isAnimated={animation || brandCode === 'GU'}
          isBorderTrim={borderTrim}
          markdown={data.markdown}
          brandComponentTheme={theme}
        />
      </BrandComponentThemeInline>
    );
  }
);
