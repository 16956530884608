import { useState } from 'react';
import {
  brandIconMapper,
  iconMapper,
  TabListButton,
  TabPanel,
  TabPanels,
  Tabs,
  TabListScrollable,
} from '@dx-ui/osc-tabs';
import cx from 'classnames';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import Icon from '@dx-ui/osc-icon';
import Image from 'next/image';

import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';
import type {
  BrandHorizontalTabProps,
  BrandHorizontalTabsProps,
} from './brand-horizontal-tabs.types';
import { BrandHorizontalTabbedSectionContent } from './brand-horizontal-tabbed-section-content';
import { HeadingLevelProvider } from '@dx-ui/osc-heading-level';
import { dispatchVideoEvent, VIDEO_EVENTS } from '@dx-ui/osc-video-player';

type TabIconProps = {
  iconType: string;
  isDark: boolean;
};

const TabIcon = ({ iconType = '', isDark = false }: TabIconProps) => {
  const brandIcon = iconType ? brandIconMapper[iconType] : null;
  if (brandIcon) {
    const { brandCode, name } = brandIcon;
    if (brandCode === 'ES') {
      const iconName = isDark ? brandIcon.invertedName : name;
      return <Icon brandCode={brandCode} name={iconName} size="5xl" />;
    }
    if (brandCode === 'EY') {
      return <Icon brandCode={brandCode} name={name} size="lg" />;
    }
    return null;
  }
  const name = iconMapper[iconType];

  if (!name) {
    return null;
  }

  return <Icon name={name} size="lg" />;
};

export const BrandHorizontalTabbedSection = ({
  brandComponentTheme,
  defaultTab,
  listDescription,
  listHeadline,
  textAlign = 'left',
  items,
  id,
  onTabChange,
}: BrandHorizontalTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  if (!items.length) return null;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const hasIconOrLogo = items.some((item) => item.logoUrl || item.iconType);

  return (
    <section
      className={cx('container py-8 brand-wa:py-16 xl:py-12 brand-wa:xl:py-20', {
        'tabbed-bg-dark': isDark,
        'tabbed-bg-light': isLight,
        'tabbed-bg': !isDark && !isLight,
      })}
      data-testid="tabbedsection-items-wrapper"
    >
      {items.length > 1 ? (
        <>
          {listHeadline ? (
            <BrandTextHeader
              id={id}
              className={cx('relative', mapTextAlignToClassname(textAlign), {
                'tabbed-list-headline-dark': isDark,
                'tabbed-list-headline-light': isLight,
              })}
            >
              {listHeadline}
            </BrandTextHeader>
          ) : null}

          {listDescription ? (
            <BrandTextBody
              brandComponentTheme={brandComponentTheme}
              className={cx('relative', mapTextAlignToClassname(textAlign), {
                'tabbed-list-description-dark': isDark,
                'tabbed-list-description-light': isLight,
                'tabbed-list-description': !isDark && !isLight,
              })}
            >
              {listDescription}
            </BrandTextBody>
          ) : null}

          <Tabs
            key={`${id}-${items.length}`}
            changeWithArrow
            onTabChange={(tabId = '') => {
              setSelectedTab((currentId = '') => {
                dispatchVideoEvent({ type: VIDEO_EVENTS.PAUSE_SELF, id: currentId });
                return tabId;
              });
              onTabChange?.(tabId);
            }}
            defaultActive={defaultTab}
          >
            <TabListScrollable
              id={id}
              data-testid={`tablist-${id}`}
              className={cx('flex flex-row flex-nowrap overflow-x-scroll border-b scrollbar-hide', {
                'horizontal-tabbed-list-scrollable-dark': isDark,
                'horizontal-tabbed-list-scrollable-light': isLight,
                'horizontal-tabbed-list-scrollable': !isDark && !isLight,
              })}
              leftArrowWrapperClassName={{
                'horizontal-tabbed-arrow-wrapper-dark': isDark,
                'horizontal-tabbed-arrow-wrapper-light': isLight,
                'horizontal-tabbed-arrow-wrapper': !isDark && !isLight,
              }}
              rightArrowWrapperClassName={{
                'horizontal-tabbed-arrow-wrapper-dark': isDark,
                'horizontal-tabbed-arrow-wrapper-light': isLight,
                'horizontal-tabbed-arrow-wrapper': !isDark && !isLight,
              }}
            >
              {items.map(({ id, tab: { label }, cmsTranslationClasses, logoUrl, iconType }) => (
                <TabListButton
                  data-testid={label}
                  activeClassName={cx('is-selected selected group z-10 brand-hp:font-bold', {
                    'horizontal-tabbed-list-button-active-dark': isDark,
                    'horizontal-tabbed-list-button-active-light': isLight,
                    'horizontal-tabbed-list-button-active': !isDark && !isLight,
                    'horizontal-tabbed-list-button-active-bg-no-icon-dark':
                      isDark && !iconType && !logoUrl,
                    'horizontal-tabbed-list-button-active-bg-no-icon-light':
                      isLight && !iconType && !logoUrl,
                    'horizontal-tabbed-list-button-active-bg-no-icon-default':
                      !isDark && !isLight && !iconType && !logoUrl,
                  })}
                  className={cx(
                    'grow cursor-pointer appearance-none border-b-4 px-4 py-2 md:whitespace-normal',
                    'focus-visible-only-for-a11y',
                    {
                      'min-w-[50%] lg:min-w-[20%]': items.length > 5,
                      'min-w-[50%] lg:min-w-fit': items.length <= 5,
                      'flex items-baseline': logoUrl,
                    }
                  )}
                  inactiveClassName={cx('border-transparent text-text-alt', {
                    'horizontal-tabbed-list-button-inactive-dark': isDark,
                    'horizontal-tabbed-list-button-inactive-light': isLight,
                    'horizontal-tabbed-list-button-inactive': !isDark && !isLight,
                  })}
                  id={id}
                  key={id}
                >
                  <div
                    className={cx(
                      'group m-auto flex min-w-[120px] flex-col items-center text-lg',
                      'brand-es:font-headline',
                      'brand-ey:font-normal',
                      'brand-gi:font-headline brand-gi:font-bold',
                      'brand-hp:shrink-0 brand-hp:font-serif',
                      'brand-nd:font-mono brand-nd:uppercase',
                      'brand-wa:font-sans brand-wa:font-light brand-wa:uppercase',
                      {
                        'hover:text-primary-alt focus:text-primary-alt brand-dt:hover:text-brand brand-dt:focus:text-brand brand-es:hover:text-primary brand-es:focus:text-primary':
                          id !== selectedTab,
                        'font-bold brand-nd:font-medium ': id === selectedTab,
                        'font-medium brand-gu:font-bold': id !== selectedTab,
                        'text-text-inverse': isDark,
                        'hover:text-text-inverse brand-es:hover:text-bg-light brand-es:focus:text-bg-light brand-nd:hover:!text-text-disabled brand-ey:hover:!text-brand-alt':
                          isDark && id !== selectedTab,
                        'brand-ht:!text-text-inverse brand-hp:text-primary': isLight,
                        'brand-hi-refresh:hover:text-primary-alt brand-nd:hover:text-primary-alt brand-wa:hover:text-bg-dark brand-ey:hover:text-primary-alt':
                          !isDark && id !== selectedTab,
                        'text-text brand-ey:text-primary brand-gu:text-primary brand-hi-refresh:text-primary brand-ou:text-primary brand-hp:text-primary brand-hp:hover:text-primary-alt':
                          !isDark,
                        'horizontal-tabbed-list-button-active-text-no-logo-icon-dark':
                          isDark && !iconType && !logoUrl && id === selectedTab,
                      },
                      cmsTranslationClasses
                    )}
                  >
                    {logoUrl ? (
                      <Image
                        src={logoUrl}
                        alt=""
                        width={70}
                        height={70}
                        unoptimized={true}
                        aria-hidden
                      />
                    ) : null}
                    {iconType ? (
                      <div
                        className={cx(
                          'flex size-16 items-center justify-center rounded-full group-[.selected]:bg-bg-alt',
                          'brand-ou:group-[.selected]:bg-bg-light',
                          'brand-es:group-[.selected]:bg-transparent',
                          {
                            'text-text-inverse group-[.selected]:text-text brand-ou:group-[.selected]:bg-secondary brand-ou:group-[.selected]:text-primary':
                              isDark,
                            'brand-es:group-[.selected]:text-text-inverse': isDark,
                            'brand-ey:bg-bg-light brand-ey:text-primary brand-ey:group-[.selected]:text-primary brand-ey:group-[.selected]:bg-brand-alt':
                              isDark,
                            'brand-gu:bg-bg-light brand-gu:text-primary brand-gu:group-[.selected]:bg-quarternary':
                              isDark,
                            'brand-hi-refresh:group-[.selected]:text-primary brand-hi-refresh:group-[.selected]:bg-bg-light':
                              isDark,
                            'brand-nd:bg-text-disabled brand-nd:text-primary brand-nd:group-[.selected]:bg-bg brand-nd:group-[.selected]:text-primary':
                              isDark,
                            'brand-gu:bg-bg-dark brand-gu:text-text-inverse brand-gu:group-[.selected]:bg-quarternary brand-gu:group-[.selected]:text-primary':
                              isLight,
                            'brand-ht:group-[.selected]:text-text': isLight,
                            'brand-ou:group-[.selected]:bg-secondary': isLight,
                            'brand-ey:bg-bg-dark brand-ey:group-[.selected]:bg-secondary brand-ey:text-text-inverse brand-ey:group-[.selected]:text-text-inverse':
                              !isDark,
                            'brand-hi-refresh:group-[.selected]:text-text-inverse brand-hi-refresh:group-[.selected]:bg-primary':
                              !isDark,
                            'brand-nd:bg-text-disabled brand-nd:group-[.selected]:bg-bg-dark brand-nd:group-[.selected]:text-text-inverse':
                              !isDark,
                            'brand-gu:bg-bg-dark brand-gu:text-text-inverse brand-gu:group-[.selected]:bg-bg-light brand-gu:group-[.selected]:text-primary brand-hp:group-[.selected]:bg-quarternary brand-hp:group-[.selected]:text-primary':
                              !isLight,
                          }
                        )}
                      >
                        <TabIcon iconType={iconType} isDark={isDark} />
                      </div>
                    ) : null}
                    <span
                      className={cx('relative', {
                        'py-4': hasIconOrLogo,
                        'py-1 brand-hp:px-4 brand-hp:py-0': !hasIconOrLogo,
                      })}
                    >
                      {id === selectedTab && !hasIconOrLogo ? (
                        <span
                          className={cx(
                            'hidden',
                            'h-full items-center px-4 brand-hp:absolute brand-hp:-start-7 brand-hp:group-[.selected]:flex'
                          )}
                        >
                          <Icon brandCode="HP" name="hex" size="md" />
                        </span>
                      ) : null}
                      {label}
                    </span>
                  </div>
                </TabListButton>
              ))}
            </TabListScrollable>
            <TabPanels>
              {items.map(
                ({
                  id,
                  cmsDocumentControl,
                  panel: { media, image, headline, longDescription, copy, link },
                  cmsTranslationClasses,
                }: BrandHorizontalTabProps) => {
                  return (
                    <HeadingLevelProvider key={id} shouldIncreaseLevel={!!listHeadline}>
                      <TabPanel id={id} className={cmsTranslationClasses}>
                        <BrandHorizontalTabbedSectionContent
                          brandComponentTheme={brandComponentTheme}
                          copy={copy}
                          longDescription={longDescription}
                          headline={headline}
                          image={image}
                          link={link}
                          media={media}
                          id={id}
                        />
                        {cmsDocumentControl}
                      </TabPanel>
                    </HeadingLevelProvider>
                  );
                }
              )}
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <>
          <BrandHorizontalTabbedSectionContent
            brandComponentTheme={brandComponentTheme}
            copy={items[0]?.panel.copy ?? ''}
            longDescription={items[0]?.panel.longDescription ?? ''}
            headline={items[0]?.panel.headline ?? ''}
            image={items[0]?.panel.image}
            link={items[0]?.panel.link}
            media={items[0]?.panel.media}
          />
          {items[0]?.cmsDocumentControl ?? ''}
        </>
      )}
    </section>
  );
};

export default BrandHorizontalTabbedSection;
