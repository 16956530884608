import type { ExperimentationConfiguration, OscDomLink } from '../adapters/types';
import type { AgentsSelection } from '@dx-ui/framework-conductrics';

export type ExperimentationItem = {
  experimentationConfiguration?: ExperimentationConfiguration;
  links?: OscDomLink[];
  link?: OscDomLink;
} | null;

/**
 * Filters out documents and any child links if they do not match conductrics selections.
 * Items without `experimentationConfiguration` will always be returned.
 */
export function filterExperimentationItems<Item extends ExperimentationItem>(
  agents: AgentsSelection,
  shouldRenderExperiment: (
    agents: AgentsSelection,
    config?: CmsExperimentationConfiguration
  ) => boolean,
  items: Item[]
) {
  return items
    .map((item) => {
      if (!shouldRenderExperiment(agents, item?.experimentationConfiguration)) {
        return null;
      }

      return {
        ...item,
        ...filterLinks(agents, shouldRenderExperiment, item),
      };
    })
    .filter((item) => !!item);
}

export function filterLinks<Item extends ExperimentationItem>(
  agents: AgentsSelection,
  shouldRenderExperiment: (
    agents: AgentsSelection,
    config?: CmsExperimentationConfiguration
  ) => boolean,
  item: Item
) {
  if (item?.links?.length) {
    const links = item.links.filter((link) =>
      shouldRenderExperiment(agents, link.experimentationConfiguration)
    );

    return {
      links,
      // Link could be undefined if all links have been filtered out
      link: links[0],
    };
  }

  return {};
}
