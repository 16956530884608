import { useEffect, useRef, useState, type ReactNode } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { JoinForm, type Props } from '@dx-ui/osc-join-form';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { LoginIFrame } from '@dx-ui/osc-login';
import { useTranslation } from 'next-i18next';
import Icon from '@dx-ui/osc-icon';
import { logError } from '@dx-ui/framework-logger';
import { isBrowser } from '@dx-ui/utilities-is-browser';

/** This is a generic button component that will launch a modal with the join form when clicked. */
export function JoinModalButton({
  children,
  className,
  language,
  loginIFrameSrc,
  metrics,
  headline,
  oCode = 'OHWGW',
  onSuccessfulLogin,
  onSuccessfulJoin,
  onClick,
}: {
  children: ReactNode;
  /** Wrapper button classname */
  className?: string;
  language: string;
  loginIFrameSrc: string;
  /** Metrics object from join form */
  metrics: Props['metrics'];
  headline?: Props['headline'];
  oCode?: Props['oCode'];
  onSuccessfulLogin?: () => void;
  onSuccessfulJoin?: () => void;
  onClick?: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showJoinFormContent, setShowJoinFormContent] = useState(false);
  const [showLoginIframe, setShowLoginIframe] = useState(false);
  const [newHonorsNumber, setNewHonorsNumber] = useState('');
  const { login } = useAuth();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const successMessageRef = useRef<HTMLParagraphElement>(null);
  const { t } = useTranslation('osc-join-modal-button');
  const origin = useOrigin();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowJoinFormContent(true);
    onClick?.();
  };

  const handleOpenLoginIframe = () => {
    setShowLoginIframe(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowLoginIframe(false);
  };

  return (
    <Dialog
      onDismiss={handleCloseModal}
      isOpen={isModalOpen}
      size={showLoginIframe ? 'md' : '2xl'}
      title={showLoginIframe ? t('signIn') : t('joinForFree')}
      dialogTrigger={
        <button type="button" className={className} onClick={handleOpenModal} ref={buttonRef}>
          {children}
        </button>
      }
    >
      {showJoinFormContent ? (
        <JoinForm
          language={language}
          oCode={oCode}
          origin={origin}
          headline={
            headline || (
              <h1 className="mb-2 text-center font-headline text-3xl">{t('joinHiltonHonors')}</h1>
            )
          }
          onSuccess={(data) => {
            const honorsNumber = data?.createGuest?.data?.hhonorsNumber;
            if (honorsNumber) {
              onSuccessfulJoin?.();
              setNewHonorsNumber(honorsNumber);
              setShowJoinFormContent(false);
              handleOpenLoginIframe();
            }
          }}
          metrics={metrics}
        />
      ) : null}
      {showLoginIframe ? (
        <>
          <div ref={successMessageRef} className="mt-5 flex bg-success-alt p-2 text-success">
            <Icon name="check-circle" variant="solid" />
            <p className="ml-2">{t('welcomeMessage')}</p>
          </div>
          {newHonorsNumber ? (
            <LoginIFrame
              frameSrc={loginIFrameSrc}
              title={t('signIn')}
              options={{
                username: newHonorsNumber,
              }}
              onLoginAttempt={(result) => {
                if (result.data) {
                  login(result)
                    .then(() => {
                      handleCloseModal();
                      setShowLoginIframe(false);
                      onSuccessfulLogin?.();
                    })
                    .catch((error) => {
                      logError(
                        'OSC_JOIN_MODAL_BUTTON',
                        error,
                        'Error logging in after successful join'
                      );
                    });
                }
              }}
            />
          ) : null}
        </>
      ) : null}
    </Dialog>
  );
}

function useOrigin() {
  const [origin, setOrigin] = useState('https://www.hilton.com');
  useEffect(() => {
    if (isBrowser && window.location) {
      const { protocol, hostname, port } = window.location;
      const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      setOrigin(origin);
    }
  }, []);
  return origin;
}
