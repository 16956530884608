import type { ImageFunctionType } from '@dx-ui/config-metrics';
import { ImageClickID, ImageType, ANALYTICS_NO_VALUE } from '@dx-ui/config-metrics';
import type { FullWidthMediaMetrics } from '../types/full-width-image-analytics';

const FULL_WIDTH__METRIC_POSITION = 1;
const FULL_WIDTH_METRIC_IMAGE_COUNT = 1;

type GenerateFullWidthMediaMetricsParams = {
  headline: string;
  itemTitle?: string;
  imageFunction: ImageFunctionType;
};

export const generateFullWidthMediaMetricsParams = ({
  headline,
  itemTitle,
  imageFunction,
}: GenerateFullWidthMediaMetricsParams): FullWidthMediaMetrics => {
  return {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        headline,
        itemTitle: itemTitle || ANALYTICS_NO_VALUE,
        position: FULL_WIDTH__METRIC_POSITION,
        count: FULL_WIDTH_METRIC_IMAGE_COUNT,
        type: ImageType.Full,
        function: imageFunction,
      },
    },
  };
};
