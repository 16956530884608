import { Header as DxUiHeader, type TDrawerItem } from '@dx-ui/osc-header';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import type { LoginResponse } from '@dx-ui/osc-login';
import { useRouter } from 'next/router';
import { sendReward } from '@dx-ui/framework-conductrics';
import { getBaseNavLinksFromEnv } from '@dx-ui/osc-header';
import { useSpeechBubbleOrGlowMVT } from './utils';

type GuestInfo = ReturnType<typeof useAuth>['guestInfo'];

function getUserFromGuestInfo(guestInfo: GuestInfo) {
  if (!guestInfo) {
    return undefined;
  }

  const hHonorsSummary = guestInfo?.hhonors?.summary;
  const hHonorsNumber = guestInfo?.hhonors?.hhonorsNumber;
  const hHonorsProgramAccountSummary = guestInfo?.hhonors?.programAccountSummary;
  const hHonorsIsHGVMax = Boolean(guestInfo?.hhonors?.isOwnerHGVNew);
  const hHonorsIsTeamMember = Boolean(guestInfo?.hhonors?.isTeamMember);
  const hHonorsIsSMBMember = Boolean(guestInfo?.hhonors?.isSMBMember);

  return {
    name: getGuestFirstName({ guestInfo }),
    honorsTier: hHonorsSummary?.tierName || '',
    honorsPoints: hHonorsSummary?.totalPoints || 0,
    hhonorsNumber: hHonorsNumber || '',
    honorsPointsFmt: hHonorsSummary?.totalPointsFmt || '',
    isHGVMax: hHonorsIsHGVMax || false,
    isTeamMember: hHonorsIsTeamMember || false,
    isSMBMember: hHonorsIsSMBMember || false,
    honorsPrograms: hHonorsProgramAccountSummary || [],
  };
}

function useDxUiHeaderData(
  guestInfo: GuestInfo,
  brandCode: string,
  languageCode: string,
  formalBrandName = 'Hilton',
  brandUrl = '/',
  {
    forgotInfoUrl,
    globalPrivacyUrl,
  }: {
    forgotInfoUrl: string;
    globalPrivacyUrl: string;
  }
) {
  const brandName = brandCode === 'WW' ? 'Hilton For the Stay' : formalBrandName; // `Hilton For The Stay` text is only for portfolio pages

  const brand = {
    code: brandCode,
    name: brandName,
    url: brandUrl,
  };

  const user = getUserFromGuestInfo(guestInfo);

  const loginOptions = {
    options: {
      showCreatePassword: true,
      forgetInfoLink: { url: forgotInfoUrl },
      secureInfoLink: { url: globalPrivacyUrl },
      joinLink: {
        url: getBaseNavLinksFromEnv(languageCode, user ? true : false).signUpLink?.url,
        underline: false,
      },
    },
  };

  return {
    brand,
    user,
    loginOptions,
  };
}

export const goUserTypes = ['TMH', 'TPH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF'];
export const SIGN_IN_SUCCESS = 'g-IqzR2S0Ai5';

export type BrandsHeaderProps = {
  megaNavLinks: TDrawerItem[] | undefined;
  languageCode: string;
  brandCode: string;
  brandFormalName: string | undefined;
  brandUrl: string | undefined;
  showLanguageSelector: boolean;
  honorsSignInUrl: string;
  honorsJoinUrl: string;
  forgotInfoUrl: string;
  globalPrivacyUrl: string;
  honorsAccountUrl: string;
  ohwBaseUrl: string | undefined;
  heading?: string;
  trackUserLoggedIn: (
    data: {
      hhonorsNumber: string;
      tierName: string;
      points: string;
      goUserTypes: string[] | null;
    }[]
  ) => void;
  mainNavLinks:
    | {
        _id: string;
        adaDescription: string;
        campaignId?: string;
        isNewWindow: boolean;
        label: string;
        url: string;
        experimentationConfiguration?: CmsExperimentationConfiguration;
      }[]
    | undefined;
};

export function BrandsHeader({
  trackUserLoggedIn,
  languageCode,
  brandCode,
  mainNavLinks,
  megaNavLinks,
  brandFormalName,
  brandUrl,
  showLanguageSelector,
  forgotInfoUrl,
  globalPrivacyUrl,
  heading,
}: BrandsHeaderProps) {
  const { login, logout, guestInfo, isLoading } = useAuth();
  const router = useRouter();
  const [trackSignIn, setTrackSignIn] = useState(false);

  const { brand, user, loginOptions } = useDxUiHeaderData(
    guestInfo,
    brandCode,
    languageCode,
    brandFormalName,
    brandUrl,
    {
      forgotInfoUrl,
      globalPrivacyUrl,
    }
  );

  const { variant } = useSpeechBubbleOrGlowMVT();

  const theme = ['GU', 'ND'].includes(brandCode) ? 'dark' : undefined;

  const onSignInAttempt = React.useCallback(
    (data: LoginResponse): Promise<void> => {
      const isError = !!data.error;

      if (!isError) {
        sendReward(SIGN_IN_SUCCESS);
      }
      if (data) {
        setTrackSignIn(true);
        return login(data);
      }
      return Promise.reject(new Error('Failed to get data on login response.'));
    },
    [login, setTrackSignIn]
  );

  const onSignOut = React.useCallback((): Promise<void> => logout(), [logout]);

  useEffect(() => {
    if (trackSignIn && !isLoading) {
      // We only want this to trigger once when a user has successfully signed in
      setTrackSignIn(false);
      const availableGoUserTypes: string[] | null = guestInfo?.hhonors?.packages?.filter
        ? guestInfo?.hhonors?.packages
            ?.filter((pack) => pack?.packageName && goUserTypes.includes(pack.packageName))
            ?.map((pack) => pack && pack.packageName)
        : null;

      trackUserLoggedIn([
        {
          hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber ?? '',
          tierName: guestInfo?.hhonors?.summary?.tierName ?? '',
          points: guestInfo?.hhonors?.summary?.totalPointsFmt ?? '',
          goUserTypes: availableGoUserTypes,
        },
      ]);
    }
  }, [guestInfo, isLoading, trackSignIn, trackUserLoggedIn]);

  return (
    <DxUiHeader
      theme={theme}
      mvts={variant ? { '5758': { variant } } : undefined}
      brand={brand}
      showNewHeader={true}
      megaNavLinks={megaNavLinks}
      mainNavLinks={mainNavLinks}
      onSignInAttempt={onSignInAttempt}
      onSignOut={onSignOut}
      user={user}
      loginOptions={loginOptions}
      heading={heading}
      {...(showLanguageSelector && {
        languageSelectorOptions: {
          appName: 'dx-cpm-live',
          locale: router.locale || 'en',
          ...(megaNavLinks && {
            labelOptions: {
              className: 'text-sm',
            },
            buttonOptions: {
              className: 'text-sm',
            },
          }),
        },
      })}
    />
  );
}
