import { EditorialCarousel, Stats, Timeline, Pullquote } from '@dx-ui/osc-editorial-carousel';
import type { EditorialCarouselProps } from '@dx-ui/osc-editorial-carousel';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { Link } from '@dx-ui/osc-link';
import type { Link as LinkType } from '@dx-ui/osc-link';
import { Chip } from '@dx-ui/osc-chip';
import { Icon } from '@dx-ui/osc-icon';
import type { IconNames } from '@dx-ui/osc-icon';
import type { Teaser } from 'dx-shared-schema/dist/types/couchbase/editorial';
import cx from 'classnames';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';

export interface BrandEditorialCarouselProps extends EditorialCarouselProps {
  editorialVariant: 'default' | 'withCarousel';
  teaser?: Teaser;
  chipLabel?: string;
  socialMediaLink?: {
    url: string;
    channel: string;
    linkLabel: string;
  };
  longDescription?: string;
  links?: (LinkType & { experimentationConfiguration?: CmsExperimentationConfiguration })[];
}

/**
 * Brand Offset utilizes the OSC-Editorial-Carousel and renders children
 */
export const BrandEditorialCarousel = ({
  image,
  contentBelowImg,
  carousel,
  editorialVariant,
  headline,
  subHeadline,
  longDescription,
  brandComponentTheme,
  carouselHeadline,
  chipLabel,
  teaser,
  socialMediaLink,
  links,
}: BrandEditorialCarouselProps) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const teaserType = teaser?.type;
  const teaserData =
    teaser?.headingAndDescription?.map((item) => ({
      heading: item.heading || '',
      description: item.description,
    })) || [];

  if (teaserType === 'quote') {
    contentBelowImg = (
      <Pullquote brandComponentTheme={brandComponentTheme}>
        {teaser?.headingAndDescription?.[0]?.description}
      </Pullquote>
    );
  } else if (teaserType === 'statistics' && Array.isArray(teaserData)) {
    contentBelowImg = <Stats data={teaserData} brandComponentTheme={brandComponentTheme} />;
  } else if (teaserType === 'timeline' && Array.isArray(teaserData)) {
    contentBelowImg = <Timeline data={teaserData} brandComponentTheme={brandComponentTheme} />;
  }

  // Don't allow subheadline to contain headings from markdown as it creates invalid html
  subHeadline = subHeadline?.replace(/^(#+\s*)/gm, '').trim();

  return (
    <div className="container relative px-0 min-[1440px]:px-10">
      <EditorialCarousel
        image={image}
        contentBelowImg={editorialVariant === 'withCarousel' ? contentBelowImg : null}
        carousel={carousel}
        headline={headline}
        subHeadline={subHeadline}
        brandComponentTheme={brandComponentTheme}
        carouselHeadline={carouselHeadline}
      >
        <div className="my-4 flex flex-wrap items-center justify-between gap-4">
          {chipLabel ? (
            <Chip label={chipLabel} size="lg" variant={isDark ? 'neutral' : 'general'} />
          ) : null}
          {socialMediaLink ? (
            <Link
              url={socialMediaLink.url}
              className={cx(
                'inline-block items-baseline text-base text-primary hover:text-primary-alt',
                {
                  'text-text-inverse hover:text-text-inverse': isDark,
                  'brand-ht:!text-text-inverse brand-ht:hover:text-text-inverse': isLight,
                }
              )}
              isNewWindow={true}
              underline={false}
            >
              <div className="inline-block pe-1.5">
                <Icon
                  size="lg"
                  name={socialMediaLink.channel?.toLowerCase() as (typeof IconNames)[number]}
                  className="-mb-2 me-1"
                  title={socialMediaLink.channel}
                />
              </div>
              <div className="inline pe-1 align-baseline underline underline-offset-4">
                {socialMediaLink.linkLabel}
              </div>
            </Link>
          ) : null}
        </div>
        <div
          className={cx('border-b border-border', {
            'brand-gu:border-bg': isDark,
            'brand-gu:border-primary brand-ou:border-primary': !isDark,
          })}
        />
        {longDescription ? (
          <BrandTextBody
            className={cx('py-2 text-text brand-wa:font-serif sm:text-lg lg:text-xl', {
              'text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
            })}
            brandComponentTheme={brandComponentTheme}
          >
            {longDescription}
          </BrandTextBody>
        ) : null}
        <div className="my-5">
          {Array.isArray(links)
            ? links.map(({ url, isNewWindow, label, experimentationConfiguration }) => {
                return (
                  <div className="inline" key={url}>
                    <BrandLink
                      label={label}
                      isNewWindow={isNewWindow}
                      showNewWindowIcon={isNewWindow}
                      url={url}
                      brandComponentTheme={brandComponentTheme}
                      data-conductrics-goal={experimentationConfiguration?.goal}
                      data-conductrics-value={experimentationConfiguration?.value}
                      anchorClassName="mb-3 me-3 sm:mb-0"
                    />
                  </div>
                );
              })
            : null}
        </div>
      </EditorialCarousel>
    </div>
  );
};
