import { useEffect, useState } from 'react';

export type HeadingAnalysisNode = {
  headingLevel: number;
  index: number;
  text: string;
  children?: HeadingAnalysisNode[];
  hasError?: boolean;
  isMissing?: boolean;
};

export type HeadingAnalysis = { headings: HeadingAnalysisNode[] | undefined; issueCount: number };

const config = { childList: true, subtree: true };
const defaultValue = { headings: [], issueCount: 0 };

export function useHeadingAnalyzer(rootElementId = '#__next') {
  const [analysis, setAnalysis] = useState<HeadingAnalysis>(defaultValue);

  useEffect(() => {
    const container = document.querySelector(rootElementId);

    if (!container) {
      return;
    }

    setAnalysis(createHeadingTree(container));

    const observer = new MutationObserver(() => setAnalysis(createHeadingTree(container)));
    observer.observe(container, config);

    return () => {
      observer.disconnect();
    };
  }, [rootElementId]);

  return analysis;
}

const MISSING_HEADING_TEXT = 'Heading is missing text content!';

function createHeadingTree(doc: Document | Element) {
  const rootNode: HeadingAnalysisNode = { headingLevel: 0, index: -1, text: '', children: [] };
  const tree = [rootNode];
  let issueCount = 0;

  doc.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(($heading, index) => {
    if ($heading.getAttribute('data-heading-analyzer-ignore')) {
      return;
    }

    const headingLevel = parseInt($heading.tagName.slice(1));
    const text = $heading.textContent ?? MISSING_HEADING_TEXT;
    const hasError = text === MISSING_HEADING_TEXT;
    const node: HeadingAnalysisNode = {
      headingLevel,
      index,
      text,
      children: [],
      hasError,
    };

    if (hasError) {
      issueCount++;
    }

    while (tree.length && tree[tree.length - 1]!.headingLevel >= headingLevel) {
      tree.pop();
    }

    while (tree.length < node.headingLevel) {
      const missingHeadingLevelNode: HeadingAnalysisNode = {
        headingLevel: tree.length,
        index: -tree.length,
        text: 'Missing heading level!',
        children: [],
        hasError: true,
        isMissing: true,
      };
      tree[tree.length - 1]!.children?.push(missingHeadingLevelNode);
      tree.push(missingHeadingLevelNode);
      issueCount++;
    }

    $heading.setAttribute('data-heading-analyzer-index', `${index}`);

    tree[tree.length - 1]!.children?.push(node);
    tree.push(node);
  });

  return {
    issueCount,
    headings: rootNode.children,
  };
}
