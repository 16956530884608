import Markdown from 'markdown-to-jsx';
import type { PropsWithChildren } from 'react';
import cx from 'classnames';

type PullquoteProps = {
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const Pullquote = ({ children, brandComponentTheme }: PropsWithChildren<PullquoteProps>) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  if (typeof children === 'string') {
    return (
      <blockquote
        className={cx(
          'ms-4 px-6 font-serif text-2xl text-primary brand-ey:font-headline brand-ey:font-bold brand-gu:font-sans brand-ou:font-headline brand-wa:text-brand lg:ms-7',
          {
            'text-text-inverse': isDark,
            'brand-ht:text-text-inverse': isLight,
          }
        )}
      >
        <Markdown>{children}</Markdown>
      </blockquote>
    );
  }
  return null;
};

export default Pullquote;
