import Image from 'next/image';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { Caption } from '@dx-ui/osc-caption';
import cx from 'classnames';
import { useRef } from 'react';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { ANALYTICS_GLOBAL_CLICK_EVENT, trackEvent } from '@dx-ui/config-metrics';
import { generateFullWidthMediaMetrics } from './utils/full-width-media-analytics';
import type { FullWidthMediaMetrics } from './types/full-width-media-analytics';
import type { VideoPlayer } from '@dx-ui/osc-video-player';
import {
  Video,
  VideoCaptions,
  VideoControls,
  useVideoPlayerClasses,
  useVideoPlayer,
  VideoPlayerMarkup,
} from '@dx-ui/osc-video-player';

export type FullWidthMediaProps = {
  image: {
    altText: string;
    mobileImageUrl: string;
    desktopImageUrl: string;
  };
  captionData?: CaptionProps;
  brandComponentTheme?: CmsBrandComponentTheme;
  imageWrapperClass?: string;
  children: React.ReactNode;
  alignment: 'left' | 'center' | 'right';
  metrics?: Partial<FullWidthMediaMetrics>;
  media?: React.ComponentProps<typeof VideoPlayer>;
};

export type FullWidthMediaContentProps = Pick<FullWidthMediaProps, 'alignment'> & {
  longDescription: string;
  headline: string;
  brandComponentTheme?: CmsBrandComponentTheme;
  link?: {
    label: string;
    url: string;
    adaDescription?: string;
    isNewWindow?: boolean;
    experimentationConfiguration?: CmsExperimentationConfiguration;
  };
};

export function FullWidthMediaContentBlock({
  alignment = 'center',
  longDescription,
  headline,
  brandComponentTheme,
  link,
}: FullWidthMediaContentProps) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isCentered = alignment === 'center';

  return (
    <div
      className={cx('flex w-full items-center lg:min-h-full', {
        'justify-end': alignment === 'right',
      })}
    >
      <div
        className={cx('relative w-full max-w-screen-lg lg:w-1/2 xl:w-[45%]', {
          'lg:start-1/2 lg:-translate-x-1/2 rtl:lg:translate-x-1/2': isCentered,
        })}
      >
        <div
          className={cx('px-16 py-8', {
            'bg-bg': !isDark && !isLight,
            'bg-bg-dark': isDark,
            'bg-bg-light': isLight,
          })}
        >
          {headline ? (
            <BrandTextHeader
              className={cx('text-center lg:mb-4 ', {
                'text-center': isCentered,
                'lg:text-start': !isCentered,
                '!text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {headline}
            </BrandTextHeader>
          ) : null}
          {longDescription ? (
            <BrandTextBody
              brandComponentTheme={brandComponentTheme}
              className={cx('text-center ', {
                'text-center': !isCentered,
                'lg:text-start': !isCentered,
                'text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {longDescription}
            </BrandTextBody>
          ) : null}
          {link?.label && link?.url ? (
            <div
              className={cx('mt-4 flex w-full justify-center', {
                'lg:justify-center': isCentered,
                'lg:justify-normal': !isCentered,
              })}
            >
              <BrandLink
                url={link.url}
                label={link.label}
                isNewWindow={link.isNewWindow}
                showNewWindowIcon={link.isNewWindow}
                brandComponentTheme={brandComponentTheme}
                data-conductrics-goal={link.experimentationConfiguration?.goal}
                data-conductrics-value={link.experimentationConfiguration?.value}
                metricsOnClick={() =>
                  trackEvent(
                    ANALYTICS_GLOBAL_CLICK_EVENT,
                    generateFullWidthMediaMetrics({
                      headline,
                      itemTitle: link.label,
                    })
                  )
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function FullWidthMedia({
  image,
  captionData,
  children,
  imageWrapperClass,
  alignment,
  metrics,
  media,
  brandComponentTheme,
}: FullWidthMediaProps) {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const playerClassNames = useVideoPlayerClasses({});
  const isCinemagraph = media ? !!media.altText : false;
  const hasAudio = !media?.altText && !media?.isMuted;
  const videoUrl = media?.videoUrl || '';
  const playerProps = useVideoPlayer({
    ...media,
    hasAudio,
    videoUrl,
    wrapperElement: ref,
  });
  const controlsAlignment =
    alignment === 'left'
      ? 'right'
      : alignment === 'right'
      ? 'left'
      : alignment === 'center'
      ? 'center'
      : 'default';

  return (
    <div className="relative md:aspect-[16/9]">
      {media && playerProps ? (
        <>
          {media?.markupSchemas ? <VideoPlayerMarkup markupSchemas={media?.markupSchemas} /> : null}
          <div
            className={cx(
              'relative mb-4 aspect-square size-full md:aspect-[16/9] lg:absolute lg:mb-0',
              imageWrapperClass
            )}
          >
            <Video
              {...playerProps.videoProps}
              captionData={media?.captionData}
              isAutoPlay={media?.isAutoPlay}
              posterImageUrl={media?.posterImageUrl}
              videoId={media?.videoId}
              videoUrl={media?.videoUrl}
              role={isCinemagraph ? 'img' : undefined}
              aria-label={isCinemagraph ? media?.altText : undefined}
              className="h-full"
              figureClassName="h-full"
              videoClassName="h-full object-cover"
            />
            <VideoCaptions
              {...playerProps.videoCaptionProps}
              brandComponentTheme={brandComponentTheme}
              className={playerClassNames.captionClassNames}
            />
            <VideoControls
              videoVariant={isCinemagraph ? 'cinemagraph' : undefined}
              {...playerProps.videoControlsProps}
              brandComponentTheme={brandComponentTheme}
              className={cx(playerClassNames.controlsClassNames[controlsAlignment])}
            />
          </div>
        </>
      ) : null}
      {!media && (
        <div
          className={cx(
            'relative -z-10 aspect-square md:aspect-[16/9] md:w-full lg:absolute lg:aspect-auto lg:size-full',
            imageWrapperClass
          )}
        >
          <Image
            className="hidden object-cover lg:block"
            unoptimized={true}
            src={image.desktopImageUrl}
            alt={image?.altText || ''}
            fill
          />
          <Image
            className="block object-cover lg:hidden"
            unoptimized={true}
            src={image.mobileImageUrl}
            alt={image?.altText || ''}
            fill
          />
        </div>
      )}
      {children}
      {!media && captionData ? (
        <Caption
          {...captionData}
          align={alignment === 'right' ? 'start' : 'end'}
          metricsOnClick={() =>
            trackEvent(
              ANALYTICS_GLOBAL_CLICK_EVENT,
              generateFullWidthMediaMetrics({
                metrics,
                itemTitle: captionData?.caption,
              })
            )
          }
        />
      ) : null}
    </div>
  );
}

export default FullWidthMedia;
