import { useFormContext } from 'react-hook-form';
import { ActionDialog } from '@dx-ui/osc-dialog-v2';
import { FormInput } from '@dx-ui/osc-form';
import { Link } from '@dx-ui/osc-link';
import { Trans, useTranslation } from 'next-i18next';

import type { Dispatch, SetStateAction } from 'react';
import type { Brand_CountriesQuery } from './queries/generated/types';

type Props = {
  isUSA: boolean;
  isChina: boolean;
  selectedCountry: Brand_CountriesQuery['countries'][0];
  isMarketingOffersModalOpen: boolean;
  setIsMarketingOffersModalOpen: Dispatch<SetStateAction<boolean>>;
  language: string;
  origin: string;
};

export const MarketingContent = ({
  isUSA,
  isChina,
  selectedCountry,
  isMarketingOffersModalOpen,
  setIsMarketingOffersModalOpen,
  language,
  origin,
}: Props) => {
  const [t] = useTranslation('osc-join-form-join');
  const form = useFormContext();

  if (!isUSA) {
    if (selectedCountry?.marketingOptIn !== 'greenMarketingOptIn') {
      return (
        <>
          <FormInput
            name="marketingConsent"
            label={t('marketing.label')}
            type="checkbox"
            labelClassName="w-full"
            optional={true}
            aria-describedby="marketing"
          />
          <p id="marketing" className="!mt-0 ml-8 text-sm">
            <Trans t={t} i18nKey={isChina ? 'marketing_china.agreement' : 'marketing.agreement'}>
              <Link url={`${origin}/${language}/p/global-privacy-statement/`} isNewWindow />
            </Trans>
          </p>
          {/* Hide Offers checkbox - China only */}
          {!isChina ? (
            <>
              <FormInput
                name="marketingOffers"
                label={t('marketing_offers.label')}
                type="checkbox"
                labelClassName="w-full"
                optional={true}
                aria-describedby="marketing_offers"
              />
              <p id="marketing_offers" className="!mt-0 ml-8 text-sm">
                <Trans t={t} i18nKey="marketing_offers.agreement">
                  <Link url={`${origin}/${language}/p/global-privacy-statement/`} isNewWindow />
                </Trans>
              </p>
              <FormInput
                name="marketingGrandVacations"
                label={t('marketing_grandVacations.label')}
                type="checkbox"
                labelClassName="w-full"
                optional={true}
                aria-describedby="marketing_grandVacations"
              />
              <p id="marketing_grandVacations" className="!mt-0 ml-8 text-sm">
                <Trans t={t} i18nKey="marketing_grandVacations.agreement">
                  <Link
                    url="https://www.hiltongrandvacations.com/en/privacy-policy.html"
                    isNewWindow
                  />
                </Trans>
              </p>
              <ActionDialog
                buttonOptions={{
                  cancel: {
                    label: t('dialog.decline'),
                  },
                  confirm: {
                    label: t('dialog.agree'),
                  },
                }}
                onConfirm={() => setIsMarketingOffersModalOpen(false)}
                onCancel={() => {
                  setIsMarketingOffersModalOpen(false);
                  form.setValue('marketingOffers', false);
                }}
                title={t('dialog.title')}
                isOpen={isMarketingOffersModalOpen}
                onDismiss={() => {
                  setIsMarketingOffersModalOpen(!isMarketingOffersModalOpen);
                  form.setValue('marketingOffers', false);
                }}
                size="lg"
              >
                <p>{t('dialog.body')}</p>
                <p className="my-4 text-sm">{t('dialog.disclaimer')}</p>
              </ActionDialog>
            </>
          ) : null}
          <p>{t(isChina ? 'consent_china' : 'consent')}</p>
          <p>
            <Trans t={t} i18nKey={isChina ? 'agreement_china' : 'agreement'}>
              <Link
                url={`/${language}/hilton-honors/terms/`}
                className="text-base text-primary hover:text-primary-alt"
                isNewWindow
              />
              <Link
                url={`${origin}/${language}/p/global-privacy-statement/`}
                className="text-base text-primary hover:text-primary-alt"
                isNewWindow
              />
            </Trans>
          </p>
        </>
      );
    }
  }

  return (
    <>
      {selectedCountry?.marketingOptInForHGV && (
        <>
          <FormInput
            name="marketingGrandVacations"
            label={t('marketing_grandVacations.label')}
            type="checkbox"
            labelClassName="w-full"
            optional={true}
            aria-describedby="marketing_grandVacations"
          />
          <p id="marketing_grandVacations" className="!mt-0 ml-8 text-sm">
            <Trans t={t} i18nKey="marketing_grandVacations.agreement">
              <Link url="https://www.hiltongrandvacations.com/en/privacy-policy.html" isNewWindow />
            </Trans>
          </p>
          <p>{t('consent')}</p>
        </>
      )}
      <p>
        <Trans t={t} i18nKey={isUSA ? 'agreement_US' : 'agreement'}>
          <Link
            url={`/${language}/hilton-honors/terms/`}
            className="text-base text-primary hover:text-primary-alt"
            isNewWindow
          />
          <Link
            url={`${origin}/${language}/p/global-privacy-statement/`}
            className="text-base text-primary hover:text-primary-alt"
            isNewWindow
          />
          <Link
            url={`${origin}/${language}/p/global-privacy-statement/#CaliforniaRights`}
            className="text-base text-primary hover:text-primary-alt"
            isNewWindow
          />
        </Trans>
      </p>
    </>
  );
};
