import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import type { ReactNode } from 'react';

type ErrorBoundaryProps = {
  /**
   * callback that gets executed on Error
   */
  onError?: (error: Error) => void;
  children?: ReactNode | undefined;
};

/** Error boundary component to catch client-side errors, display fallback page/component and call onError callback. */
export const ErrorBoundary = (
  props: React.ComponentProps<typeof ReactErrorBoundary> & ErrorBoundaryProps
) => {
  const { children, onError, ...rest } = props;

  const logError = (error: Error) => {
    onError?.(error);
  };

  return (
    <ReactErrorBoundary {...rest} onError={logError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
