import { useRef, useEffect } from 'react';
import { useInitConductrics } from '@dx-ui/framework-conductrics';
import { useAuth } from '@dx-ui/framework-auth-provider';
import Cookies from 'universal-cookie';

export const useInitializeConductrics = (traitsList: string[] = []) => {
  const isConductricsInit = useRef(false);
  const { guestInfo, isLoading, isAuthenticated } = useAuth();
  const initConductrics = useInitConductrics();

  useEffect(() => {
    if (!isLoading && !isConductricsInit.current) {
      isConductricsInit.current = true;

      const tms = new Cookies()
        .get('TMS')
        ?.split(',')
        .filter((entry: string) => (entry.toLowerCase() || '').startsWith('web'))
        .map((entry: string) => entry.split('=')[1] || '');

      let view = 'app-only-view';

      const isNotHonorsMember = !!tms?.includes('15300019');
      const isHonorsMember = !!tms?.includes('15217574');

      if (isNotHonorsMember) {
        view = 'join-view';
      }

      if (isHonorsMember) {
        view = 'sign-in-view';
      }

      const traits = [
        ...traitsList,
        `login:${isAuthenticated ? 'yes' : 'no'}`,
        `component-view:${view}`,
      ];
      const { hhonors } = guestInfo || {};

      if (hhonors?.summary?.tier) {
        traits.push(`tier:${hhonors.summary.tier.toLowerCase()}`);
      }

      const teamMember =
        hhonors?.isOwner ||
        hhonors?.isTeamMember ||
        hhonors?.isOwnerHGV ||
        hhonors?.isLongTenure10 ||
        hhonors?.isLongTenure20;

      traits.push(`teamMember:${teamMember ? 'yes' : 'no'}`);
      initConductrics({ traits });
    }
  }, [isAuthenticated, isLoading, guestInfo, initConductrics, traitsList]);
};
