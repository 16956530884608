import type {
  CmsDomLink,
  OscDomLink,
  AddCampaignCodeToUrl,
  NavigationListData,
  BrandNavigationListData,
  CpmBrandNavigationItem,
  CpmBrandNavigationList,
  CpmNavigationList,
} from '../adapters/types';

/** Convert an <a href/> definition object from Cms structure to Osc structure */
export function transformLink(
  link: CmsDomLink | null | undefined,
  addCampaignCodeToUrl: AddCampaignCodeToUrl
): OscDomLink | null {
  if (!link) {
    return null;
  }

  const translatedLink: OscDomLink = {
    _id: '',
    adaDescription: link.adaDescription ?? '',
    isNewWindow: Boolean(link.isNewWindow),
    label: link.linkLabel,
    url: addCampaignCodeToUrl(link.linkUrl, link.campaignId),
    campaignId: link.campaignId ?? undefined,
  };

  if (link.experimentationConfiguration) {
    translatedLink.experimentationConfiguration = link.experimentationConfiguration;
  }

  return translatedLink;
}

export function translateNavigationListLinks(
  navigationItems: NavigationListData['items'] | BrandNavigationListData['items'],
  addCampaignCodeToUrl: AddCampaignCodeToUrl
) {
  if (!!navigationItems && isBrandNavigationDocumentItems(navigationItems)) {
    return {
      items: navigationItems
        .map((navItem) => {
          const secondaryItems = navItem.secondaryNavItems?.[0]?.links
            ?.map((link) => transformLink(link, addCampaignCodeToUrl))
            .filter((link): link is OscDomLink => link !== null);

          return {
            primaryNav: transformLink(navItem.primaryNav, addCampaignCodeToUrl),
            secondaryNavItems: secondaryItems || [],
          };
        })
        .filter((item): item is CpmBrandNavigationItem => item.primaryNav !== null),
    } as CpmBrandNavigationList;
  }

  if (!!navigationItems && isNavigationListDocumentItems(navigationItems)) {
    return {
      items: navigationItems
        .filter((linkGroup): linkGroup is { links: CmsDomLink[] } =>
          Array.isArray(linkGroup?.links)
        )
        .map((linkGroup) => ({
          links: linkGroup.links
            .map((link) => transformLink(link, addCampaignCodeToUrl))
            .filter((link): link is OscDomLink => link !== null),
        })),
    } as CpmNavigationList;
  }

  return {
    items: null,
  };
}

function isNavigationListDocumentItems(items: unknown): items is NavigationListData['items'] {
  return Array.isArray(items) && items.length > 0 && 'links' in items[0];
}

function isBrandNavigationDocumentItems(items: unknown): items is BrandNavigationListData['items'] {
  return Array.isArray(items) && items.length > 0 && 'primaryNav' in items[0];
}
