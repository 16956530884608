import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { CaptionProps } from '@dx-ui/osc-caption';
import ImageControlButton from './image-control-button';
import { Image } from './utils';
import { Caption } from '@dx-ui/osc-caption';
import type { ImageClickIDType } from '@dx-ui/config-metrics';
import {
  ANALYTICS_GLOBAL_CLICK_EVENT,
  ImageClickID,
  ImageFunction,
  trackEvent,
} from '@dx-ui/config-metrics';
import { generateFilmstripMetrics } from './utils/filmstrip-analytics';

export type FilmstripImageProps = {
  id?: string;
  altText: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  captionData?: CaptionProps;
  headline?: string;
  linkHref?: string;
  linkText?: string;
  text?: string;
};

export type FilmstripProps = {
  /** An array of FilmstripImageProps **/
  headline?: string;
  images: FilmstripImageProps[];
  /** The desired width of the Images within the Filmstrip. **/
  imageWidth?: number;
};

const getDisplayIndex = (activeIndex: number, imagesLength: number) => {
  if ((activeIndex + 1) % imagesLength !== 0) return (activeIndex + 1) % imagesLength;
  else return imagesLength;
};

const getPreviousPreviousIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex - 2 === -1) return imagesLength - 1;
  if (activeIndex - 2 === -2) return imagesLength - 2;
  else return activeIndex - 2;
};

const getPreviousIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex - 1 < 0) return imagesLength - 1;
  else return activeIndex - 1;
};
const getNextIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex + 1 > imagesLength - 1) return 0;
  else return activeIndex + 1;
};
const getNextNextIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex + 2 === imagesLength) return 0;
  if (activeIndex + 2 === imagesLength + 1) return 1;
  else return activeIndex + 2;
};

const getOffsets = (imageWidth: number) => {
  const doubleOffset = imageWidth * 2 + 16 * 2;

  return {
    previousPreviousOffset: `-${doubleOffset}px 0px`,
    previousOffset: `-${imageWidth + 16}px 0px`,
    nextOffset: `${imageWidth + 16}px -432px`,
    nextNextOffset: `${doubleOffset}px -432px`,
  };
};

export const Filmstrip = ({ headline, images, imageWidth = 768 }: FilmstripProps) => {
  const [t] = useTranslation('osc-filmstrip');
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const itemText = t('itemLabel') || 'slide';
  const imageCount = (Array.isArray(images) && images.length) || 0;
  const nextText = t('next', {
    itemLabel: itemText,
    count: images.length,
    activeIndexLabel: getDisplayIndex(activeIndex, imageCount),
  });
  const previousText = t('previous', {
    itemLabel: itemText,
    count: imageCount,
    activeIndexLabel: getDisplayIndex(activeIndex, imageCount),
  });

  const selectors =
    'cursor-pointer absolute flex justify-center items-center bg-bg-inverse opacity-80';
  const imgBaseAspectRatio = '16:9';
  const imgBaseClassName = 'object-contain aspect-[16/9] image-corner-radius';

  const trackFilmstripMetrics = (
    index: number,
    images: FilmstripImageProps[],
    clickID: ImageClickIDType,
    imageFunction?: (typeof ImageFunction)['Link']
  ) =>
    trackEvent(ANALYTICS_GLOBAL_CLICK_EVENT, {
      ...generateFilmstripMetrics({
        position: index + 1,
        count: images.length,
        clickID,
        headline,
        itemTitle: images[index]?.altText || undefined,
        imageFunction,
      }),
    });

  const scroll = (direction: string) => {
    switch (direction) {
      case 'next':
        if (activeIndex === imageCount - 1) {
          setActiveIndex(0);
        } else {
          setActiveIndex(activeIndex + 1);
        }
        trackFilmstripMetrics(activeIndex, images, ImageClickID.Scroll);
        break;
      case 'previous':
        if (activeIndex === 0) {
          setActiveIndex(imageCount - 1);
        } else {
          setActiveIndex(activeIndex - 1);
        }
        trackFilmstripMetrics(activeIndex, images, ImageClickID.Scroll);
        break;
      default:
        break;
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setTouchStart(e.targetTouches[0]?.clientX ?? 0);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0]?.clientX ?? 0);
  };
  const handleTouchEnd = () => {
    // swipe sensitivity can be adjusted - travel distance in [number] pixels of user swipe event
    const SWIPE_SENSITIVITY = 50;
    // swipe left
    if (touchStart - touchEnd > SWIPE_SENSITIVITY && touchEnd > 0) scroll('next');
    // swipe right
    if (touchStart - touchEnd < -SWIPE_SENSITIVITY && touchEnd > 0) scroll('previous');

    setTouchStart(0);
    setTouchEnd(0);
  };

  if (images && images.length) {
    return (
      <div
        ref={ref}
        className="relative overflow-hidden"
        data-testid="filmstrip-wrapper"
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        <div className="my-4 flex justify-center overflow-hidden">
          <ImageControlButton
            icon="previous"
            className={`${selectors} left-0 top-1/2 z-20 h-12 w-8 text-2xl`}
            labelText={previousText}
            navigationText={t('previousNavText')}
            onClick={() => scroll('previous')}
          />
          <div className="z-10">
            {(rect?.width ?? 0) >= 2364 ? (
              <div
                className="absolute"
                style={{
                  width: imageWidth,
                  translate: getOffsets(imageWidth).previousPreviousOffset,
                }}
                role="presentation"
              >
                {images.map((image, index) => (
                  <div
                    key={image.altText}
                    className={cx('relative snap-center rounded', {
                      hidden: index !== getPreviousPreviousIndex(activeIndex, images.length),
                    })}
                  >
                    <Image
                      alt={image.altText}
                      src={image.desktopImageUrl}
                      width={imageWidth}
                      aspectRatio={imgBaseAspectRatio}
                      className={imgBaseClassName}
                    />
                  </div>
                ))}
              </div>
            ) : null}

            <div
              className="absolute"
              style={{
                width: imageWidth,
                translate: getOffsets(imageWidth).previousOffset,
              }}
              role="presentation"
            >
              {images.map((image, index) => (
                <div
                  key={image.altText}
                  className={cx('relative snap-center rounded', {
                    hidden: index !== getPreviousIndex(activeIndex, images.length),
                  })}
                >
                  <Image
                    alt={image.altText}
                    src={image.desktopImageUrl}
                    width={imageWidth}
                    aspectRatio={imgBaseAspectRatio}
                    className={imgBaseClassName}
                  />
                </div>
              ))}
            </div>
            <div>
              {images.map((image, index) => (
                <div
                  key={image.altText}
                  className={cx('relative snap-center rounded', {
                    hidden: index !== activeIndex,
                  })}
                >
                  <Image
                    alt={image.altText}
                    src={image.desktopImageUrl}
                    width={imageWidth}
                    aspectRatio={imgBaseAspectRatio}
                    className={imgBaseClassName}
                  />
                  {image.captionData && (
                    <Caption
                      caption={image?.captionData?.caption}
                      captionLink={image?.captionData?.captionLink}
                      metricsOnClick={() =>
                        trackFilmstripMetrics(index, images, ImageClickID.Img, ImageFunction.Link)
                      }
                    />
                  )}
                </div>
              ))}
            </div>
            <div
              className="absolute"
              style={{
                width: imageWidth,
                translate: getOffsets(imageWidth).nextOffset,
              }}
              role="presentation"
            >
              {images.map((image, index) => (
                <div
                  key={image.altText}
                  className={cx('relative snap-center rounded', {
                    hidden: index !== getNextIndex(activeIndex, images.length),
                  })}
                >
                  <Image
                    alt={image.altText}
                    src={image.desktopImageUrl}
                    width={imageWidth}
                    aspectRatio={imgBaseAspectRatio}
                    className={imgBaseClassName}
                  />
                </div>
              ))}
            </div>
            {(rect?.width ?? 0) >= 2364 ? (
              <div
                className="absolute"
                style={{
                  width: imageWidth,
                  translate: getOffsets(imageWidth).nextNextOffset,
                }}
                role="presentation"
              >
                {images.map((image, index) => (
                  <div
                    key={image.altText}
                    className={cx('relative snap-center rounded', {
                      hidden: index !== getNextNextIndex(activeIndex, images.length),
                    })}
                  >
                    <Image
                      alt={image.altText}
                      src={image.desktopImageUrl}
                      width={imageWidth}
                      aspectRatio={imgBaseAspectRatio}
                      className={imgBaseClassName}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <ImageControlButton
            icon="next"
            className={`${selectors} right-0 top-1/2 z-20 h-12 w-8 text-2xl`}
            labelText={nextText}
            navigationText={t('nextNavText')}
            onClick={() => scroll('next')}
          />
          <div
            className={`${selectors} bottom-4 right-0 z-20 h-8 w-12 px-8 py-4 text-sm text-bg-alt`}
          >
            {getDisplayIndex(activeIndex, images.length)}/{images.length}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Filmstrip;
