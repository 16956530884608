import { Filmstrip } from '@dx-ui/osc-filmstrip';
import type { FilmstripProps } from '@dx-ui/osc-filmstrip';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { BrandLinkProps } from '@dx-ui/osc-brand-buttons';
import { HeadingLevel } from '@dx-ui/osc-heading-level';
import cx from 'classnames';

export interface BrandFilmstripProps extends FilmstripProps {
  headline?: string;
  description?: string;
  link?: BrandLinkProps & { experimentationConfiguration?: CmsExperimentationConfiguration };
  brandComponentTheme?: 'light' | 'dark';
  textAlign: CmsAlignContent | undefined;
}

export const BrandFilmstrip = ({
  headline,
  description,
  link,
  images,
  brandComponentTheme,
  textAlign = 'center',
}: BrandFilmstripProps) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div className="py-8 brand-wa:py-16 xl:py-12 brand-wa:xl:py-20">
      <div
        className={cx('container', {
          'text-start': textAlign === 'left',
          'text-center': textAlign === 'center',
          'text-end': textAlign === 'right',
        })}
        data-testid="filmstrip"
      >
        {headline ? (
          <HeadingLevel
            headingLevelFallback={2}
            className={cx('heading-2xl pb-2 xl:heading-4xl xl:pb-4', {
              '!text-text-inverse': isDark,
              '!filmstrip-headline-text-light': isLight,
            })}
          >
            {headline}
          </HeadingLevel>
        ) : null}
        {description ? (
          <p
            className={cx('pb-4 text-base font-normal text-text md:text-lg xl:pb-8 xl:text-xl', {
              'text-text-inverse': isDark,
              '!filmstrip-description-text-light': isLight,
            })}
          >
            {description}
          </p>
        ) : null}
      </div>
      <Filmstrip images={images} headline={headline} />
      <div className={cx('container overflow-hidden px-4 text-center sm:px-10')}>
        {link ? (
          <BrandLink
            url={link.url}
            label={link.label}
            isNewWindow={link.isNewWindow}
            showNewWindowIcon={link.isNewWindow}
            brandComponentTheme={brandComponentTheme}
            data-conductrics-goal={link.experimentationConfiguration?.goal}
            data-conductrics-value={link.experimentationConfiguration?.value}
            variant="solid"
          />
        ) : null}
      </div>
    </div>
  );
};
