import type * as React from 'react';

import type { CpmServerBundle, KeyedMappingDefinition } from './types';

import { makeFetchServerCpmPage } from './fetchServerCpmPage';
import type { CpmRendererProps } from './CpmRenderer';
import { makeCpmRenderer } from './CpmRenderer';
import type { QueryClient } from '@tanstack/react-query';
import type { getTaggingValuesFromCpmAnalytics } from '../utils/add-campaign-id-to-url';
import { serverSideCmsPageModelQuery } from '../generated/queries';

type FetchServerCpmPage<Definitions extends KeyedMappingDefinition> = (
  queryClient: QueryClient,
  contentPath: string,
  localeCode: string,
  pathname: string,
  sectionNames: string[],
  extractCampaignCodeTaggingValues?: typeof getTaggingValuesFromCpmAnalytics
) => Promise<CpmServerBundle<Definitions> | null>;

export function generateCpmAppInterface<Definitions extends KeyedMappingDefinition>(
  definitions: Partial<Definitions>
): {
  fetchServerCpmPage: FetchServerCpmPage<Definitions>;
  CpmRenderer: React.FC<CpmRendererProps<Definitions>>;
} {
  const CpmRenderer = makeCpmRenderer<Definitions>(definitions);
  const fetchServerCpmPage = makeFetchServerCpmPage(
    definitions as KeyedMappingDefinition,
    serverSideCmsPageModelQuery
  );

  return {
    fetchServerCpmPage,
    CpmRenderer,
  };
}
