import { env } from '@dx-ui/framework-env';

export const getBaseUrl = (slug?: string) =>
  slug ? new URL(slug, env('OHW_BASE_URL'))?.href : new URL(env('OHW_BASE_URL')).origin;

export const getAssetPath = () => `${getBaseUrl()}/modules/assets`;

const getSvgPath = () => `${getAssetPath()}/svgs`;

export const getSvgAssetUrls = () => ({
  home: `${getSvgPath()}/guest/home.svg`,
  phone: `${getSvgPath()}/common/phone.svg`,
  search: `${getSvgPath()}/common/search.svg`,
  account: `${getSvgPath()}/guest/account.svg`,
});

export const getBrandSvg = (brandCode: string) => `${getSvgPath()}/logos/${brandCode}.svg`;
