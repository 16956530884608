import { LANGUAGES } from './constants';

const brandsWithBrandPage = [
  'PY',
  'CH',
  'QQ',
  'DT',
  'ES',
  'HP',
  'GI',
  'HI',
  'HT',
  'HW',
  'OL',
  'SA',
  'UA',
  'UP',
  'RU',
  'PO',
  'WA',
  'PE',
];

export const isBrandHomePageUrlFormat = (pathname: string, brandCode: string) => {
  const pathParts = pathname.split('/').filter((n) => !!n);

  if (pathParts.length > 3) {
    return false;
  }

  const languages: string[] = Object.values(LANGUAGES);

  const lang = pathParts[0];

  if (!lang) {
    return false;
  }

  if (!languages.includes(lang)) {
    return false;
  }

  if (pathParts[1] !== 'brands') {
    return false;
  }

  if (!brandsWithBrandPage.includes(brandCode)) {
    return false;
  }

  return true;
};
