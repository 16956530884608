import BrandAlerts from './mapping/BrandAlerts';
import BrandCarousel from './mapping/BrandCarousel';
import BrandShowcase from './mapping/BrandShowcase';
import BrandVideo from './mapping/BrandVideo';
import Collage from './mapping/Collage';
import Editorial from './mapping/Editorial';
import Experimentation from './mapping/Experimentation';
import FourXGrid from './mapping/FourXGrid';
import FullWidthImage from './mapping/FullWidthImage';
import Gallery from './mapping/Gallery';
import Grid369 from './mapping/Grid369';
import HalfAndHalf from './mapping/HalfAndHalf';
import ImageHeadliner from './mapping/ImageHeadliner';
import MastheadImage from './mapping/MastheadImage';
import TabbedSection from './mapping/TabbedSection';
import TextBlock from './mapping/TextBlock';
import TextHighlight from './mapping/TextHighlight';
import VideoPoster from './mapping/VideoPoster';
import PatchworkGrid from './mapping/PatchworkGrid';
import Filmstrip from './mapping/Filmstrip';
import FullWidthMedia from './mapping/FullWidthMediaAndCopy';
import FullWidthMediaAndCopyOverlay from './mapping/FullWidthMediaAndCopyOverlay';
import Offset from './mapping/Offset';
import Accordion from './mapping/Accordion';
import IllustrationBlock from './mapping/IllustrationBlock';
import DynamicGrid from './mapping/DynamicGrid';

export type { AnalyticsTagging } from './types/analytics-tagging';
export * from './types/gql';

export * from './Context';

export { publicEnvVars, namespaces } from './utils/constants';
export { deduplicate } from './utils/deduplicate';

export { usePreviewSegmentsFromUrl } from './hooks/use-preview-segments-from-url';

export { Goals } from './utils/conductrics';

export {
  BrandAlerts,
  BrandCarousel,
  BrandShowcase,
  BrandVideo,
  Collage,
  Editorial,
  Experimentation,
  FourXGrid,
  FullWidthImage,
  Gallery,
  Grid369,
  HalfAndHalf,
  ImageHeadliner,
  MastheadImage,
  TabbedSection,
  TextBlock,
  TextHighlight,
  VideoPoster,
  PatchworkGrid,
  Filmstrip,
  Offset,
  Accordion,
  IllustrationBlock,
  FullWidthMedia,
  FullWidthMediaAndCopyOverlay,
  DynamicGrid,
};

export const sharedMappingSpec = {
  [BrandAlerts.cpmComponentName]: BrandAlerts,
  [BrandCarousel.cpmComponentName]: BrandCarousel,
  [BrandShowcase.cpmComponentName]: BrandShowcase,
  [BrandVideo.cpmComponentName]: BrandVideo,
  [Collage.cpmComponentName]: Collage,
  [Editorial.cpmComponentName]: Editorial,
  [Experimentation.cpmComponentName]: Experimentation,
  [FourXGrid.cpmComponentName]: FourXGrid,
  [FullWidthImage.cpmComponentName]: FullWidthImage,
  [Gallery.cpmComponentName]: Gallery,
  [Grid369.cpmComponentName]: Grid369,
  [HalfAndHalf.cpmComponentName]: HalfAndHalf,
  [ImageHeadliner.cpmComponentName]: ImageHeadliner,
  [MastheadImage.cpmComponentName]: MastheadImage,
  [TabbedSection.cpmComponentName]: TabbedSection,
  [TextBlock.cpmComponentName]: TextBlock,
  [TextHighlight.cpmComponentName]: TextHighlight,
  [VideoPoster.cpmComponentName]: VideoPoster,
  [PatchworkGrid.cpmComponentName]: PatchworkGrid,
  [Filmstrip.cpmComponentName]: Filmstrip,
  [Offset.cpmComponentName]: Offset,
  [Accordion.cpmComponentName]: Accordion,
  [IllustrationBlock.cpmComponentName]: IllustrationBlock,
  [FullWidthMedia.cpmComponentName]: FullWidthMedia,
  [FullWidthMediaAndCopyOverlay.cpmComponentName]: FullWidthMediaAndCopyOverlay,
  [DynamicGrid.cpmComponentName]: DynamicGrid,
};
