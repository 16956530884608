import type { ReactNode } from 'react';
import cx from 'classnames';
import { HalfAndHalfContent } from './half-and-half-content';
import type { HalfAndHalfProps } from './half-and-half';
import { Cycle, CycleContext } from '@dx-ui/osc-cycle';

export type HalfAndHalfCarouselItem = Pick<
  HalfAndHalfProps,
  | 'copy'
  | 'link'
  | 'headline'
  | 'description'
  | 'imageUrl'
  | 'captionData'
  | 'imageAltText'
  | 'media'
>;

export type HalfAndHalfCarouselProps = {
  /**
   * sets position of image left or right
   */
  index?: number;
  /**
   * should the half and half be flipped?
   */
  isReverse?: boolean;
  /**
   * whether the background color should be altered and if they should be on `even` or `odd` components.
   */
  showBackgroundOn?: 'even' | 'odd';
  /**
   * Unique ID
   */
  id?: string;
  /**
   * CPM controlled brand color themes
   */
  brandComponentTheme?: CmsBrandComponentTheme;
  /**
   * renders the component with a parallax animation.
   */
  hasParallax?: boolean;
  /**
   * the speed of Parallax
   */
  speed?: number;
  /**
   * CMS document editor button
   */
  cmsDocumentControl?: ReactNode;
  /**
   * Translation CSS classes to add to each item
   */
  cmsTranslationClasses?: string;
  /**
   * Carousel Items
   */
  carouselItems: HalfAndHalfCarouselItem[];
  /**
   * Full Screen / Full Bleed mode
   */
  isFullScreen?: boolean;
  /**
   * Ratio of Copy / Media section
   */
  mediaRatio?: '50-50' | '60-40' | '70-30';
};

export const HalfAndHalfCarousel = ({
  index = 0,
  isReverse = false,
  brandComponentTheme,
  id,
  cmsTranslationClasses,
  carouselItems,
  hasParallax,
  isFullScreen,
  mediaRatio,
}: HalfAndHalfCarouselProps) => {
  const currElemIndxType = !Number.isNaN(index) && (index % 2 === 0 ? 'even' : 'odd');
  const isPositionOdd = isReverse ? currElemIndxType === 'even' : currElemIndxType === 'odd';
  const isLight = brandComponentTheme === 'light';
  const isDark = brandComponentTheme === 'dark';

  const containerBgClass = {
    'bg-bg-light': isLight,
    'bg-bg-dark': isDark,
    'bg-transparent': !isLight && !isDark,
    'brand-po:bg-bg-alt': !isPositionOdd && !isLight && !isDark,
  };

  const count = carouselItems.length;
  if (!count) {
    return null;
  }

  return (
    <div
      id={id}
      className={cx('w-full', containerBgClass, cmsTranslationClasses)}
      data-testid="halfnhalf-carousel-sections-wrapper"
    >
      <Cycle total={count} current={0}>
        <CycleContext.Consumer>
          {({ active, cycleId }) => (
            <div id={cycleId}>
              <HalfAndHalfContent
                isFullCarousel={true}
                index={active}
                activeIndex={active}
                isPositionOdd={isPositionOdd}
                hasParallax={hasParallax}
                imageUrl={carouselItems[active]?.imageUrl}
                captionData={carouselItems[active]?.captionData}
                headline={carouselItems[active]?.headline}
                description={carouselItems[active]?.description}
                copy={carouselItems[active]?.copy}
                imageAltText={carouselItems[active]?.imageAltText}
                link={carouselItems[active]?.link}
                media={carouselItems[active]?.media}
                count={count}
                isFullScreen={isFullScreen}
                mediaRatio={mediaRatio}
                brandComponentTheme={brandComponentTheme}
              />
            </div>
          )}
        </CycleContext.Consumer>
      </Cycle>
    </div>
  );
};
