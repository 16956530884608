import { VideoPlayer } from '@dx-ui/osc-video-player';
import { AttachedContent, type AttachedContentProps } from './AttachedContent';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';

export interface VideoProps extends React.ComponentProps<typeof VideoPlayer> {
  attachedContent?: AttachedContentProps;
  brandComponentTheme?: CmsBrandComponentTheme;
  shouldAnimateReveal: boolean;
  altText?: string;
}

/**
 * Full screen width video component, auto-play, with video controls and optional attached content with text and CTA link/button.
 */
export const VideoPoster = ({
  videoUrl,
  videoGroup,
  videoLabel,
  videoName,
  posterImageUrl,
  orientation = 'landscape',
  attachedContent,
  brandComponentTheme,
  isAutoPlay = true,
  captionData,
  audioTracks,
  captionTracks,
  transcriptTracks,
  markupSchemas,
  shouldAnimateReveal,
  altText,
  isMuted,
}: VideoProps) => {
  const hasAudio = !altText && !isMuted;
  return (
    <>
      <div data-testid="video-poster-wrapper">
        <VideoPlayer
          markupSchemas={markupSchemas}
          videoUrl={videoUrl}
          videoGroup={videoGroup}
          videoLabel={videoLabel}
          videoName={videoName}
          posterImageUrl={posterImageUrl}
          orientation={orientation}
          videoId="video-poster"
          captionData={captionData}
          audioTracks={audioTracks}
          captionTracks={captionTracks}
          transcriptTracks={transcriptTracks}
          isAutoPlay={isAutoPlay}
          brandComponentTheme={brandComponentTheme}
          role={altText ? 'img' : undefined}
          aria-label={altText ? altText : undefined}
          hasAudio={hasAudio}
          videoControlsProps={{
            ...(altText && { videoVariant: 'cinemagraph' }),
          }}
        />
      </div>
      {attachedContent && (
        <AnimateRevealItem delay={0} isAnimated={shouldAnimateReveal} animationType="fade-in-up">
          <AttachedContent
            headline={attachedContent.headline}
            description={attachedContent.description}
            link={attachedContent.link}
            brandComponentTheme={brandComponentTheme}
            alignContent={attachedContent.alignContent}
          />
        </AnimateRevealItem>
      )}
    </>
  );
};

export default VideoPoster;
