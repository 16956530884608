import { effectsList } from '../mappingEngine/parseComponentParams';

export function validateCompomentParamEffects(
  componentParams: Record<string, string | boolean | number | { [key: string]: unknown }>,
  supportedEffects: Record<string, boolean> | undefined,
  componentName: string,
  addIssue: (issue: { id: string; message: string }) => void,
  clearIssue: (id: string) => void
): void {
  const fullSupportedEffects: Record<string, boolean> =
    supportedEffects ?? Object.fromEntries(effectsList.map((effect: string) => [effect, false]));

  for (const effect of effectsList) {
    if (componentParams[effect] === true && fullSupportedEffects[effect] === false) {
      addIssue({
        id: `${componentName}-${effect}`,
        message: `"${componentName}" does not support effect ${effect}`,
      });
    } else {
      clearIssue(`${componentName}-${effect}`);
    }
  }
}
