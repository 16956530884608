import { createCpmComponentDefinition, type StructuredAsset } from '@dx-ui/cpm-sdk';
import { PatchworkGrid, type PatchworkGridType } from '@dx-ui/patchwork-grid';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';
import cx from 'classnames';

type ImageObjectTypes = {
  aspectRatios: {
    [key: string]: {
      url: string;
    };
  };
  altText?: string;
  caption?: string;
  captionLink?: string;
};

const createImageObject = (image: ImageObjectTypes) => {
  return {
    url: image?.aspectRatios['1x1']?.url ?? image?.aspectRatios['3x2']?.url ?? '',
    variants: {
      '2x1': image?.aspectRatios?.['2x1']?.url ?? image?.aspectRatios['16x9']?.url ?? '',
    },
    altText: image?.altText ?? '',
    captionData: image?.caption
      ? {
          caption: image?.caption,
          captionLink: image?.captionLink ?? '',
        }
      : undefined,
  };
};

export default createCpmComponentDefinition(
  'Patchwork Grid',

  function mapComponentData({ data, componentParams }) {
    const images = data.cpmAssets;

    const rows: PatchworkGridType['rows'] = [
      {
        headline: data.headline ?? '',
        description: data.longDescription ?? '',
        link: {
          label: data.link?.label ?? '',
          url: data.link?.url ?? '',
          isNewWindow: data.link?.isNewWindow ?? false,
          adaDescription: data.link?.adaDescription ?? '',
          experimentationConfiguration: data.link?.experimentationConfiguration,
        },
        // The first row only gets a single image
        // min 1 / max 1 image for row 1
        images: images.length > 0 && images[0] ? [createImageObject(images[0])] : [],
      },
    ];

    if (images.length > 1) {
      // If more than one image is supplied in the doc, these fall onto the second row.
      // min 2  / max 3 images for row 2
      const additionalImages = images
        .slice(1, 4)
        .filter((image): image is StructuredAsset => image !== undefined)
        .map(createImageObject);

      rows.push({
        images: additionalImages,
      });
    }

    return {
      id: data.id,
      links: data.links,
      brandComponentTheme: componentParams?.theme,
      rows,
    };
  },

  function PatchworkGridCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const isWA = brandCode === 'WA';

    if (!items.length) {
      return null;
    }

    const patchworks = items.map((data) => {
      data.rows = data.rows.map((row) => {
        // Replace link with one that has been filtered by experimentation agent. Links are filtered by the CPM SDK.
        // The link will be the same one as the mapping function if no experimentation agent(s) have been defined.
        if (row.link) {
          row.link = data.links[0];
        }

        return row;
      });

      return data;
    });

    return (
      <BrandComponentThemeInline
        componentClassName={cx({
          'pt-8 xl:p-12 brand-wa:pt-16 brand-wa:xl:pt-20': componentParams.topPadding === 'default',
          'pb-8 xl:p-12 brand-wa:pb-16 brand-wa:xl:pb-20':
            componentParams.bottomPadding === 'default',
        })}
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        {patchworks.map((data, index) => (
          <AnimateRevealItem
            key={data.id}
            delay={0}
            animationType="fade-in-up"
            isAnimated={brandCode === 'GU'}
          >
            <PatchworkGrid
              key={data.id}
              isReverse={componentParams.display === 'mirror' || Boolean(index % 2)}
              brandComponentTheme={data?.brandComponentTheme}
              rows={data.rows}
              isAnimated={isWA}
            />
          </AnimateRevealItem>
        ))}
      </BrandComponentThemeInline>
    );
  }
);
