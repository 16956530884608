import type * as React from 'react';
import type { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { FallbackProps } from 'react-error-boundary';
import { type IconNames } from '@dx-ui/osc-icon';
import { ErrorPage, PhoneNumberLink, PhoneNumberSection } from '@dx-ui/osc-error-page';

type TNumber = {
  label: string;
  phone: string;
  dataE2e?: string;
};

export type TContactInfo = {
  dataE2e?: string;
  title: string;
  showIcon: boolean;
  numbers: Array<TNumber>;
  phoneText: string;
};

function isContactInfo(x: unknown): x is TContactInfo {
  if (typeof x !== 'object') {
    return false;
  }

  if (!x) {
    return false;
  }

  if ('title' in x && typeof x.title !== 'string') {
    return false;
  }

  if ('showIcon' in x && typeof x.showIcon !== 'boolean') {
    return false;
  }

  if ('numbers' in x && !Array.isArray(x.numbers)) {
    return false;
  }

  if ('phoneText' in x && typeof x.phoneText !== 'string') {
    return false;
  }

  return true;
}

export type THelpfulLinkItem = {
  linkURL: string;
  linkLabel: string;
  icon: (typeof IconNames)[number];
  index: number;
};

export type ErrorProps = {
  statusCode: '404' | '500';
};

const DEFAULT_LANGUAGE = 'en';

const ContactInfo: React.FC<TContactInfo> = ({ title, showIcon, numbers }) => {
  return (
    <PhoneNumberSection title={title} showIcon={showIcon}>
      {numbers?.map((number) => (
        <PhoneNumberLink
          key={number.label + number.phone}
          label={number.label}
          url={`tel:${number.phone}`}
          hint={number.phone}
        />
      ))}
    </PhoneNumberSection>
  );
};

export const Error: NextPage<ErrorProps> = ({ statusCode }: ErrorProps) => {
  const language = useRouter()?.query?.language || DEFAULT_LANGUAGE;

  const { t } = useTranslation('osc-marketing-error');
  const links = {
    accountUrl: `https://www.hilton.com/${language}/hilton-honors/guest/my-account/`,
    brandName: 'Hilton',
    brandUrl: `https://www.hilton.com/${language}/`,
    searchUrl: `https://www.hilton.com/${language}/search/find-hotels/`,
  };

  const contactInfo = t('contactInfo', { returnObjects: true });
  const honors =
    'honors' in contactInfo && isContactInfo(contactInfo.honors) ? contactInfo.honors : null;
  const brand =
    'brand' in contactInfo && isContactInfo(contactInfo.brand) ? contactInfo.brand : null;

  const helpfulLinksRaw = t('helpfulLinks', { ...links, returnObjects: true }) ?? [];
  const helpfulLinks = Array.isArray(helpfulLinksRaw) ? helpfulLinksRaw : [];

  const errorKind = statusCode === '404' ? '404' : 'technical';

  return (
    <ErrorPage error={errorKind} helpfulLinks={helpfulLinks}>
      {brand ? <ContactInfo {...brand} /> : null}
      {honors ? <ContactInfo {...honors} /> : null}
    </ErrorPage>
  );
};

export const ErrorFallback: React.FC<
  Omit<FallbackProps, 'error'> & { error: Error & ErrorProps }
> = ({ error }) => {
  return <Error statusCode={error.statusCode} />;
};
