import cx from 'classnames';
import { DynamicGridWOM, DynamicGrid } from '@dx-ui/osc-dynamic-grids';

import { extractInstanceIndexFromId } from '../../utils/id-transformer';
import {
  createCpmComponentDefinition,
  useCreateManageContentButton,
  selectFirstAssetWithAspectRatio,
  selectLastAssetWithAspectRatio,
} from '@dx-ui/cpm-sdk';
import { HamptonHexVisual } from './HamptonHexVisual';
import { sendReward } from '@dx-ui/framework-conductrics';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';
import { useMetrics } from '../../Context';

export default createCpmComponentDefinition(
  '4X Grid',

  function mapData({ data }) {
    const { link } = data;
    const asset = selectFirstAssetWithAspectRatio('3x4', data.cpmAssets);
    const modalImageUrl = selectLastAssetWithAspectRatio('16x9', data.cpmAssets)?.aspectRatios[
      '16x9'
    ].url;

    return {
      id: data.id ?? '',
      captionData: asset?.caption
        ? {
            caption: asset?.caption ?? '',
          }
        : undefined,
      imageUrl: asset?.aspectRatios['3x4']?.url ?? '',
      imageAltTxt: asset?.altText ?? '',
      headline: data.headline,
      shortDescription: data.shortDescription,
      link: link?.url ? link : undefined,
      links: data.links,
      itemTitle: data?.label ?? '',
      modalImageUrl,
    };
  },

  function FourXGrid({ componentParams, items = [], listData, mappedPage: { brandCode } }) {
    const metrics = useMetrics();
    const createManageContentButton = useCreateManageContentButton();
    const { display } = componentParams;
    const isWA = brandCode === 'WA';

    if (!listData) {
      return null;
    }

    const { id, headline, description, links } = listData;

    if (!items.length) {
      return null;
    }

    const itemsWithManageContentButton = items.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const onViewItem = (selectedItemId: string) => {
      const index = items.findIndex((item) => item.id === selectedItemId);
      const item = items[index];

      if (item?.experimentationConfiguration && item.experimentationConfiguration.goal) {
        const val = Number(item.experimentationConfiguration.value);
        sendReward(item.experimentationConfiguration.goal, Number.isFinite(val) ? val : undefined);
      }

      metrics.trackGridItemClick?.([
        {
          itemNumber: index + 1,
          totalItems: items.length,
          label: item?.itemTitle ?? '',
          gridNumber: extractInstanceIndexFromId(id),
          is4XGrid: true,
        },
      ]);
    };

    const listLink = links[0];
    const isThemeAdded = Boolean(componentParams.theme);
    const hexEnabled =
      brandCode === 'HP' && componentParams.backgroundIllustration === 'hx-hexagon';

    if (display === 'wom') {
      return (
        <BrandComponentThemeInline
          componentClassName="fourXWOMGrid"
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <HamptonHexVisual theme={componentParams.theme ?? ''} enabled={hexEnabled}>
            <div
              className={cx({
                relative: hexEnabled,
                'md:py-12': hexEnabled && isThemeAdded,
              })}
            >
              <DynamicGridWOM
                textAlign={componentParams.listTextAlign}
                imageAspectRatio="3:4"
                isAnimated={componentParams.animation}
                listHeadline={headline ?? undefined}
                listDescription={description ?? undefined}
                id={id}
                link={listLink ? listLink : undefined}
                items={itemsWithManageContentButton}
                brandComponentTheme={componentParams.theme}
              />
            </div>
          </HamptonHexVisual>
        </BrandComponentThemeInline>
      );
    } else {
      return (
        <BrandComponentThemeInline
          componentClassName="fourXGrid"
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <HamptonHexVisual theme={componentParams.theme ?? ''} enabled={hexEnabled}>
            <DynamicGrid
              textAlign={componentParams.listTextAlign}
              listDescription={description ?? undefined}
              listHeadline={headline ?? undefined}
              id={id}
              imageAspectRatio="3:4"
              items={itemsWithManageContentButton}
              onViewItem={onViewItem}
              brandComponentTheme={componentParams.theme}
              isAnimated={isWA}
              variant="card"
            />
          </HamptonHexVisual>
        </BrandComponentThemeInline>
      );
    }
  }
);
