import Icon from '@dx-ui/osc-icon';
import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import { sendReward } from '@dx-ui/framework-conductrics';
import { useEffect } from 'react';
type SpeechBubbleProps = {
  showSpeechBubble: boolean;
  variant: string;
  closeSpeechBubble: () => void;
  onInlineButtonClick: () => void;
  containerRef?: Element | null;
  contentStyles?: string;
};
//The internal radix's logic traps focus inside the popover. This is a workaround
const getDisplayedFocusableElements = () => {
  const body = document.querySelector('body');

  if (body) {
    const focusableElements = body.querySelectorAll(
      'a, button, input, textarea, select, details, summary, [tabindex]:not([tabindex="-1"]), iframe, area'
    );

    const displayedFocusableElements = Array.from(focusableElements).filter((element) => {
      const style = window.getComputedStyle(element);
      return (
        style.display !== 'none' &&
        style.visibility !== 'hidden' &&
        (element as HTMLElement).offsetWidth > 0 &&
        (element as HTMLElement).offsetHeight > 0
      );
    });

    return displayedFocusableElements as HTMLElement[];
  }
};

export const SpeechBubble: React.FC<React.PropsWithChildren<SpeechBubbleProps>> = ({
  closeSpeechBubble,
  showSpeechBubble,
  contentStyles,
  children,
  variant,
  containerRef,
  onInlineButtonClick,
}) => {
  const onSpeechBubbleButtonClick = () => {
    sendReward('g-JtxVq7s7u9');
    onInlineButtonClick?.();
  };
  //The internal radix's logic traps focus inside the popover. This is a workaround
  const onKeyDownCloseButton = (e: React.KeyboardEvent) => {
    const displayedFocusableElements = getDisplayedFocusableElements();

    if (e.shiftKey && e.key === 'Tab' && displayedFocusableElements) {
      e.preventDefault();

      //of the currently focused element
      const currentIndex = Array.prototype.indexOf.call(
        displayedFocusableElements,
        document.activeElement
      );
      //in the list of displayed focusable items
      const previousIndex =
        (currentIndex - 1 + displayedFocusableElements.length) % displayedFocusableElements.length;

      displayedFocusableElements[previousIndex]?.focus();
    }
  };
  //The internal radix's logic traps focus inside the popover. This is a workaround
  const onKeyDownInlineButton = (e: React.KeyboardEvent) => {
    const displayedFocusableElements = getDisplayedFocusableElements();

    if (!e.shiftKey && e.key === 'Tab' && displayedFocusableElements) {
      e.preventDefault();
      //of the currently focused element
      const currentIndex = Array.prototype.indexOf.call(
        displayedFocusableElements,
        document.activeElement
      );
      //in the list of displayed focusable items
      const nextIndex = (currentIndex + 1) % displayedFocusableElements.length;

      displayedFocusableElements[nextIndex]?.focus();
    }
  };

  //This is another workaround to remove the focus guards that radix injects under the hood
  //This is not a good long term solution, and we might need to rethink using radix for a speech bubble
  //This is for the MVT test only
  useEffect(() => {
    if (showSpeechBubble) {
      //remove existing focus guards
      const focusGuards = document.querySelectorAll('span[data-radix-focus-guard]');
      focusGuards.forEach((el) => el.remove());

      // Select the parent node where the focus guards are added
      const parentNode = document.body;

      const config: MutationObserverInit = { childList: true, subtree: false };

      const callback: MutationCallback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node) => {
              if (
                node.nodeType === 1 &&
                (node as HTMLElement).hasAttribute('data-radix-focus-guard')
              ) {
                (node as HTMLElement).remove();
              }
            });
          }
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(parentNode, config);

      return () => {
        observer.disconnect();
      };
    }
  }, [showSpeechBubble]);

  return (
    <div>
      <Popover.Root open={showSpeechBubble}>
        <Popover.Trigger asChild>{children}</Popover.Trigger>
        <Popover.Portal container={containerRef}>
          <Popover.Content
            className={cx(
              'z-10 flex w-[80vw] max-w-[178px] flex-col rounded-md bg-tertiary shadow-lg sm:max-w-[266px]',
              contentStyles
            )}
            align="end"
            hideWhenDetached
            onEscapeKeyDown={closeSpeechBubble}
            aria-label="Sign in message"
            onOpenAutoFocus={(event) => event.preventDefault()}
          >
            <div className="flex justify-end">
              <Popover.Close
                className="text-bg"
                aria-label="Close sign in message"
                onClick={closeSpeechBubble}
                onKeyDown={onKeyDownCloseButton}
              >
                <Icon name="close" size="md" />
              </Popover.Close>
            </div>
            <p className="px-4 pb-4 text-bg" id="sign-in-note">
              {variant === 'B' ? (
                <>
                  Get exclusive rates and benefits{' '}
                  <button
                    onClick={onSpeechBubbleButtonClick}
                    onKeyDown={onKeyDownInlineButton}
                    type="button"
                    className="underline"
                  >
                    with Hilton Honors.
                  </button>
                </>
              ) : null}
              {variant === 'C' ? (
                <>
                  <button
                    onClick={onSpeechBubbleButtonClick}
                    onKeyDown={onKeyDownInlineButton}
                    type="button"
                    className="underline"
                  >
                    Join or sign in
                  </button>
                  <span> to unlock Hilton Honors benefits.</span>
                </>
              ) : null}
              {variant === 'D' ? (
                <>
                  <span>Check out faster—</span>
                  <button
                    onClick={onSpeechBubbleButtonClick}
                    onKeyDown={onKeyDownInlineButton}
                    type="button"
                    className="underline"
                  >
                    join or sign in
                  </button>
                  <span> to Hilton Honors.</span>
                </>
              ) : null}
            </p>
            <Popover.Arrow
              className="fill-tertiary lg:ml-[36px]"
              width={15}
              height={10}
              aria-hidden={true}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default SpeechBubble;
