import * as React from 'react';
import { ErrorBlock } from './ErrorBlock';
import type { BaseData } from '../adapters/types';
import { logError } from '@dx-ui/framework-logger';
import { CpmMappingError } from '../mappingEngine/CpmMappingError';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  displayName?: string;
  data?: BaseData | null;
  showError: boolean;
};

export type ErrorType = Error | string | null | Array<ErrorType>;

const messageFromError = (err: ErrorType): string =>
  err instanceof Error ? err.message : typeof err === 'string' ? err : '';

const titleFromProps = (
  data?: ErrorBoundaryProps['data'],
  displayName?: ErrorBoundaryProps['displayName']
) => {
  const titleEntity = data ? <code>{displayName}</code> : 'component';
  const titleSuffix = data ? (
    <>
      {' '}
      with <code>{data.contentType}</code> data
    </>
  ) : (
    ''
  );

  return (
    <>
      Something went wrong rendering {titleEntity}.{titleSuffix}
    </>
  );
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error: ErrorType }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error | string) {
    return { hasError: !!error, error };
  }

  componentDidCatch(error: Array<Error> | Error | string) {
    if (Array.isArray(error)) {
      for (const errorInstance of error) {
        if (!(errorInstance instanceof CpmMappingError)) {
          logError('Cpm Error', errorInstance);
        }
      }
    } else if (!(error instanceof CpmMappingError)) {
      logError('Cpm Error', error);
    }

    //eslint-disable-next-line no-console
    console.error(error);
  }

  render() {
    const { data, displayName, children, showError } = this.props;

    if (!this.state.hasError) {
      return children;
    }

    // Only render errors in editor mode
    if (!showError) {
      return null;
    }

    if (Array.isArray(this.state.error)) {
      return this.state.error.map((error) => (
        <ErrorBlock
          key={messageFromError(error)}
          title={titleFromProps(data, displayName)}
          message={messageFromError(error)}
        />
      ));
    } else {
      return (
        <ErrorBlock
          title={titleFromProps(data, displayName)}
          message={messageFromError(this.state.error)}
        />
      );
    }
  }
}
