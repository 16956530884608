import type { ImageClickIDType, ImageFunctionType } from '@dx-ui/config-metrics';
import { ANALYTICS_NO_VALUE, ImageType, ImageFunction, ImageClickID } from '@dx-ui/config-metrics';
import type { ImageHeadlinerMetrics } from '../types/image-headliner-analytics';

type GenerateImageHeadlinerMetricsParams = {
  headline?: string;
  itemTitle?: string;
  position: number;
  count: number;
  imageFunction?: ImageFunctionType;
  clickID: ImageClickIDType;
};

export const generateImageHeadlinerMetrics = ({
  clickID = ImageClickID.Img,
  headline = ANALYTICS_NO_VALUE,
  itemTitle = ANALYTICS_NO_VALUE,
  imageFunction,
  position,
  count,
}: GenerateImageHeadlinerMetricsParams): ImageHeadlinerMetrics => {
  const ImageHeadlinerMetrics: ImageHeadlinerMetrics = {
    click: {
      clickID,
    },
    osc: {
      image: {
        headline,
        itemTitle,
        position,
        count,
        type: ImageType.Headliner,
      },
    },
  };

  if (imageFunction) {
    ImageHeadlinerMetrics.osc.image.function = ImageFunction.Link;
  }

  return ImageHeadlinerMetrics;
};
