export const getDynamicGridColumnCount = (columnCount: string | number, count: number) => {
  let is369 = false,
    is4x = false,
    is2x = false,
    isOther = false;

  if (columnCount === 'auto') {
    is369 = count % 3 === 0 || count === 5;
    is4x = !is369 && count > 2 && (count % 2 === 0 || count === 7);
    is2x = !is369 && (is4x || count % 2 === 0);
    isOther = !is369 && !is4x && !is2x;
  } else {
    is369 = columnCount === '3';
    is4x = columnCount === '4';
    is2x = columnCount === '2';
  }

  return { is369, is4x, is2x, isOther };
};
