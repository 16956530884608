import { useRef } from 'react';
import cx from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';
import { useTranslation } from 'next-i18next';
import { ResponsiveImage, getCurrentAspectRatioAndUrl } from '@dx-ui/osc-responsive-image';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { Caption } from '@dx-ui/osc-caption';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { Link as LinkType } from '@dx-ui/osc-link';
import Image from 'next/image';
import { useRect } from '@dx-ui/utilities-use-rect';

export const isReducedMotion =
  typeof window !== 'undefined' && typeof window.matchMedia === 'function'
    ? window.matchMedia('(prefers-reduced-motion: reduce)').matches
    : true;

export type HeroTextOverlayProps = {
  /**
   * Dark opacity or Light opacity or none.
   */
  brandComponentTheme?: CmsBrandComponentTheme;
  /**
   * Caption link and text
   */
  captionData?: CaptionProps;
  /**
   * Text content box alignment, center is default
   */
  alignContent?: CmsAlignContent;
  /**
   * Main headline of component
   */
  headline?: string;
  /**
   * Similar to subheadline
   */
  shortDescription?: string;
  /**
   * Provides the long description
   */
  longDescription?: string | null;
  /**
   * OSC-Link
   */
  link?: (LinkType & { experimentationConfiguration?: CmsExperimentationConfiguration }) | null;
  /**
   * Allows animations to run when true, CMS option
   */
  isAnimated?: boolean;
  /**
   * Alternative text of the Image (a11y)
   */
  imageAltText: string;
  aspectRatios?: {
    /**
     * Aspect Ratio of the Image at 1280px +
     */
    desktop?: AspectRatio;
    /**
     * Aspect Ratio of the Image at 640px - 1280px
     */
    tablet?: AspectRatio;
    /**
     * Aspect Ratio of the Image at 0px - 640px
     */
    mobile?: AspectRatio;
  };
  /**
   * Image URLs.
   */
  image: {
    /**
     * URL of the Image at 1280px +
     */
    desktopUrl: string;
    /**
     * URL of the Image at 0px - 1280px
     */
    mobileUrl: string;
  };
  /**
   * Small image icon in the upper left corner
   */
  badgeImageUrl?: string | null;
  /**
   * Small image icon alt text
   */
  badgeImageAltText: string;
  /**
   * Opens a gallery of additional images/information
   */
  hasGalleryButton?: boolean;
  brandLinkMetricsOnClick?: () => void;
  captionMetricsOnClick?: () => void;
};

/**
 * Hero-Text Overlay is a full screen width image with text overlay which includes text, CTA and optional caption. If no additional content, only image will appear.
 */
export const HeroTextOverlay = ({
  captionData,
  image,
  imageAltText,
  alignContent = 'center',
  headline,
  shortDescription,
  longDescription,
  link,
  isAnimated = false,
  badgeImageAltText,
  badgeImageUrl,
  hasGalleryButton = false,
  aspectRatios = { desktop: '16:9', tablet: '1:1', mobile: '9:16' },
  brandComponentTheme,
  brandLinkMetricsOnClick,
  captionMetricsOnClick,
}: HeroTextOverlayProps) => {
  const { t } = useTranslation('osc-hero-text-overlay');
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.15],
    freezeOnceVisible: true,
  });
  const attachedContentAnimate = !isReducedMotion && isAnimated ? intersectionRef : null;
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const { desktopUrl: desktopImageUrl, mobileUrl: mobileImageUrl } = image;
  const {
    desktop: desktopAspectRatio,
    tablet: tabletAspectRatio,
    mobile: mobileAspectRatio,
  } = aspectRatios;
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isCenter = alignContent === 'center';
  const isLeft = alignContent === 'left';
  const isRight = alignContent === 'right';

  if (!desktopImageUrl || !mobileImageUrl) {
    return null;
  }

  const { imageUrl, aspectRatio } = getCurrentAspectRatioAndUrl({
    width: rect?.width || 0,
    imageUrlMobile: mobileImageUrl,
    imageUrlTablet: mobileImageUrl,
    imageUrlDesktop: desktopImageUrl,
    aspectRatioMobile: mobileAspectRatio,
    aspectRatioTablet: tabletAspectRatio,
    aspectRatioDesktop: desktopAspectRatio,
  });

  return (
    <div
      ref={ref}
      data-testid="heroTextOverlayContainer"
      className={cx('relative overflow-hidden bg-transparent brand-qq:bg-bg-light', {
        'bg-bg-light': isLight,
        'bg-bg-dark': isDark,
      })}
    >
      <figure className="absolute w-full">
        <ResponsiveImage
          imageUrl={imageUrl}
          aspectRatio={aspectRatio}
          altText={imageAltText}
          width={rect?.width ?? 0}
        />
        <div className="absolute inset-0">
          <div
            data-testid="heroOverlayClassTest"
            className={cx('size-full bg-bg/70', {
              'bg-bg-inverse/60': isDark,
              'bg-bg/70 brand-ch:bg-bg/80 brand-py:bg-bg/80': isLight,
            })}
          />
        </div>
        {captionData?.caption ? (
          <Caption
            caption={captionData?.caption}
            captionLink={captionData?.captionLink}
            metricsOnClick={captionMetricsOnClick}
          />
        ) : null}
      </figure>

      <div className="relative inset-0 mt-20 pb-20">
        {badgeImageUrl || hasGalleryButton ? (
          <div className="relative flex h-20 justify-between px-5 sm:h-24 md:h-44 xl:h-52">
            {badgeImageUrl ? (
              <div className="relative aspect-square h-20 sm:h-24 md:h-44 xl:ms-5 xl:h-52">
                <Image
                  className="object-contain"
                  data-testid="badgeImage"
                  src={badgeImageUrl}
                  alt={badgeImageAltText}
                  fill
                />
              </div>
            ) : null}

            {hasGalleryButton ? (
              <div className="absolute ltr:right-5 ltr:xl:right-10 rtl:left-5 rtl:xl:left-10">
                <button
                  type="button"
                  className={cx(
                    'btn btn-sm btn-primary brand-ol:rounded-none brand-qq:rounded-none brand-wa:rounded-none brand-wa:font-normal brand-wa:uppercase',
                    {
                      'btn btn-primary-outline bg-bg hover:bg-bg-alt border-0': isDark,
                    }
                  )}
                >
                  <div className="container px-0">
                    {t('galleryButton')}
                    <svg
                      className={cx('inline max-h-4 pl-1 pr-3 rtl:pl-3 rtl:pr-1', {
                        'fill-text-inverse': isLight,
                        'fill-primary': isDark,
                        'fill-bg': brandComponentTheme === undefined,
                      })}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m0 0v24h24v-24z" fill="none" />
                      <rect height="9" rx="1.5" width="9" x="4.5" y="10.5" />
                      <path d="m16 16.5c.28 0 .5-.22.5-.5v-6c0-1.38-1.12-2.5-2.5-2.5h-6c-.28 0-.5.22-.5.5s.22.5.5.5h6c.83 0 1.5.67 1.5 1.5v6c0 .28.22.5.5.5z" />
                      <path d="m19 13.5c.28 0 .5-.22.5-.5v-6c0-1.38-1.12-2.5-2.5-2.5h-6c-.28 0-.5.22-.5.5s.22.5.5.5h6c.83 0 1.5.67 1.5 1.5v6c0 .28.22.5.5.5z" />
                    </svg>
                  </div>
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="relative block w-full">
          <div
            data-testid="heroTextContainerTestClass"
            ref={attachedContentAnimate}
            className={cx('container sm:max-w-xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl', {
              'justify-end': isRight,
              'justify-start': isLeft,
              'justify-center': isCenter,
            })}
          >
            {shortDescription ? (
              <BrandTextBody
                className={cx(
                  'pb-3 text-center font-sans text-xl font-bold text-primary delay-150 sm:!text-3xl',
                  {
                    'sm:text-left': isLeft,
                    'sm:text-right': isRight,
                    'sm:text-center': isCenter,
                    'text-text-inverse': isDark,
                    'opacity-100 translate-y-0': inView && isAnimated,
                    'translate-y-4': !inView && isAnimated,
                    'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                      isAnimated,
                  }
                )}
                brandComponentTheme={brandComponentTheme}
              >
                {shortDescription}
              </BrandTextBody>
            ) : null}

            {headline ? (
              <BrandTextHeader
                className={cx(
                  'heading-2xl whitespace-normal break-words pb-2 text-center delay-300 sm:!heading-4xl xl:!heading-6xl 2xl:heading-7xl',
                  {
                    'sm:text-left': isLeft,
                    'sm:text-right': isRight,
                    'sm:text-center': isCenter,
                    '!text-text-inverse brand-ou:!text-text-inverse': isDark,
                    'opacity-100 translate-y-0': inView && isAnimated,
                    'translate-y-4': !inView && isAnimated,
                    'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                      isAnimated,
                  }
                )}
              >
                {headline}
              </BrandTextHeader>
            ) : null}

            {longDescription ? (
              <BrandTextBody
                className={cx(
                  'whitespace-normal pb-5 text-center text-xl font-bold tracking-normal delay-500 brand-ou:font-normal sm:pb-6 sm:!text-2xl xl:pb-8 xl:!text-4xl',
                  {
                    'sm:text-left': isLeft,
                    'sm:text-right': isRight,
                    'sm:text-center': isCenter,
                    'text-text-inverse': isDark,
                    'opacity-100 translate-y-0': inView && isAnimated,
                    'translate-y-4': !inView && isAnimated,
                    'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                      isAnimated,
                  }
                )}
                brandComponentTheme={brandComponentTheme}
              >
                {longDescription}
              </BrandTextBody>
            ) : null}

            {link ? (
              <div
                className={cx('p-0 text-center delay-700', {
                  'sm:text-left': isLeft,
                  'sm:text-right': isRight,
                  'sm:text-center': isCenter,
                  'opacity-100 translate-y-0': inView && isAnimated,
                  'translate-y-4': !inView && isAnimated,
                  'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                    isAnimated,
                })}
              >
                <BrandLink
                  label={link.label}
                  isNewWindow={link.isNewWindow}
                  showNewWindowIcon={link.isNewWindow}
                  url={link.url}
                  brandComponentTheme={brandComponentTheme}
                  data-conductrics-goal={link.experimentationConfiguration?.goal}
                  data-conductrics-value={link.experimentationConfiguration?.value}
                  anchorClassName={cx('xl:btn-2xl', {
                    'border-none': isDark,
                    'brand-ht:!btn-primary brand-ht:!text-text-inverse': isLight,
                  })}
                  metricsOnClick={brandLinkMetricsOnClick}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
