import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

type Segments = Record<string, Array<string>>;
export type DispatchSegmentsUpdate = Dispatch<SetStateAction<Segments>>;

export type SegmentsContext = [Segments, DispatchSegmentsUpdate];
const noop = () => undefined;

const EditorPageSegmentationContext = createContext<SegmentsContext>([{}, noop]);

/**
 * Store all segments + component/document names for the CPM editor so that it can filter them
 */
export function EditorPageSegmentsContextProvider({ children }: { children: ReactNode }) {
  const state = useState<Segments>({});

  return (
    <EditorPageSegmentationContext.Provider value={state}>
      {children}
    </EditorPageSegmentationContext.Provider>
  );
}

export function useEditorPageSegmentsContext() {
  const context = useContext(EditorPageSegmentationContext);

  if (context === undefined) {
    throw new Error(
      `SegmentsContext is undefined. This component can only be used in apps that are wrapped in SegmentationContextProvider.`
    );
  }

  return context;
}
