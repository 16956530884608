import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import type { CpmMappedComponentData } from '@dx-ui/cpm-sdk';
import { useCorporateAccount, Goals, useBrandData } from '@dx-ui/cpm-mapping-shared';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import { sendReward, useConductricsSelection, Status } from '@dx-ui/framework-conductrics';
import { getVisitorId } from '@dx-ui/framework-react-query';
import {
  BrandsSearch,
  generateUrl,
  mapDataToSpecialRates,
  type SearchUrlParameters,
  type FormData,
} from '@dx-ui/osc-brands-search';
import { getBaseUrl } from '../../utils/constants';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { env } from '@dx-ui/framework-env';
import { useStickyLayout } from '@dx-ui/utilities-use-sticky-layout';
import { getBrandRouteParams, useSingleInstanceQueryStrings } from '@dx-ui/osc-brands-data';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

const DEFAULT_LANGUAGE = 'en';

function handleSearchRedirect(searchUrlParameters: SearchUrlParameters, newBrowserTab: boolean) {
  const deepLinkUrl = generateUrl(searchUrlParameters);

  if (isBrowser && deepLinkUrl) {
    if (newBrowserTab) {
      window.open(deepLinkUrl, '_blank', 'noopener');
    } else {
      window.location.assign(deepLinkUrl);
    }
  }
}
function useStickySearchFormTest() {
  const { selection, isLoaded } = useConductricsSelection('a-kacI2WL5f0Jp', Status.OK);
  return {
    variantB: isLoaded && selection?.choice === 'B',
  };
}

function useSearchFormNewTabTest(enableTest: boolean) {
  const { selection, isLoaded } = useConductricsSelection(
    'a-81bYndwf7bCg',
    enableTest ? Status.OK : Status.PENDING
  );
  return {
    variantB: isLoaded && selection?.choice === 'B',
  };
}

function useAmenityIdsFilterTest(enableTest: boolean) {
  const { selection, isLoaded } = useConductricsSelection(
    'a-t3EOdlM3heNJ',
    enableTest ? Status.OK : Status.PENDING
  );
  return {
    variantB: isLoaded && selection?.choice === 'B',
  };
}
/*
 * SearchWidgetCpm is more featureful that most mapping components.
 *
 * As well as connecting CMS data and analytics callbacks it is also responsible
 * for re-writing the search redirect URL on form submission.
 *
 * Currently, there is a specific URL override for
 *
 * - Group Searches
 * - Flex Dates (Conductrics)
 *
 * and this list may grow in the future
 */
export default function SearchWidgetCpm({
  componentParams,
  mappedPage: { brandCode },
  isCPMEditor,
}: CpmMappedComponentData<'Search Widget', null>) {
  const { displayOption } = componentParams;
  const isGroupSearch = displayOption === 'group';
  const router = useRouter();
  const contentParam = Array.isArray(router.query?.content)
    ? router.query?.content[0]
    : router.query?.content;
  const languageCode = contentParam || router?.locale || DEFAULT_LANGUAGE;
  const corporateAccount = useCorporateAccount();
  const baseAppUrl = getBaseUrl(`${languageCode}${router.asPath}`);

  const { brandSlug, brandPath, auxPageSlug } = getBrandRouteParams(router.asPath, languageCode);
  const isLiveMode = !isCPMEditor;

  const sessionId = getVisitorId();
  const { isPartnerBrand, shopAvailOptions } = useBrandData();
  const maxNumRooms = shopAvailOptions?.maxNumRooms ?? 9;

  const {
    cid = '',
    awc = '',
    dclid = '',
    gclid = '',
    wtmcid = '',
  } = useSingleInstanceQueryStrings();
  const isDayUse = brandPath === 'workspaces-by-hilton';
  const [isNewTabTest, setIsNewTabTest] = useState(false);
  const [isAmenityIdsFilterTest, setIsAmenityIdsFilterTest] = useState(false);
  const newTabTest = useSearchFormNewTabTest(isNewTabTest);
  const amenityIdsFilterTest = useAmenityIdsFilterTest(isAmenityIdsFilterTest);
  const { additionalQS, specialRates } = mapDataToSpecialRates(componentParams, corporateAccount);
  const stickyTest = useStickySearchFormTest();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isWrapperSticky } = useStickyLayout({ ref: containerRef });
  const landingPageEx =
    /^\/(all-inclusive|resorts|beach|pet-friendly|p\/spa|p\/golf|p\/ski|p\/hotel-residences)\//;
  const isLandingPage = landingPageEx.test(router.asPath);
  const shouldRemoveAmenityIds =
    amenityIdsFilterTest.variantB && additionalQS?.f_amenityIds && isLandingPage;
  const amenityIdsFilterTestQS = shouldRemoveAmenityIds
    ? {
        ...additionalQS,
        f_amenityIds: undefined,
      }
    : additionalQS;
  function onSearchSubmit(formData: FormData, url: string) {
    setIsNewTabTest(true);
    if (isLandingPage) {
      setIsAmenityIdsFilterTest(true);
    }
    sendReward(Goals.FindAHotelClick);
    setTimeout(
      () =>
        handleSearchRedirect(
          {
            formData,
            awc,
            cid,
            dclid,
            gclid,
            isGroupSearch,
            brandCode,
            languageCode,
            url,
            wtmcid,
            baseAppUrl,
          },
          newTabTest.variantB
        ),
      0
    );
  }
  return (
    <div
      className={cx({ 'sticky top-0 z-[50] shadow-md': stickyTest.variantB && isWrapperSticky })}
      ref={containerRef}
    >
      <LocationProvider>
        <BrandComponentThemeInline
          componentClassName="search-widget-cpm"
          componentParams={componentParams}
          brandCode={brandCode}
        >
          {/*
           * Add a key to the search component in Editor mode to force a rerender
           * when special rates have changed. This is to fix a memorisation bug in the
           * Search component in dx-ui.
           */}
          <BrandsSearch
            isPartnerBrand={isPartnerBrand ?? false}
            brandCode={brandCode}
            brandSlug={brandSlug}
            baseAppUrl={baseAppUrl}
            auxPageSlug={auxPageSlug}
            isBrandRoute={true}
            fullBrandPath={brandPath}
            key={(!isLiveMode && JSON.stringify(specialRates)) || undefined}
            isGroupSearch={isGroupSearch}
            isDayUse={isDayUse}
            defaultLanguage={DEFAULT_LANGUAGE}
            languageCode={languageCode}
            autocompleteUri={env('DX_AUTOCOMPLETE_URI')}
            additionalQSParameters={amenityIdsFilterTestQS}
            specialRates={specialRates}
            sessionId={sessionId ?? ''}
            onSearchSubmit={onSearchSubmit}
            maxNumRooms={maxNumRooms}
            shouldOpenNewTabOnSubmit={newTabTest.variantB}
          />
        </BrandComponentThemeInline>
      </LocationProvider>
    </div>
  );
}
