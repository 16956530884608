import cx from 'classnames';
import { useTranslation } from 'next-i18next';

export const CarouselNoImage = ({
  className,
  isFullSize = false,
}: {
  className?: string;
  isFullSize?: boolean;
}) => {
  const { t } = useTranslation('osc-carousel');

  return (
    <div
      className={cx(
        'flex items-center justify-center bg-bg-alt @container/no-image',
        { 'h-full w-full': isFullSize, 'aspect-[3/2]': !isFullSize },
        className
      )}
    >
      <span className="text-xs text-text-alt @[150px]/no-image:text-base @[256px]/no-image:text-2xl">
        {t('noPhotoAvailable')}
      </span>
    </div>
  );
};
